export const RATING_RANKS = {
  AAA: 12,
  "AA+": 11,
  AA: 10,
  "AA-": 9,
  "A+": 8,
  A: 7,
  "A-": 6,
  "BBB+": 5,
  BBB: 4,
  "BBB-": 3,
  "BB+": 2,
  BB: 1,
  "BB-": 0,
};
