import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import formatter from "../../tools/formatter";
import moment from "moment";

export default function USTreasuryYield({ loading, referenceDate, data }) {
  return (
    <Box>
      <Typography textAlign="center">U.S. Treasury Yields</Typography>
      <Box
        display="flex"
        justifyContent="center"
        borderBottom="solid 1px #000"
        pb={1}
        mb={1}
      >
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <Typography textAlign="center" variant="subtitle2">
            ({moment(referenceDate).format("MM/DD/YYYY")})
          </Typography>
        )}
      </Box>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer className={"printableTable"}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Maturity</TableCell>
                  <TableCell align="center">Yield</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(data)?.map((maturity) => (
                  <TableRow key={maturity}>
                    <TableCell align="center">{maturity}</TableCell>
                    <TableCell align="center">
                      {formatter.percent(data?.[maturity].yield)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
