const MaxIterations = 20
const PrecisionDefault = 0.001

class Point {
    x: Number = 0.0
    y: Number = 0.0

    constructor(x: Number, y: Number) {
        this.x = x
        this.y = y
    }
}

function slope(a: Point, b: Point): Number {
    if (a.x === b.x) {
        return b.y > a.y ? Infinity : -Infinity
    }
    const [l, r] = a.x < b.x ? [a, b] : [b, a]
    return (r.y - l.y) / (r.x - l.x)
}

function line_crosses_x_axis(a: Point, b: Point): Boolean {
    return a.y * b.y < 0
}

const lerp = (
  func: (x: Number) => Number,
  target: Number,
  firstGuess: Number = 1.0,
  secondGuess: Number = 0.0,
  precision: Number = PrecisionDefault
): Number => {
    const makePoint = (x) => {
        return new Point(x, func(x) - target)
    }

    let P1 = makePoint(firstGuess)
    let P2 = makePoint(secondGuess)

    let PNext: Point
    for (let i = 0; i < MaxIterations; i++) {
        const new_x = P1.x - (P1.y / slope(P1, P2))
        PNext = makePoint(new_x)

        if (Math.abs(PNext.y) <= precision) {
            break;
        }

        if (line_crosses_x_axis(P1, P2)) {
            if (!line_crosses_x_axis(P1, PNext)) {
                P1 = PNext
            } else {
                P2 = PNext
            }
        } else {
            if (PNext.x <= P1.x) {
                P2 = P1
                P1 = PNext
            } else {
                P1 = P2
                P2 = PNext
            }
        }
    }

    return PNext.x
};

export {lerp}