import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export default function PromptDialog({
  children,
  open,
  onCancel,
  onOk,
  actions,
  title,
  okBtnText,
  cancelBtnText,
  maxWidth = "xs",
  ...other
}) {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: "content-fit" } }}
      maxWidth={maxWidth}
      open={open}
      {...other}
    >
      <DialogTitle textAlign={"center"}>{title}</DialogTitle>
      {children && (
        <DialogContent dividers style={{ textAlign: "justify" }}>
          {children}
        </DialogContent>
      )}
      <DialogActions>
        {actions?.length ? (
          actions.map(({ label, onClick, props }) => (
            <Button key={label} onClick={onClick} {...props}>
              {label}
            </Button>
          ))
        ) : (
          <>
            {!!onCancel && (
              <Button autoFocus onClick={onCancel}>
                {cancelBtnText || "Cancel"}
              </Button>
            )}
            <Button onClick={onOk}>{okBtnText || "OK"}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
