import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorContext } from "./ErrorContext";
import FiCurveApi from "../tools/ficurve-api";

export const UserInfoContext = createContext({
  userInfo: null,
  loadingUserInfo: false,
});

export function UserInfoProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const { setActiveError } = useContext(ErrorContext);

  useEffect(() => {
    fetchUserInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserInfo = useCallback(async () => {
    setLoadingUserInfo(true);
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runGetUserInfo(
      (response) => {
        setLoadingUserInfo(false);
        setUserInfo(response);
      },
      (err) => {
        console.debug(err);
        setLoadingUserInfo(false);
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  }, [getAccessTokenSilently, setActiveError]);

  return (
    <UserInfoContext.Provider value={{ loadingUserInfo, userInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
}
