import React, { useContext } from "react";
import { Box, Stack } from "@mui/material";
import formatter from "../../tools/formatter";
import ReportPageHeader from "./report-page-header";
import moment from "moment";
import { DealBuilderContext } from "../../contexts/DealBuilderContext";

const ValueEntry = ({ name, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>{name}</Box>
      <Box>{value}</Box>
    </Stack>
  );
};

const ExpenseAdjustedTIC = ({ expenseAdjustedTIC }) => {
  if (!expenseAdjustedTIC) return null;
  if (expenseAdjustedTIC === "N/A") {
    return <ValueEntry name="Expense Adjusted TIC" value="N/A" />;
  }
  return (
    <ValueEntry
      name="Expense Adjusted TIC"
      value={formatter.percent(expenseAdjustedTIC, 6, false)}
    />
  );
};

function BondSummaryStatistics() {
  const { dealBuilder } = useContext(DealBuilderContext);

  const spacer = <Box sx={{ py: "10px" }}></Box>;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ReportPageHeader
        dealBuilder={dealBuilder}
        title="BOND SUMMARY STATISTICS"
      />
      <Box width="100%">
        <Stack
          direction="column"
          justifyContent="center"
          spacing={0}
          sx={{ maxWidth: "600px", mx: "auto" }}
        >
          <ValueEntry
            name="Dated / Delivery Date"
            value={moment(dealBuilder.deliveryDate).format("MM/DD/YYYY")}
          />
          <ValueEntry
            name="First Interest Payment"
            value={moment(dealBuilder.firstCoupon).format("MM/DD/YYYY")}
          />
          <ValueEntry
            name="First Principal Payment"
            value={moment(dealBuilder.firstPrincipal).format("MM/DD/YYYY")}
          />
          <ValueEntry
            name="Final Principal Payment"
            value={moment(dealBuilder.finalPrincipal).format("MM/DD/YYYY")}
          />
          {spacer}
          <ValueEntry
            name="Total Par Amount"
            value={formatter.money(dealBuilder.parAmount, false, true)}
          />
          <ValueEntry
            name="Total Proceeds"
            value={formatter.money(dealBuilder.totalProduction, false, true)}
          />
          <ValueEntry
            name="Total Debt Service"
            value={formatter.money(
              dealBuilder.totalTotalDebtService,
              false,
              true
            )}
          />
          <ValueEntry
            name="Total Interest"
            value={formatter.money(dealBuilder.totalInterest, false, true)}
          />
          <ValueEntry
            name="Maximum Annual Debt Service"
            value={formatter.money(
              dealBuilder.maxAnnualDebtService,
              false,
              true
            )}
          />
          <ValueEntry
            name="Average Annual Debt Service"
            value={formatter.money(
              dealBuilder.averageAnnualDebtService(),
              false,
              true
            )}
          />
          {spacer}
          <ValueEntry
            name="True Interest Cost (TIC)"
            value={formatter.percent(dealBuilder.TIC, 6, true)}
          />
          <ExpenseAdjustedTIC
            expenseAdjustedTIC={dealBuilder.expenseAdjustedTIC}
          />
          <ValueEntry
            name="Net Interest Cost (NIC)"
            value={formatter.percent(dealBuilder.NIC, 6, true)}
          />
          <ValueEntry
            name="Average Coupon"
            value={`${formatter.percent(dealBuilder.averageCoupon, 6, true)}`}
          />
          {spacer}
          <ValueEntry
            name="Average Life (years)"
            value={formatter.float(dealBuilder.averageLife, 3, true)}
          />
          <ValueEntry
            name="Weighted Average Maturity (years)"
            value={formatter.float(dealBuilder.WAM, 3, true)}
          />
        </Stack>
      </Box>
    </Box>
  );
}

export default BondSummaryStatistics;
