import FiCurveApi from "../tools/ficurve-api";

export const transferLocalStorageDeals = async (
  localStorageDeals,
  getAccessTokenSilently
) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    const localStorageDealPromises = localStorageDeals.map(
      (localStorageDeal) =>
        new Promise(async (resolve, reject) => {
          api.runSaveLocalStorageDeal(
            localStorageDeal,
            () => {
              localStorage.removeItem(localStorageDeal.id);
              resolve();
            },
            (err) => {
              reject(
                `Error saving localStorage deal ${localStorageDeal.id}: ${
                  err.message ?? err
                }`
              );
            }
          );
        })
    );
    await Promise.all(localStorageDealPromises);
  } catch (err) {
    console.error(err);
  }
};
