import React, { useContext, useState, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { DealBuilderInputPanel } from "./deal-builder-input-panel";
import DealBuilderDisplay from "./deal-builder-display";
import PromptDialog from "../components/prompt-dialog";
import { DealBuilderContext } from "../contexts/DealBuilderContext";
import { ErrorContext } from "../contexts/ErrorContext";

function DealBuilderMain(props) {
  const { sx } = props;

  const {
    showOverwriteDialog,
    setShowOverwriteDialog,
    handleSaveNew,
    handleOverwriteExisting,
    showOutOfSyncDialog,
    setShowOutOfSyncDialog,
    showManualPrincipalWarningDialog,
    setShowManualPrincipalWarningDialog,
    handleDisablePrincipalValues,
    handleSaveWithoutRebuild,
    handleRebuildAndSave,
    dealBuilder,
  } = useContext(DealBuilderContext);
  const { setActiveError } = useContext(ErrorContext);
  const [footerLogo, setFooterLogo] = useState();

  useEffect(() => {
    if (dealBuilder?.hasErrors()) {
      setActiveError(dealBuilder.errors[0]);
    }
  }, [setActiveError, dealBuilder]);

  const printContentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    const ref = printContentRef.current;
    if (!footerLogo) return ref;
    const PrintElem = document.createElement("div");
    const header = `<div class="page-footer"><img src="${footerLogo?.previewImage}" alt="" class="watermark"/></div>`;
    PrintElem.innerHTML = header;
    PrintElem.appendChild(ref);
    return PrintElem;
  }, [footerLogo]);

  // RENDER
  const boxSx = {
    ...sx,
    display: sx?.display ?? "flex",
    flexDirection: sx?.flexDirection ?? { xs: "row" },
    alignItems: sx?.alignItems ?? "flex-start",
    justifyContent: sx?.justifyContent ?? "left",
    m: sx?.m ?? 3,
    flex: 1,
  };

  return (
    <Box sx={boxSx}>
      <PromptDialog
        title="Overwrite confirmation"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowOverwriteDialog(false),
            props: { autoFocus: true },
          },
          {
            label: "Save New",
            onClick: handleSaveNew,
          },
          {
            label: "Overwrite Existing",
            onClick: handleOverwriteExisting,
          },
        ]}
        open={showOverwriteDialog}
      >
        <Typography>
          Are you sure you want to overwrite existing deal?
        </Typography>
      </PromptDialog>
      <PromptDialog
        title="Out of Sync"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowOutOfSyncDialog(false),
          },
          {
            label: "Save without rebuilding",
            onClick: handleSaveWithoutRebuild,
            props: { style: { whiteSpace: "nowrap" } },
          },
          {
            label: "Rebuild and save",
            onClick: handleRebuildAndSave,
            props: { autoFocus: true, style: { whiteSpace: "nowrap" } },
          },
        ]}
        maxWidth={500}
        open={showOutOfSyncDialog}
      >
        <Typography>
          You have unbuilt changes on your deal. Would you like to rebuild
          before saving?
        </Typography>
      </PromptDialog>

      <PromptDialog
        title="Disable Manual Principal Inputs"
        open={showManualPrincipalWarningDialog}
        onCancel={() => setShowManualPrincipalWarningDialog(false)}
        onOk={handleDisablePrincipalValues}
      >
        <Typography>
          Are you sure you want to disable manual principal inputs? Any saved
          principal amounts will be lost.
        </Typography>
      </PromptDialog>
      <DealBuilderInputPanel
        reactToPrintContent={reactToPrintContent}
        sx={{ minWidth: "350px" }}
        setFooterLogo={setFooterLogo}
      />
      <Divider orientation="vertical" flexItem sx={{ mx: "5px" }} />
      <DealBuilderDisplay printContentRef={printContentRef} />
    </Box>
  );
}

export { DealBuilderMain };
