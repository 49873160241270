import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MunicipalMarketTable from "./municipal-market-table";

export default function MunicipalMarkets({
  handleInputUpdate,
  savedInputValues,
}) {
  return (
    <Box>
      <Box mb={1}>
        <Typography textAlign="center" fontWeight="bold">
          Municipal Markets - Fully Amortizing ("TIC")
        </Typography>
      </Box>
      <Divider />
      <Box mt={2}>
        <MunicipalMarketTable
          handleInputUpdate={handleInputUpdate}
          savedInputValues={savedInputValues}
        />
      </Box>
    </Box>
  );
}
