import React from "react";
import { NumericFormat } from "react-number-format";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { nameToId } from "../inputs/inputs";

const NumbericFormatCustom = React.forwardRef(function NumbericFormatCustom(
  props,
  ref
) {
  const { onChange, decimalScale = 2, ...other } = props;
  return (
    <NumericFormat
      {...other}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      decimalScale={decimalScale}
      suffix="%"
      placeholder="0.00%"
      thousandSeparator
      allowLeadingZeros={false}
      getInputRef={ref}
    />
  );
});

function FiCurveInputPercent(props) {
  const input = props.input;
  const value = input.getValue();
  const validationFailure = isNaN(value);
  const id = nameToId(input.name);

  return (
    <FormControl fullWidth size="small" error={validationFailure}>
      <InputLabel htmlFor={id}>{input.name}</InputLabel>
      <OutlinedInput
        id={id}
        label={input.name}
        value={value}
        onChange={(value) => {
          input.setValue(parseFloat(value));
        }}
        inputProps={props.inputProps}
        inputComponent={NumbericFormatCustom}
      />
    </FormControl>
  );
}

export default FiCurveInputPercent;
