const Locale = 'en-US';
const Currency = 'USD';

const convertNum = (num) => {
    if (!num) return 0;
    return num;
}

class Formatter {
    money(num, includeCents = false, showNull = false) {
        if (num === null && !showNull) {
            return ""
        }
        num = convertNum(num)
        return this.generateMoneyFormatter(includeCents).format(num)
    }

    percent(num, fractionDigits=2, showNull = false) {
        if (num === null && !showNull) {
            return ""
        }
        num = convertNum(num)
        return `${(num * 100).toFixed(fractionDigits)}%`
    }

    float(num, fractionDigits=3, showNull = false) {
        if (num === null && !showNull) {
            return ""
        }
        num = convertNum(num)
        return this.generateNumberFormatter(fractionDigits).format(num)
    }

    generateMoneyFormatter(includeCents=false) {
        const digits = includeCents ? 2 : 0;
        return new Intl.NumberFormat(Locale, {
            style: 'currency',
            currency: Currency,
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
        });
    }

    generateNumberFormatter(decPlaces=3) {
        return new Intl.NumberFormat(Locale, {
            style: 'decimal',
            minimumFractionDigits: decPlaces,
            maximumFractionDigits: decPlaces,
        });
    }
}

const formatter = new Formatter()

export default formatter
