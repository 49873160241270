import { useEffect, useState } from "react";
import * as Parse from "papaparse";

export default function useFHLBRawData(parserConfig = {}) {
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = async function () {
    try {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "content-type": "text/csv;charset=UTF-8",
        },
      };
      const res = await fetch(
        "https://storage.googleapis.com/ficurve-daily-files/fhlb_daily.csv",
        requestOptions
      );
      const resText = await res.text();
      setRawData(Parse.parse(resText, parserConfig).data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return { rawData, loading };
}
