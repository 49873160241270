const States = {
  AL: "Alabama",
  AK: "Alaska",
  // "AS": "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  // "FM": "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  // "GU": "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  // "MH": "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  // "MP": "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  // "PW": "Palau",
  PA: "Pennsylvania",
  // "PR": "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  // "VI": "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const SecurityType = {
  UnlimitedTaxGeneralObligation: "Unlimited Tax General Obligation",
  LimitedTaxGeneralObligation: "Limited Tax General Obligation",
  TaxBackedLease: "Tax-Backed Lease",
  IncomeTaxRevenues: "Income Tax Revenues",
  LeaseAppropriation: "Lease Appropriation",
  NonLeaseAppropriation: "Non-Lease Appropriation",
  SalesExciseTax: "Sales/Excise Tax",
  NonTaxRevenue: "Non-Tax Revenue",
  UtilityRevenue: "Utility Revenue",
  ElectricRevenues: "Electric Revenues",
  GasRevenues: "Gas Revenues",
  PublicHigherEducation: "Public Higher Education",
  PrivateHigherEducation: "Private Higher Education",
  CharterSchool: "Charter School",
  HospitalRevenues: "Hospital Revenues",
  SeniorLiving: "Senior Living",
  HousingRevenues: "Housing Revenues",
  TaxIncrementRevenues: "Tax Increment Revenues",
  SpecialAssessments: "Special Assessments",
  HotelRevenues: "Hotel Revenues",
  AirportRevenue: "Airport Revenue",
  PortRevenues: "Port Revenues",
  TollRevenues: "Toll Revenues",
  TobaccoSettlement: "Tobacco Settlement",
  BondFund: "Bond Fund",
  BanNote: "BAN/Note",
};

const RatingAssumption = {
  AAA: "AAA",
  AAPlus: "AA+",
  AA: "AA",
  AAMinus: "AA-",
  APlus: "A+",
  A: "A",
  AMinus: "A-",
  BBBPlus: "BBB+",
  BBB: "BBB",
  BBBMinus: "BBB-",
};

const ExpandedRatingOptions = {
  AAA: "AAA",
  AAPlus: "AA+",
  AA: "AA",
  AAMinus: "AA-",
  APlus: "A+",
  A: "A",
  AMinus: "A-",
  BBBPlus: "BBB+",
  BBB: "BBB",
  BBBMinus: "BBB-",
  BBPlus: "BB+",
  BB: "BB",
  BBMinus: "BB-",
};

const TaxDesignation = {
  BankQualified: "Bank Qualified",
  NonBankQualified: "Non-Bank Qualified",
  SubjectToAMT: "Subject to AMT",
  Taxable: "Taxable",
};

const PrincipalRate = {
  Annual: "Annual",
  SemiAnnual: "Semi-Annual",
};

const BondSolutions = ["Size by Par"];

const CallPriceDefault: Number = 100.0;

const CouponSelection = {
  FiCurveCouponPrediction: "fiCurve Coupon Prediction",
  FivePercentCoupon: "5% Coupon",
};

const SaleMethod = {
  Negotiated: "Negotiated",
  Competitive: "Competitive",
};

export {
  States,
  SecurityType,
  RatingAssumption,
  ExpandedRatingOptions,
  TaxDesignation,
  PrincipalRate,
  BondSolutions,
  CallPriceDefault,
  CouponSelection,
  SaleMethod,
};
