import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import formatter from "../tools/formatter";
import PromptDialog from "../components/prompt-dialog";
import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import FiCurveApi from "../tools/ficurve-api";
import { DealBuilderContext } from "../contexts/DealBuilderContext";
import { ErrorContext } from "../contexts/ErrorContext";
import { MainTabContext, TAB_NAMES } from "../contexts/MainTabContext";
import { formatDeals } from "../tools/utils";

export default function DealBuilderDraftTransactions() {
  const { getAccessTokenSilently } = useAuth0();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingDeals, setLoadingDeals] = useState(false);
  const [deals, setDeals] = useState([]);
  const [selectedDealID, setSelectedDealID] = useState();
  const { setActiveError } = useContext(ErrorContext);
  const { setActiveTab } = useContext(MainTabContext);
  const { handleOpenDeal, currentDealID, handleDeleteCurrentDeal } =
    useContext(DealBuilderContext);

  const fetchDeals = useCallback(async () => {
    setLoadingDeals(true);
    setActiveError(null);
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runGetDeals(
      (response) => {
        const newDeals = formatDeals(response);
        setDeals(newDeals);
        setLoadingDeals(false);
      },
      (err) => {
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
        setLoadingDeals(false);
      }
    );
  }, [getAccessTokenSilently, setActiveError]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const handleClickDelete = (id) => {
    setConfirmDelete(true);
    setSelectedDealID(id);
  };

  const handleDelete = async () => {
    setActiveError(null);
    setLoadingDeals(true);
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runDeleteDeal(
      selectedDealID,
      () => {
        const newDeals = deals.filter(({ id }) => id !== selectedDealID);
        setDeals(newDeals);
        if (currentDealID === selectedDealID) {
          handleDeleteCurrentDeal();
        }
        setLoadingDeals(false);
      },
      (err) => {
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
        setLoadingDeals(false);
      }
    );
    setConfirmDelete(false);
  };

  const handleOpen = (id, inputs) => {
    handleOpenDeal(id, inputs);
    setActiveTab(TAB_NAMES.DEAL_BUILDER);
  };

  return (
    <Container component={Box} textAlign="center" mt={4} maxWidth="xl">
      <PromptDialog
        title="Delete Confirmation"
        open={confirmDelete}
        onCancel={() => setConfirmDelete(false)}
        onOk={handleDelete}
      >
        <Typography>Are you sure you want to delete this deal?</Typography>
      </PromptDialog>
      <Paper component={Box} p={0} elevation={0}>
        {loadingDeals ? (
          <CircularProgress />
        ) : (
          <TableContainer className="printableTable">
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Delivery Date</TableCell>
                  <TableCell align="left">Par Amount</TableCell>
                  <TableCell align="left">Borrower</TableCell>
                  <TableCell align="left">Issuance Title</TableCell>
                  <TableCell align="left">Rating Assumption</TableCell>
                  <TableCell align="left">Tax Designation</TableCell>
                  <TableCell align="left">Final Principal</TableCell>
                  <TableCell align="left">Date Saved</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deals?.map(({ id, ...row }) => (
                  <TableRow key={id}>
                    <TableCell align="left">
                      {moment(row.deliveryDate).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {formatter.money(row.parAmount)}
                    </TableCell>
                    <TableCell align="left">{row.borrower}</TableCell>
                    <TableCell align="left">{row.issuanceTitle}</TableCell>
                    <TableCell align="left">{row.ratingAssumption}</TableCell>
                    <TableCell align="left">{row.taxDesignation}</TableCell>
                    <TableCell align="left">
                      {moment(row.finalPrincipal).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.dateSaved).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        color="success"
                        variant="text"
                        size="small"
                        onClick={() => handleOpen(id, row)}
                      >
                        Open
                      </Button>
                      <Button
                        color="error"
                        variant="text"
                        size="small"
                        onClick={() => handleClickDelete(id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
}
