import React, { useCallback, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import formatter from "../tools/formatter";
import { DatePicker } from "@mui/x-date-pickers";
import { Edit } from "@mui/icons-material";
import FiCurveInputPercent from "../components/FiCurveInputPercent";
import moment from "moment";

export function nameToId(name) {
  return name.toLowerCase().replace(" ", "-");
}

class FiCurveValidationFailure {
  message = "";

  constructor(message) {
    this.message = message;
  }
}

function isRequiredValidation(val) {
  return val === null || val === 0 ? "Required" : null;
}

class FiCurveInput {
  name = null;
  #value;
  #setter;
  #setterCallback;
  #disableValidations;
  #validations;

  constructor(inputName, useStateOutput, options = {}) {
    this.name = inputName;
    [this.#value, this.#setter] = useStateOutput;

    this.#setterCallback = options.setValueCallback ?? null;
    this.#validations = options.validations ?? [];

    const isRequired = options.isRequired ?? true;
    if (isRequired) {
      this.#validations.push(isRequiredValidation);
    }

    this.#disableValidations = options.disableValidations ?? false;
  }

  getValue() {
    return this.#value;
  }

  setValue(newVal, skipCallback = false) {
    this.#setter(newVal);
    if (!!this.#setterCallback && !skipCallback) {
      this.#setterCallback(newVal);
    }
  }

  validate() {
    if (this.#validations.length === 0 || this.#disableValidations) {
      return null;
    }
    const val = this.getValue();
    for (let i = 0; i < this.#validations.length; i++) {
      const errMsg = this.#validations[i](val);
      if (errMsg !== null) {
        return new FiCurveValidationFailure(errMsg);
      }
    }
    return null;
  }
}

function DisabledInputEditButton({ handleClick }) {
  if (!handleClick) return undefined;
  return (
    <InputAdornment position="end" sx={{ mr: -0.8 }}>
      <IconButton onClick={handleClick} size="small">
        <Edit fontSize="small" color="primary" />
      </IconButton>
    </InputAdornment>
  );
}

function FiCurveInputText({ input, disabled }) {
  const validationFailure = input.validate();
  const id = nameToId(input.name);

  return (
    <FormControl fullWidth size="small" error={!!validationFailure}>
      <InputLabel htmlFor={id}>{input.name}</InputLabel>
      <OutlinedInput
        id={id}
        label={input.name}
        value={input.getValue()}
        onChange={(e) => input.setValue(e.target.value)}
        disabled={disabled}
      />
      {!!validationFailure && (
        <FormHelperText>{validationFailure.message}</FormHelperText>
      )}
    </FormControl>
  );
}

function FiCurveInputCurrency({ input, disabled, handleEditButtonClick }) {
  const validationFailure = input.validate();
  const id = nameToId(input.name);

  const setFormattedMoney = (e) => {
    const val = parseFloat(e.target.value.replace(/,/g, ""));
    input.setValue(val);
  };

  return (
    <FormControl fullWidth size="small" error={!!validationFailure}>
      <InputLabel htmlFor={id}>{input.name}</InputLabel>
      <OutlinedInput
        id={id}
        label={input.name}
        value={formatter.float(input.getValue(), 0)}
        onChange={setFormattedMoney}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        endAdornment={
          <DisabledInputEditButton handleClick={handleEditButtonClick} />
        }
        disabled={disabled}
      />
      {!!validationFailure && (
        <FormHelperText>{validationFailure.message}</FormHelperText>
      )}
    </FormControl>
  );
}

function FiCurveInputSelect({
  input,
  disabled,
  options = [],
  handleEditButtonClick,
}) {
  const validationFailure = input.validate();
  const id = nameToId(input.name);

  const inputProps = useMemo(() => {
    if (!handleEditButtonClick) return undefined;
    return { IconComponent: () => null };
  }, [handleEditButtonClick]);

  return (
    <FormControl fullWidth size="small" error={!!validationFailure}>
      <InputLabel htmlFor={id}>{input.name}</InputLabel>
      <Select
        id={id}
        label={input.name}
        value={input.getValue()}
        onChange={(e) => input.setValue(e.target.value)}
        disabled={disabled}
        inputProps={inputProps}
        endAdornment={
          <DisabledInputEditButton handleClick={handleEditButtonClick} />
        }
      >
        {options.map((val) => (
          <MenuItem value={val} key={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
      {!!validationFailure && (
        <FormHelperText>{validationFailure.message}</FormHelperText>
      )}
    </FormControl>
  );
}

function FiCurveInputCheckbox({ input, disabled }) {
  const id = nameToId(input.name);

  return (
    <FormControlLabel
      size="small"
      id={id}
      label={input.name}
      control={
        <Checkbox
          checked={input.getValue()}
          onChange={(e) => input.setValue(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled}
        />
      }
    />
  );
}

function FiCurveInputDate({
  input,
  disabled,
  disableDate = false,
  handleEditButtonClick,
}) {
  const validationFailure = input.validate();
  const id = nameToId(input.name);

  const isMoment = moment.isMoment(input.getValue());
  const dateValue = isMoment ? input.getValue() : moment(input.getValue());

  const shouldDisableDate = (date) => {
    if (!disableDate) return;

    return moment(date).isBefore(moment(disableDate));
  };

  const getInputProps = useCallback(
    (params) => {
      if (!handleEditButtonClick) return params?.InputProps;
      return {
        endAdornment: (
          <DisabledInputEditButton handleClick={handleEditButtonClick} />
        ),
      };
    },
    [handleEditButtonClick]
  );

  return (
    <FormControl fullWidth size="small" error={!!validationFailure}>
      <DatePicker
        id={id}
        shouldDisableDate={shouldDisableDate}
        label={input.name}
        inputFormat="MM/DD/YYYY"
        value={dateValue}
        onChange={(e) => input.setValue(e)}
        disabled={disabled}
        disableOpenPicker={disabled}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            error={!!validationFailure}
            InputProps={getInputProps(params)}
          />
        )}
      />
      {!!validationFailure && (
        <FormHelperText>{validationFailure.message}</FormHelperText>
      )}
    </FormControl>
  );
}

function FiCurveInputSwitch({ input, disabled }) {
  const id = nameToId(input.name);

  return (
    <FormControlLabel
      size="small"
      id={id}
      label={input.name}
      control={
        <Switch
          checked={input.getValue()}
          onChange={(e) => input.setValue(e.target.checked)}
          size="small"
          disabled={disabled}
        />
      }
    />
  );
}

export {
  FiCurveInput,
  FiCurveValidationFailure,
  FiCurveInputText,
  FiCurveInputSwitch,
  FiCurveInputCurrency,
  FiCurveInputDate,
  FiCurveInputSelect,
  FiCurveInputCheckbox,
  FiCurveInputPercent,
};
