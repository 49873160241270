import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { TabPanel } from "../tab-panel";
import Manage from "./manage";
import Analyze from "./analyze";
import {
  deepCopy,
  formatDeals,
  getPricingManagementFields,
} from "../tools/utils";
import { ErrorContext } from "../contexts/ErrorContext";
import { useAuth0 } from "@auth0/auth0-react";
import FiCurveApi from "../tools/ficurve-api";
import useAAABenchmarkRawData from "../market-monitor/national-benchmarks/useAAABenchmarkRawData";
import useUSTreasuryRawData from "../market-monitor/national-benchmarks/useUSTreasuryRawData";
import useFHLBRawData from "./useFHLBRawData";
import { DealBuilderContext } from "../contexts/DealBuilderContext";
import PromptDialog from "../components/prompt-dialog";
import { UserInfoContext } from "../contexts/UserInfoContext";

const TAB_NAMES = {
  PENDING: "Pending",
  WON: "Won",
  CLOSED: "Closed",
  LOST: "Lost",
  ANALYZE: "Analyze",
};

const getTeamOptions = (teams) => {
  const options = teams.map((team) => ({
    label: team.name,
    value: team.id,
  }));
  options.unshift({ label: "All Teams", value: "all" });
  return options;
};

const getTeamUserOptions = (teams) =>
  teams.map((team) => ({
    value: team.id,
    label: team.name,
    users: team.users.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
  }));

function PricingManagementTabs() {
  const [loading, setLoading] = useState(false);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("all");
  const [activeTab, setActiveTab] = useState(TAB_NAMES.PENDING);
  const [deals, setDeals] = useState([]);
  const [reassignDeal, setReassignDeal] = useState(null);
  const [reassignUserId, setReassignUserId] = useState(null);
  const { rawData: AAAData } = useAAABenchmarkRawData({ header: true });
  const { rawData: USTData } = useUSTreasuryRawData({ header: true });
  const { rawData: FHLBData } = useFHLBRawData({ header: true });
  const { setActiveError } = useContext(ErrorContext);
  const { currentDealID, handleDeleteCurrentDeal } =
    useContext(DealBuilderContext);
  const { userInfo } = useContext(UserInfoContext);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchPricingManagementDeals();
    fetchTeams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [pendingBids, dealsWon, closedDeals, lostDeals] = useMemo(() => {
    const pending = [];
    const closed = [];
    const won = [];
    const lost = [];

    deals.forEach((deal) => {
      if (team === "all" || deal.teamId === team) {
        switch (deal.status) {
          case "Closed":
            closed.push(deal);
            return;
          case "Won":
            won.push(deal);
            return;
          case "Lost":
            lost.push(deal);
            return;
          default:
            pending.push(deal);
            return;
        }
      }
    });

    return [pending, won, closed, lost];
  }, [deals, team]);

  const teamOptions = useMemo(() => getTeamOptions(teams), [teams]);
  const teamUserOptions = useMemo(() => getTeamUserOptions(teams), [teams]);

  const fetchPricingManagementDeals = useCallback(async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runGetPricingManagementDeals(
      (response) => {
        setLoading(false);
        const formattedDeals = formatDeals(response);
        const dealsWithPricingManagementFields = formattedDeals.map((deal) => ({
          ...deal,
          ...getPricingManagementFields(deal),
        }));
        setDeals(dealsWithPricingManagementFields);
      },
      (err) => {
        console.debug(err);
        setLoading(false);
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  }, [getAccessTokenSilently, setActiveError]);

  const fetchTeams = useCallback(async () => {
    setLoadingTeams(true);
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runGetTeams(
      (response) => {
        setLoadingTeams(false);
        setTeams(response);
      },
      (err) => {
        console.debug(err);
        setLoadingTeams(false);
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  }, [getAccessTokenSilently, setActiveError]);

  const handleUpdateDeal = async (deal) => {
    const newDeals = deepCopy(deals);
    const index = newDeals.findIndex(({ id }) => id === deal.id);
    newDeals[index] = deal;
    setDeals(formatDeals(newDeals));
  };

  const handleDeleteDeal = async (selectedDealID) => {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    return api.runDeleteDeal(
      selectedDealID,
      () => {
        const newDeals = deals.filter(({ id }) => id !== selectedDealID);
        setDeals(newDeals);
        if (currentDealID === selectedDealID) {
          handleDeleteCurrentDeal();
        }
      },
      (err) => {
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  };

  const handleDuplicateDeal = async (dealId) => {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    return api.runDuplicateDeal(
      dealId,
      (res) => {
        const [formattedDeal] = formatDeals([res]);
        const extendedDeal = {
          ...formattedDeal,
          ...getPricingManagementFields(formattedDeal),
        };
        const duplicatedDealIndex = deals.findIndex(({ id }) => id === dealId);
        const newDeals = deals.toSpliced(duplicatedDealIndex, 0, extendedDeal);
        setDeals(newDeals);
      },
      (err) => {
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  };

  const openReassignDealModal = (deal) => {
    setReassignDeal(deal);
    setReassignUserId(deal.userId);
  };

  const handleReassignDeal = async () => {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    return api.runReassignDeal(
      reassignDeal.id,
      reassignUserId,
      (_) => {
        const matchingTeam = teams.find((team) =>
          team.users.some((user) => user.id === reassignUserId)
        );
        const newDeals = [...deals];
        const matchingDealIndex = deals.findIndex(
          (deal) => deal.id === reassignDeal.id
        );

        // Hide deal if user no longer has permission
        if (!userInfo.isAdmin && matchingTeam.id !== userInfo.team.id) {
          newDeals.splice(matchingDealIndex, 1);
          // Otherwise, update team and user ids
        } else {
          newDeals[matchingDealIndex].teamId = matchingTeam.id;
          newDeals[matchingDealIndex].userId = reassignUserId;
        }

        setDeals(newDeals);
        setReassignDeal(null);
        setReassignUserId(null);
      },
      (err) => {
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  };

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {userInfo?.isAdmin && (
        <FormControl sx={{ m: 2, maxWidth: 250 }} size="small">
          <InputLabel id="team-selector">Team View</InputLabel>
          <Select
            labelId="team-selector"
            label="Team View"
            value={team}
            onChange={({ target }) => setTeam(target.value)}
          >
            {teamOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <PromptDialog
        title="Reassign Deal"
        open={!!reassignDeal}
        actions={[
          { label: "Reassign", onClick: handleReassignDeal },
          { label: "Cancel", onClick: () => setReassignDeal(null) },
        ]}
      >
        <FormControl sx={{ m: 2, width: 250 }} size="small">
          <InputLabel id="user-selector">Assign Deal To:</InputLabel>
          <Select
            labelId="user-selector"
            label="Assign Deal To:"
            value={reassignUserId}
            onChange={({ target }) => setReassignUserId(target.value)}
          >
            {teamUserOptions
              .map((team) => [
                <ListSubheader key={team.value}>
                  Team: {team.label}
                </ListSubheader>,
                team.users.map((user) => (
                  <MenuItem key={user.value} value={user.value}>
                    {user.label}
                  </MenuItem>
                )),
              ])
              .flat()}
          </Select>
        </FormControl>
      </PromptDialog>
      <Box>
        <Tabs
          value={activeTab}
          onChange={(e, v) => {
            setActiveTab(v);
          }}
          scrollButtons="auto"
          aria-label="pricing management tabs"
        >
          <Tab label="Pending Bids" value={TAB_NAMES.PENDING} />
          <Tab label="Deals Won" value={TAB_NAMES.WON} />
          <Tab label="Closed Deals" value={TAB_NAMES.CLOSED} />
          <Tab label="Lost Deals" value={TAB_NAMES.LOST} />
          <Tab label="Bid Pricing Analyzer" value={TAB_NAMES.ANALYZE} />
        </Tabs>
      </Box>
      {loading || loadingTeams ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TabPanel activeTab={activeTab} name={TAB_NAMES.PENDING}>
            <Manage
              deals={pendingBids}
              allDeals={deals}
              handleUpdateDeal={handleUpdateDeal}
              handleDeleteDeal={handleDeleteDeal}
              handleDuplicateDeal={handleDuplicateDeal}
              handleReassignDeal={openReassignDealModal}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
            />
          </TabPanel>
          <TabPanel activeTab={activeTab} name={TAB_NAMES.WON}>
            <Manage
              deals={dealsWon}
              allDeals={deals}
              handleUpdateDeal={handleUpdateDeal}
              handleDeleteDeal={handleDeleteDeal}
              handleDuplicateDeal={handleDuplicateDeal}
              handleReassignDeal={openReassignDealModal}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
              defaultOrderColumnIndex={1}
            />
          </TabPanel>
          <TabPanel activeTab={activeTab} name={TAB_NAMES.CLOSED}>
            <Manage
              deals={closedDeals}
              allDeals={deals}
              handleUpdateDeal={handleUpdateDeal}
              handleDeleteDeal={handleDeleteDeal}
              handleDuplicateDeal={handleDuplicateDeal}
              handleReassignDeal={openReassignDealModal}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
              defaultOrderColumnIndex={1}
            />
          </TabPanel>
          <TabPanel activeTab={activeTab} name={TAB_NAMES.LOST}>
            <Manage
              deals={lostDeals}
              allDeals={deals}
              handleUpdateDeal={handleUpdateDeal}
              handleDeleteDeal={handleDeleteDeal}
              handleDuplicateDeal={handleDuplicateDeal}
              handleReassignDeal={openReassignDealModal}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
            />
          </TabPanel>
          <TabPanel activeTab={activeTab} name={TAB_NAMES.ANALYZE}>
            <Analyze
              deals={deals}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
            />
          </TabPanel>
        </>
      )}
    </Box>
  );
}

export default PricingManagementTabs;
