import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import formatter from "../../tools/formatter";
import ReportPageHeader from "./report-page-header";
import { DealBuilderContext } from "../../contexts/DealBuilderContext";

function BondPricing() {
  const { dealBuilder } = useContext(DealBuilderContext);

  const useCustomCouponYield = dealBuilder.useCustomCouponYield;

  const showYTM = dealBuilder.showYTM(); // TODO - this is hacky and should be changed

  const tableData = dealBuilder.tableRows.filter((row) => !!row.principal);
  const finalRow = (
    <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
      <TableCell>TOTALS</TableCell>
      <TableCell align="right">
        {formatter.money(dealBuilder.totalPrincipal)}
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      {!useCustomCouponYield && (
        <>
          <TableCell></TableCell>
          {showYTM && <TableCell></TableCell>}
          <TableCell></TableCell>
        </>
      )}
      <TableCell align="right">
        {formatter.money(dealBuilder.totalProduction, true)}
      </TableCell>
    </TableRow>
  );

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", flex: 1 }}
      textAlign="center"
    >
      <ReportPageHeader dealBuilder={dealBuilder} title="BOND PRICING" />
      <TableContainer className={"printableTable"}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">Maturity Date</TableCell>
              <TableCell align="right">Principal</TableCell>
              <TableCell align="right">Coupon</TableCell>
              {!useCustomCouponYield && (
                <>
                  <TableCell align="right">"AAA"</TableCell>
                  <TableCell align="right">Spread</TableCell>
                </>
              )}
              <TableCell align="right">Yield</TableCell>
              {!useCustomCouponYield && showYTM && (
                <TableCell align="right">YTM</TableCell>
              )}
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Production</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="right">
                  {row.paymentDate.format("MM/DD/YYYY")}
                </TableCell>
                <TableCell align="right">
                  {formatter.money(row.principal)}
                </TableCell>
                <TableCell align="right">
                  {formatter.percent(row.couponRate)}
                </TableCell>
                {!useCustomCouponYield && (
                  <>
                    <TableCell align="right">
                      {formatter.percent(row.AAA)}
                    </TableCell>
                    <TableCell align="right">
                      {formatter.percent(row.spread)}
                    </TableCell>
                  </>
                )}
                <TableCell align="right">
                  {formatter.percent(row.YTC)}
                </TableCell>
                {!useCustomCouponYield && showYTM && (
                  <TableCell align="right">
                    {formatter.percent(row.YTM)}
                  </TableCell>
                )}
                <TableCell align="right">
                  {formatter.float(row.price, 3)}
                </TableCell>
                <TableCell align="right">
                  {formatter.money(row.production, true)}
                </TableCell>
              </TableRow>
            ))}
            {finalRow}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BondPricing;
