import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import RobotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import fiCurveLogo from "../../images/main-logo-login.png";
import { percentFormatter, round } from "../../tools/utils";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingBottom: 50,
    width: "100%",
    fontFamily: "Roboto",
    fontSize: 5.25,
    flexDirection: "column",
  },
  pageTitle: {
    fontWeight: "bold",
    height: 33,
    justifyContent: "center",
    marginBottom: 8,
  },
  filterSubTitle: {
    marginBottom: 4,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    width: "100%",
    flexDirection: "row",
  },
  sectionHeader: {
    width: "100%",
    marginBottom: 4,
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  spreadsHeader: {
    width: "16%",
    backgroundColor: "#c6e0b4",
    border: "0.5px solid black",
    textAlign: "center",
  },
  headerCellText: {
    textDecoration: "underline",
  },
  alignLeft: {
    textAlign: "left",
    height: 6.5,
  },
  alignCenter: {
    textAlign: "center",
    height: 6.5,
  },
  alignRight: {
    textAlign: "right",
    height: 6.5,
  },
  footerImage: {
    position: "absolute",
    height: 15.3125,
    width: 92.0625,
    bottom: 20,
    right: 40,
  },
});

const underlineFields = ["amount", "fiCurve", "AAA", "UST", "FHLB"];

const columns = [
  {
    label: "Bid Date",
    dataName: "bidDate",
    align: "alignCenter",
    width: "6%",
    formatter: (value) => moment(value).format("M/D/YYYY"),
  },
  {
    label: "State",
    dataName: "state",
    align: "alignLeft",
    width: "5%",
  },
  {
    label: "Issuer",
    dataName: "issuer",
    align: "alignLeft",
    width: "14%",
  },
  {
    label: "Issue",
    dataName: "issue",
    align: "alignLeft",
    width: "20%",
  },
  {
    label: "Amount",
    dataName: "amount",
    align: "alignRight",
    width: "7%",
    formatter: (value, isFirst) =>
      isFirst ? `$${value.toLocaleString()}` : value.toLocaleString(),
  },
  {
    label: "Rating Grade",
    dataName: "ratingGrade",
    align: "alignCenter",
    width: "5%",
  },
  {
    label: "Avg. Life",
    dataName: "averageLife",
    align: "alignRight",
    width: "3%",
    formatter: (value) => value.toFixed(1),
  },
  {
    label: "Final Maturity",
    dataName: "finalMaturity",
    align: "alignCenter",
    width: "6%",
    formatter: (value) => moment(value).format("M/D/YYYY"),
  },
  {
    label: "Winning Bidder",
    dataName: "winningBidder",
    align: "alignLeft",
    width: "10%",
  },
  {
    label: "Coupon",
    dataName: "coupon",
    align: "alignRight",
    width: "4%",
    formatter: (value) => percentFormatter(round(value / 100, 2)),
  },
  {
    label: "Cover Bid",
    dataName: "coverBid",
    align: "alignRight",
    width: "4%",
    formatter: (value) =>
      value !== "-" ? percentFormatter(round(value / 100, 2)) : value,
  },
  {
    label: "fiCurve",
    dataName: "fiCurve",
    align: "alignRight",
    width: "4%",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    label: "AAA",
    dataName: "AAA",
    align: "alignRight",
    width: "4%",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    label: "UST",
    dataName: "UST",
    align: "alignRight",
    width: "4%",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    label: "FHLB",
    dataName: "FHLB",
    align: "alignRight",
    width: "4%",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
];

const RowPadding = ({ padding }) => (
  <View style={{ width: "100%", flexDirection: "row" }}>
    <Text style={{ height: padding, width: "84%" }} />
    <Text
      style={{
        height: padding,
        width: "16%",
        borderRight: "0.5px solid black",
        borderLeft: "0.5px solid black",
      }}
    />
  </View>
);

const Footer = () => (
  <Image src={fiCurveLogo} style={styles.footerImage} fixed />
);

const SectionHeader = ({ title, color }) => (
  <View style={[styles.sectionHeader, { backgroundColor: color }]}>
    <Text style={styles.alignCenter}>{title}</Text>
  </View>
);

const HeaderCell = ({ column }) => {
  const alignStyle = styles[column.align];
  return (
    <Text
      style={[
        alignStyle,
        styles.headerCellText,
        {
          width: column.width,
          borderLeft: column.label === "fiCurve" ? "0.5px solid black" : 0,
          borderRight: column.label === "FHLB" ? "0.5px solid black" : 0,
        },
      ]}
    >
      {column.label}
    </Text>
  );
};

const TableRow = ({ row, isFirst, isLast }) => {
  return (
    <>
      <RowPadding padding={1} />
      <View style={styles.row}>
        {columns.map((column, index) => {
          const displayValue = row[column.dataName];
          return (
            <Text
              key={column.dataName}
              style={[
                styles[column.align],
                {
                  width: column.width,
                  color:
                    typeof displayValue === "number" && displayValue < 0
                      ? "red"
                      : "black",
                  textDecoration:
                    isLast && underlineFields.includes(column.dataName)
                      ? "underline"
                      : "none",
                  fontWeight:
                    column.dataName === "Winning Bidder" &&
                    displayValue === "Your Bid"
                      ? "bold"
                      : "normal",
                  borderRight: index === 14 ? "0.5px solid black" : 0,
                  borderLeft: index === 11 ? "0.5px solid black" : 0,
                },
              ]}
            >
              {column.formatter
                ? column.formatter(displayValue, isFirst)
                : displayValue}
            </Text>
          );
        })}
      </View>
    </>
  );
};

const Table = ({ rows }) => (
  <View style={styles.tableContainer}>
    <View style={styles.row}>
      <Text style={{ width: "84%" }} />
      <Text style={styles.spreadsHeader}>Spread Analysis</Text>
    </View>
    <View style={styles.row}>
      {columns.map((column) => (
        <HeaderCell key={column.dataName + "Title"} column={column} />
      ))}
    </View>
    {rows.map((row, i) => (
      <TableRow
        key={i}
        row={row}
        columns={columns}
        isFirst={i === 0}
        isLast={i === rows.length - 1}
      />
    ))}
  </View>
);

const AverageRow = ({ title, row, isLast }) => {
  return (
    <>
      <View style={styles.row}>
        <Text style={[styles.alignCenter, { width: "66%" }]} />
        <Text style={[styles.alignRight, { width: "14%", fontWeight: "bold" }]}>
          {title}
        </Text>
        <Text style={[styles.alignCenter, { width: "4%" }]} />
        <Text
          style={[
            styles.alignRight,
            {
              width: "4%",
              borderLeft: "0.5px solid black",
              fontWeight: "bold",
              borderBottom: isLast ? "0.5px solid black" : 0,
            },
          ]}
        >
          {row.fiCurveCount
            ? percentFormatter(round(row.fiCurve / row.fiCurveCount, 2))
            : "-"}
        </Text>
        <Text
          style={[
            styles.alignRight,
            {
              width: "4%",
              fontWeight: "bold",
              borderBottom: isLast ? "0.5px solid black" : 0,
            },
          ]}
        >
          {row.AAACount
            ? percentFormatter(round(row.AAA / row.AAACount, 2))
            : "-"}
        </Text>
        <Text
          style={[
            styles.alignRight,
            {
              width: "4%",
              fontWeight: "bold",
              borderBottom: isLast ? "0.5px solid black" : 0,
            },
          ]}
        >
          {row.USTCount
            ? percentFormatter(round(row.UST / row.USTCount, 2))
            : "-"}
        </Text>
        <Text
          style={[
            styles.alignRight,
            {
              width: "4%",
              borderRight: "0.5px solid black",
              fontWeight: "bold",
              borderBottom: isLast ? "0.5px solid black" : 0,
            },
          ]}
        >
          {row.FHLBCount
            ? percentFormatter(round(row.FHLB / row.FHLBCount, 2))
            : "-"}
        </Text>
      </View>
      {!isLast && <RowPadding padding={4} />}
    </>
  );
};

const TotalsAndAverages = ({ totalsAndAverages }) => {
  return (
    <>
      <RowPadding padding={8} />
      <View style={[styles.row]}>
        <Text style={[styles.alignCenter, { width: "45%" }]} />
        <Text style={[styles.alignRight, { width: "7%", fontWeight: "bold" }]}>
          ${totalsAndAverages.totalAmount.toLocaleString()}
        </Text>
        <Text style={[styles.alignCenter, { width: "32%" }]} />
        <Text
          style={[
            styles.alignCenter,
            {
              width: "16%",
              backgroundColor: "#f2f2f2",
              borderRight: "0.5px solid black",
              borderLeft: "0.5px solid black",
            },
          ]}
        >
          Averages
        </Text>
      </View>
      <RowPadding padding={4} />
      <AverageRow
        title="All Bid Data:"
        row={totalsAndAverages.allBidsAverages}
      />
      <AverageRow
        title="Lost Bids Only:"
        row={totalsAndAverages.lostBidsAverages}
      />
      <AverageRow
        isLast
        title="Lost Bids with Cover Limit:"
        row={totalsAndAverages.lostBidsUnderCoverLimitAverages}
      />
    </>
  );
};

export const AnalysisReport = ({
  title,
  minDate,
  maxDate,
  minMaturity,
  maxMaturity,
  minRating,
  maxRating,
  taxDesignation,
  coverBidLimit,
  totalsAndAverages,
  rows,
}) => {
  return (
    <Document>
      <Page size="letter" orientation="landscape" style={styles.page}>
        <View style={[styles.pageTitle, styles.alignLeft]}>
          <Text>{title}</Text>
        </View>
        <View style={[styles.alignLeft, styles.filterSubTitle]}>
          <Text>
            Date Range: {minDate.format("MMMM D, YYYY")} -{" "}
            {maxDate.format("MMMM D, YYYY")}
          </Text>
        </View>
        <View style={[styles.alignLeft, styles.filterSubTitle]}>
          <Text>
            Maturity Range: {minMaturity} to {maxMaturity} years
          </Text>
        </View>
        <View style={[styles.alignLeft, styles.filterSubTitle]}>
          <Text>
            Rating Range: {minRating} to {maxRating}
          </Text>
        </View>
        <View style={[styles.alignLeft, styles.filterSubTitle]}>
          <Text>Tax Designation: {taxDesignation}</Text>
        </View>
        <View style={[styles.alignLeft, styles.filterSubTitle]}>
          <Text>Cover Bid Limit: {percentFormatter(coverBidLimit)}</Text>
        </View>
        <SectionHeader title="Bid Data" color="#bfbfbf" />
        <Table rows={rows} />
        <TotalsAndAverages totalsAndAverages={totalsAndAverages} />
        <Footer />
      </Page>
    </Document>
  );
};
