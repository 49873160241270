import React from "react";
import { Box } from "@mui/material";

function TabPanel(props) {
  const { children, name, activeTab, ...other } = props;

  const hidden = activeTab !== name;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      style={{ display: "flex", flex: hidden ? 0 : 1 }}
      {...other}
    >
      {activeTab === name && (
        <Box sx={{ display: "flex", flex: 1 }}>{children}</Box>
      )}
    </div>
  );
}

export { TabPanel };
