import { useEffect, useState } from "react";
import {
  calcPrice,
  calcYTM,
  convertPercentageStringToNumber,
} from "../../tools/utils";
import useAAABenchmarkRawData from "./useAAABenchmarkRawData";
import moment from "moment";

const usedMaturities = [1, 2, 3, 5, 7, 10, 20, 30];

const defaultRate = 0.05;
const defaultSpread = 0.0;

export default function useAAABenchmarkData({ taxRate }) {
  const { rawData, loading } = useAAABenchmarkRawData();

  const allData = rawData.slice(1, -1);

  const newestRawData =
    allData.length === 0
      ? []
      : allData.reduce((a, b) => {
          return new Date(a[0]) > new Date(b[0]) ? a : b;
        });

  // Format interpreted as UTC date, reformatted to match other datasets
  if (newestRawData[0]) {
    const splitDateArray = newestRawData[0].replace("Z", "").split("-");
    const newDateString = [
      splitDateArray[1],
      splitDateArray[2],
      splitDateArray[0],
    ].join("/");
    newestRawData[0] = newDateString;
  }

  const [data, setData] = useState({});

  const getData = () => {
    return usedMaturities.reduce((result, maturity) => {
      result[maturity] = {
        date: newestRawData[0],
      };
      // get yields
      result[maturity] = {
        ...result[maturity],
        yield: convertPercentageStringToNumber(newestRawData[maturity]),
      };

      // get fiCurve YTM
      if ([20, 30].includes(maturity)) {
        const callDate = moment(result[maturity].date).add(10, "year");
        const maturityDate = moment(result[maturity].date).add(
          maturity,
          "year"
        );
        const deliveryDate = moment(result[maturity].date);
        const yieldValue = result[maturity].yield;
        const AAA = yieldValue;
        const price = calcPrice(
          callDate,
          deliveryDate,
          defaultRate,
          yieldValue
        );
        const YTC = AAA + defaultSpread;

        result[maturity] = {
          ...result[maturity],
          fiCurveYTM: calcYTM(
            maturityDate,
            deliveryDate,
            defaultRate,
            price,
            YTC
          ),
        };
      } else {
        result[maturity] = {
          ...result[maturity],
          fiCurveYTM: null,
        };
      }

      // get fiCurve tax equiv
      let fiCurveTaxEquiv: Number;
      const taxRateComplement = 1 - taxRate / 100;
      if ([20, 30].includes(maturity)) {
        fiCurveTaxEquiv = result[maturity].fiCurveYTM / taxRateComplement;
      } else {
        fiCurveTaxEquiv = result[maturity].yield / taxRateComplement;
      }
      result[maturity] = {
        ...result[maturity],
        fiCurveTaxEquiv,
      };
      return result;
    }, {});
  };

  useEffect(() => {
    if (rawData.length) {
      setData({
        ...data,
        ...getData(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, taxRate]);

  return { data, loading, referenceDate: newestRawData?.[0], newestRawData };
}
