import { createContext, useState } from "react";
import moment from "moment";

export const BidAnalyzerContext = createContext({
  minDate: moment().subtract(1, "month"),
  setMinDate: () => {},
  maxDate: moment(),
  setMaxDate: () => {},
  minMaturity: "",
  setMinMaturity: () => {},
  maxMaturity: "",
  setMaxMaturity: () => {},
  minRating: "",
  setMinRating: () => {},
  maxRating: "",
  setMaxRating: () => {},
  taxDesignation: "",
  setTaxDesignation: () => {},
  coverBidLimit: "",
  setCoverBidLimit: () => {},
  analysisData: [],
  setAnalysisData: () => {},
});

export function BidAnalyzerProvider({ children }) {
  const [minDate, setMinDate] = useState(moment().subtract(1, "month"));
  const [maxDate, setMaxDate] = useState(moment());
  const [minMaturity, setMinMaturity] = useState("");
  const [maxMaturity, setMaxMaturity] = useState("");
  const [minRating, setMinRating] = useState("");
  const [maxRating, setMaxRating] = useState("");
  const [taxDesignation, setTaxDesignation] = useState("");
  const [coverBidLimit, setCoverBidLimit] = useState("");
  const [analysisData, setAnalysisData] = useState([]);

  return (
    <BidAnalyzerContext.Provider
      value={{
        minDate,
        setMinDate,
        maxDate,
        setMaxDate,
        minMaturity,
        setMinMaturity,
        maxMaturity,
        setMaxMaturity,
        minRating,
        setMinRating,
        maxRating,
        setMaxRating,
        taxDesignation,
        setTaxDesignation,
        coverBidLimit,
        setCoverBidLimit,
        analysisData,
        setAnalysisData,
      }}
    >
      {children}
    </BidAnalyzerContext.Provider>
  );
}
