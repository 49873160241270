import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import RobotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import fiCurveLogo from "../../images/main-logo-login.png";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" },
    { src: RobotoBold, fontWeight: "bold" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingBottom: 50,
    width: "100%",
    fontFamily: "Roboto",
    fontSize: 5.25,
    flexDirection: "column",
  },
  pageTitle: {
    fontWeight: "bold",
    height: 33,
    justifyContent: "center",
    marginBottom: 8,
  },
  dateRange: {
    marginBottom: 8,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    width: "100%",
    flexDirection: "row",
    marginTop: 1,
  },
  sectionHeader: {
    width: "100%",
    marginBottom: 8,
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  headerCellText: {
    textDecoration: "underline",
    marginBottom: 8,
  },
  alignLeft: {
    textAlign: "left",
    height: 6.5,
  },
  alignCenter: {
    textAlign: "center",
    height: 6.5,
  },
  alignRight: {
    textAlign: "right",
    height: 6.5,
  },
  footerImage: {
    position: "absolute",
    height: 15.3125,
    width: 92.0625,
    bottom: 20,
    right: 40,
  },
});

const underlineFields = [
  "parAmount",
  "averageLife",
  "bidRate",
  "winningBid",
  "variance",
];

const Footer = () => (
  <Image src={fiCurveLogo} style={styles.footerImage} fixed />
);

const SectionHeader = ({ title, color }) => (
  <View style={[styles.sectionHeader, { backgroundColor: color }]}>
    <Text style={styles.alignCenter}>{title}</Text>
  </View>
);

const HeaderCell = ({ column }) => {
  const alignStyle = styles[column.align];
  return (
    <Text style={[alignStyle, styles.headerCellText, { width: column.width }]}>
      {column.label}
    </Text>
  );
};

const TotalsAndAverages = ({ totalsAndAverages, columns }) => {
  return (
    <View
      style={[
        styles.row,
        {
          borderBottom: "1px solid black",
          marginTop: 10,
          marginBottom: 10,
        },
      ]}
    >
      {columns.map((column) => {
        const displayValue = totalsAndAverages?.[column.dataName] || "";
        return (
          <Text
            key={column.dataName + "totals"}
            style={[
              styles[column.align],
              {
                width: column.width,
                fontWeight: "bold",
                color:
                  typeof displayValue === "number" && displayValue < 0
                    ? "red"
                    : "black",
                marginRight:
                  column.dataName === "variance" && displayValue < 0
                    ? -2.25
                    : 0,
                marginLeft:
                  column.dataName === "variance" && displayValue < 0 ? 2.25 : 0,
              },
            ]}
          >
            {column.formatter && displayValue
              ? column.formatter(displayValue, true)
              : displayValue}
          </Text>
        );
      })}
    </View>
  );
};

const TableRow = ({ row, columns, isFirst, isLast }) => {
  return (
    <View style={styles.row}>
      {columns.map((column) => {
        const displayValue = row[column.dataName];
        return (
          <Text
            key={column.dataName}
            style={[
              styles[column.align],
              {
                width: column.width,
                color:
                  typeof displayValue === "number" && displayValue < 0
                    ? "red"
                    : "black",
                marginRight:
                  column.dataName === "variance" && displayValue < 0 ? -2 : 0,
                marginLeft:
                  column.dataName === "variance" && displayValue < 0 ? 2 : 0,
                textDecoration:
                  isLast && underlineFields.includes(column.dataName)
                    ? "underline"
                    : "none",
              },
            ]}
          >
            {column.formatter
              ? column.formatter(displayValue, isFirst)
              : displayValue}
          </Text>
        );
      })}
    </View>
  );
};

const TableSection = ({ title, color, rows, columns, totalsAndAverages }) => {
  return (
    <>
      <SectionHeader title={title} color={color} />
      <View style={styles.row}>
        {columns.map((column) => (
          <HeaderCell key={column.dataName + "Title"} column={column} />
        ))}
      </View>
      {rows.map((row, i) => (
        <TableRow
          key={i}
          row={row}
          columns={columns}
          isFirst={i === 0}
          isLast={i === rows.length - 1}
        />
      ))}
      <TotalsAndAverages
        totalsAndAverages={totalsAndAverages}
        columns={columns}
      />
    </>
  );
};

const Table = ({ sections }) => (
  <View style={styles.tableContainer}>
    {sections.map((section) => (
      <TableSection {...section} />
    ))}
  </View>
);

// Main Document
export const ActivityReport = ({ sections, dateRange, title }) => {
  return (
    <Document>
      <Page size="letter" orientation="landscape" style={styles.page}>
        <View style={[styles.pageTitle, styles.alignLeft]}>
          <Text>{title}</Text>
        </View>
        <View style={[styles.alignLeft, styles.dateRange]}>
          <Text>
            Date Range: {moment(dateRange[0]).format("MMMM D, YYYY")} -{" "}
            {moment(dateRange[1]).format("MMMM D, YYYY")}
          </Text>
        </View>
        <Table sections={sections} />
        <Footer />
      </Page>
    </Document>
  );
};
