import { useState } from "react";
import { Button } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Box } from "@mui/system";

function UploadImageInput({ disabled, onChange, text = "" }) {
  const [state, setState] = useState({
    currentFile: null,
    previewImage: null,
  });

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const newState = {
      ...state,
      currentFile: file,
      previewImage: URL.createObjectURL(file),
    };
    setState(newState);
    onChange(newState);
  };

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="upload-logo"
        onChange={(e) => {
          handleUpload(e);
        }}
      />
      <label htmlFor="upload-logo">
        <Button
          disabled={disabled}
          component="span"
          variant="outlined"
          fullWidth
        >
          <AddPhotoAlternateIcon />
          &nbsp;{text}
        </Button>
      </label>
      <br />
      <Box style={{ textAlign: "center" }} mt={1}>
        {state.previewImage && (
          <img src={state.previewImage} alt="logo" style={{ width: "1in" }} />
        )}
      </Box>
    </Box>
  );
}

export default UploadImageInput;
