import React, { useCallback, useContext, useRef, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ExpandedRatingOptions } from "../../constants";
import { RATING_RANKS } from "../constants";
import { dollarFormatter, percentFormatter, round } from "../../tools/utils";
import { getDealSpreadRecords } from "../utils";
import { AnalysisReport } from "./analysis-report";
import { ErrorContext } from "../../contexts/ErrorContext";
import { pdf } from "@react-pdf/renderer";
import { BidAnalyzerContext } from "../../contexts/BidAnalyzerContext";
import {
  CellContents,
  Header,
  getTableComponents,
} from "../../components/TableComponents";
import { TableVirtuoso } from "react-virtuoso";

const dataFields = [
  {
    dataName: "bidDate",
    tableName: "Bid Date",
    width: 40,
    align: "left",
    formatter: (value) => moment(value).format("M/D/YYYY"),
  },
  {
    dataName: "state",
    tableName: "State",
    width: 60,
    align: "center",
  },
  {
    dataName: "issuer",
    tableName: "Issuer",
    width: 125,
    align: "left",
  },
  {
    dataName: "issue",
    tableName: "Issue",
    width: 200,
    align: "left",
  },
  {
    dataName: "amount",
    tableName: "Amount",
    width: 60,
    align: "right",
    formatter: (value, isFirst) =>
      isFirst ? `$${value.toLocaleString()}` : value.toLocaleString(),
  },
  {
    dataName: "ratingGrade",
    tableName: "Rating Grade",
    width: 30,
    align: "center",
  },
  {
    dataName: "averageLife",
    tableName: "Avg. Life",
    width: 30,
    align: "center",
    formatter: (value) => value.toFixed(1),
  },
  {
    dataName: "finalMaturity",
    tableName: "Final Maturity",
    width: 40,
    align: "center",
    formatter: (value) => moment(value).format("M/D/YYYY"),
  },
  {
    dataName: "winningBidder",
    tableName: "Winning Bidder",
    width: 100,
    align: "center",
  },
  {
    dataName: "coupon",
    tableName: "Coupon",
    width: 40,
    align: "center",
    formatter: (value) => percentFormatter(round(value / 100, 2)),
  },
  {
    dataName: "coverBid",
    tableName: "Cover Bid",
    width: 40,
    align: "center",
    formatter: (value) =>
      value !== "-" ? percentFormatter(round(value / 100, 2)) : value,
  },
  {
    dataName: "fiCurve",
    width: 40,
    align: "center",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    dataName: "AAA",
    width: 40,
    align: "center",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    dataName: "UST",
    width: 40,
    align: "center",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
  {
    dataName: "FHLB",
    width: 40,
    align: "center",
    formatter: (value) => (value !== 0 ? percentFormatter(value) : "-"),
  },
];

// We have to add the lost padding when merging two columns together
const CELL_PADDING = 32;

const bottomHeaderFields = [
  dataFields[0],
  dataFields[1],
  dataFields[2],
  {
    ...dataFields[4],
    colSpan: 2,
    width: dataFields[3].width + dataFields[4].width + CELL_PADDING,
    align: "right",
  },
  dataFields[5],
  dataFields[6],
  dataFields[7],
  dataFields[8],
  {
    ...dataFields[9],
    colSpan: 2,
    width: dataFields[9].width + dataFields[10].width + CELL_PADDING,
  },
  {
    ...dataFields[11],
    colSpan: 4,
    width:
      dataFields[11].width +
      dataFields[12].width +
      dataFields[13].width +
      dataFields[14].width +
      CELL_PADDING * 3,
  },
];

const bottomDataFields = [
  dataFields[0],
  dataFields[1],
  dataFields[2],
  dataFields[3],
  dataFields[4],
  dataFields[5],
  dataFields[6],
  dataFields[7],
  {
    ...dataFields[8],
    colSpan: 3,
    width:
      dataFields[8].width +
      dataFields[9].width +
      dataFields[10].width +
      CELL_PADDING * 2,
    align: "right",
  },
  dataFields[11],
  dataFields[12],
  dataFields[13],
  dataFields[14],
];

const TableComponents = getTableComponents(dataFields);

const EmptyState = ({ children }) => (
  <Box
    sx={{
      border: "1px solid rgba(0,0,0,.12)",
      borderRadius: 4,
      backgroundColor: "rgba(0,0,0,.03)",
      width: "100%",
      height: 300,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography sx={{ color: "#979797" }}>{children}</Typography>
  </Box>
);

const Analyze = ({ deals, AAAData, USTData, FHLBData }) => {
  const {
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    minMaturity,
    setMinMaturity,
    maxMaturity,
    setMaxMaturity,
    minRating,
    setMinRating,
    maxRating,
    setMaxRating,
    taxDesignation,
    setTaxDesignation,
    coverBidLimit,
    setCoverBidLimit,
    analysisData,
    setAnalysisData,
  } = useContext(BidAnalyzerContext);
  const { setActiveError } = useContext(ErrorContext);
  const topTableScrollerRef = useRef(null);
  const bottomTableScrollerRef = useRef(null);

  const handleAnalyze = useCallback(() => {
    const totalsAndAverages = {
      totalAmount: 0,
      allBidsAverages: {
        fiCurve: 0,
        fiCurveCount: 0,
        AAA: 0,
        AAACount: 0,
        UST: 0,
        USTCount: 0,
        FHLB: 0,
        FHLBCount: 0,
      },
      lostBidsAverages: {
        fiCurve: 0,
        fiCurveCount: 0,
        AAA: 0,
        AAACount: 0,
        UST: 0,
        USTCount: 0,
        FHLB: 0,
        FHLBCount: 0,
      },
      lostBidsUnderCoverLimitAverages: {
        fiCurve: 0,
        fiCurveCount: 0,
        AAA: 0,
        AAACount: 0,
        UST: 0,
        USTCount: 0,
        FHLB: 0,
        FHLBCount: 0,
      },
    };
    const rows = [];

    const analysisDeals = deals.filter((deal) => {
      const startOfMinDate = minDate.startOf("day");
      const endOfMaxDate = maxDate.endOf("day");
      const maturity = moment
        .duration(deal.finalPrincipal.diff(deal.deliveryDate))
        .asYears();

      return (
        ["Won", "Lost", "Closed"].includes(deal.status) &&
        deal.bidDate >= startOfMinDate &&
        deal.bidDate <= endOfMaxDate &&
        RATING_RANKS[deal.ratingAssumption] >= RATING_RANKS[minRating] &&
        RATING_RANKS[deal.ratingAssumption] <= RATING_RANKS[maxRating] &&
        maturity >= minMaturity &&
        maturity <= maxMaturity &&
        ((deal.taxDesignation === "Taxable" && taxDesignation === "Taxable") ||
          (deal.taxDesignation !== "Taxable" &&
            taxDesignation === "Tax-Exempt"))
      );
    });

    analysisDeals.forEach((deal) => {
      // Add par amount to total
      totalsAndAverages.totalAmount += deal.parAmount;

      // Calculate winning bid, coupon, and cover bid
      let winningBidder = "Your Bid";
      let coupon = deal.bidRate || 400;
      let coverBid = "-";
      const bids = deal.bids.toSorted(
        ({ rate: rate1 }, { rate: rate2 }) => rate1 - rate2
      );
      if (deal.status === "Lost" && bids.length) {
        winningBidder = bids[0].name;
        coupon = bids[0].rate;
        coverBid = deal.bidRate - bids[0].rate;
      } else if (
        (deal.status === "Won" || deal.status === "Closed") &&
        bids.length
      ) {
        coverBid = bids[0].rate - deal.bidRate;
      }

      // Calculate spread statistics
      const rate = round(coupon / 100, 2);
      let fiCurve = 0;
      let AAA = 0;
      let UST = 0;
      let FHLB = 0;

      const { matchingAAARate, matchingUSTRate, matchingFHLBRate } =
        getDealSpreadRecords({
          deal,
          AAAData,
          USTData,
          FHLBData,
        });

      if (
        deal.tic !== 0 &&
        [
          "Bank Qualified",
          "Non-Bank Qualified",
          "Alternative Minimum Tax",
        ].includes(deal.taxDesignation)
      ) {
        fiCurve = rate - deal.tic;
        AAA = matchingAAARate ? rate - matchingAAARate : 0;
        UST = matchingUSTRate ? rate / 0.79 - matchingUSTRate : 0;
        FHLB = matchingFHLBRate ? rate / 0.79 - matchingFHLBRate : 0;
      } else if (deal.tic !== 0) {
        fiCurve = 0;
        AAA = 0;
        UST = matchingUSTRate ? rate - matchingUSTRate : 0;
        FHLB = matchingFHLBRate ? rate - matchingFHLBRate : 0;
      }

      if (fiCurve) {
        totalsAndAverages.allBidsAverages.fiCurve += fiCurve;
        totalsAndAverages.allBidsAverages.fiCurveCount += 1;

        if (deal.status === "Lost") {
          totalsAndAverages.lostBidsAverages.fiCurve += fiCurve;
          totalsAndAverages.lostBidsAverages.fiCurveCount += 1;
        }

        if (
          deal.status === "Lost" &&
          Math.abs(coverBid) <= coverBidLimit * 100
        ) {
          totalsAndAverages.lostBidsUnderCoverLimitAverages.fiCurve += fiCurve;
          totalsAndAverages.lostBidsUnderCoverLimitAverages.fiCurveCount += 1;
        }
      }

      if (AAA) {
        totalsAndAverages.allBidsAverages.AAA += AAA;
        totalsAndAverages.allBidsAverages.AAACount += 1;

        if (deal.status === "Lost") {
          totalsAndAverages.lostBidsAverages.AAA += AAA;
          totalsAndAverages.lostBidsAverages.AAACount += 1;
        }

        if (
          deal.status === "Lost" &&
          Math.abs(coverBid) <= coverBidLimit * 100
        ) {
          totalsAndAverages.lostBidsUnderCoverLimitAverages.AAA += AAA;
          totalsAndAverages.lostBidsUnderCoverLimitAverages.AAACount += 1;
        }
      }

      if (UST) {
        totalsAndAverages.allBidsAverages.UST += UST;
        totalsAndAverages.allBidsAverages.USTCount += 1;

        if (deal.status === "Lost") {
          totalsAndAverages.lostBidsAverages.UST += UST;
          totalsAndAverages.lostBidsAverages.USTCount += 1;
        }

        if (
          deal.status === "Lost" &&
          Math.abs(coverBid) <= coverBidLimit * 100
        ) {
          totalsAndAverages.lostBidsUnderCoverLimitAverages.UST += UST;
          totalsAndAverages.lostBidsUnderCoverLimitAverages.USTCount += 1;
        }
      }

      if (FHLB) {
        totalsAndAverages.allBidsAverages.FHLB += FHLB;
        totalsAndAverages.allBidsAverages.FHLBCount += 1;

        if (deal.status === "Lost") {
          totalsAndAverages.lostBidsAverages.FHLB += FHLB;
          totalsAndAverages.lostBidsAverages.FHLBCount += 1;
        }

        if (
          deal.status === "Lost" &&
          Math.abs(coverBid) <= coverBidLimit * 100
        ) {
          totalsAndAverages.lostBidsUnderCoverLimitAverages.FHLB += FHLB;
          totalsAndAverages.lostBidsUnderCoverLimitAverages.FHLBCount += 1;
        }
      }

      rows.push({
        id: deal.id,
        bidDate: deal.bidDate,
        status: deal.status,
        state: deal.stateOfIssuance,
        issuer: deal.borrower,
        issue: deal.issuanceTitle,
        amount: deal.parAmount,
        ratingGrade: deal.ratingAssumption,
        averageLife: deal.savedDealBuilder.averageLife,
        finalMaturity: deal.finalPrincipal,
        winningBidder,
        coupon,
        coverBid,
        fiCurve,
        AAA,
        UST,
        FHLB,
      });
    });

    setAnalysisData({
      title: "Bid Analysis Report",
      minDate,
      maxDate,
      minMaturity,
      maxMaturity,
      minRating,
      maxRating,
      taxDesignation,
      coverBidLimit,
      totalsAndAverages,
      rows,
    });
  }, [
    AAAData,
    FHLBData,
    USTData,
    deals,
    minDate,
    maxDate,
    minMaturity,
    maxMaturity,
    minRating,
    maxRating,
    taxDesignation,
    coverBidLimit,
    setAnalysisData,
  ]);

  const handleExport = async () => {
    if (!analysisData?.rows) return;
    try {
      const blob = await pdf(<AnalysisReport {...analysisData} />).toBlob();
      const pdfUrl = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = pdfUrl;
      tempLink.setAttribute(
        "download",
        `bid-analysis-report-${moment().format("MM-DD-YYYY-HH:mm:ss:SS")}.pdf`
      );
      tempLink.click();
      tempLink.remove();
    } catch (err) {
      console.debug(err);
      setActiveError("There was an error generating the PDF");
    }
  };
  const handleTopTableScrollerRef = useCallback((ref) => {
    topTableScrollerRef.current = ref;
  }, []);
  const handleBottomTableScrollerRef = useCallback((ref) => {
    bottomTableScrollerRef.current = ref;
  }, []);
  const handleTopTableScroll = (event) => {
    bottomTableScrollerRef.current.scrollLeft = event.target.scrollLeft;
  };
  const handleBottomTableScroll = (event) => {
    topTableScrollerRef.current.scrollLeft = event.target.scrollLeft;
  };

  return (
    <Box
      gap={2}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginX: 2,
      }}
    >
      <Stack direction="row" spacing={4} mt={3}>
        <Stack direction="column" spacing={2}>
          <DatePicker
            id="report-min-date"
            label="Min Date"
            inputFormat="MM/DD/YYYY"
            value={minDate}
            onChange={(e) => setMinDate(e)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            id="report-max-date"
            label="Max Date"
            inputFormat="MM/DD/YYYY"
            value={maxDate}
            onChange={(e) => setMaxDate(e)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>

        <Stack direction="column" spacing={2}>
          <TextField
            id="report-min-maturity"
            type="number"
            label="Min Maturity"
            value={minMaturity}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">year(s)</InputAdornment>
              ),
            }}
            onChange={({ target }) =>
              setMinMaturity(
                !isNaN(parseFloat(target.value)) ? parseFloat(target.value) : ""
              )
            }
            sx={{
              width: 231,
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
                margin: 0,
              },
            }}
          />
          <TextField
            id="report-max-maturity"
            type="number"
            label="Max Maturity"
            value={maxMaturity}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">year(s)</InputAdornment>
              ),
            }}
            onChange={({ target }) =>
              setMaxMaturity(
                !isNaN(parseFloat(target.value)) ? parseFloat(target.value) : ""
              )
            }
            sx={{
              width: 231,
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
              },
            }}
          />
        </Stack>

        <Stack direction="column" spacing={2}>
          <FormControl sx={{ width: 231 }}>
            <InputLabel id="min-rating-label">Min Rating</InputLabel>
            <Select
              displayEmpty
              id="report-min-rating"
              label="Min Rating"
              labelId="min-rating-label"
              value={minRating}
              onChange={({ target }) => setMinRating(target.value)}
            >
              {Object.values(ExpandedRatingOptions).map((rating) => (
                <MenuItem value={rating} key={`min-${rating}`}>
                  {rating}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 231 }}>
            <InputLabel id="max-rating-label">Max Rating</InputLabel>
            <Select
              displayEmpty
              id="report-max-rating"
              label="Max Rating"
              labelId="max-rating-label"
              value={maxRating}
              onChange={({ target }) => setMaxRating(target.value)}
            >
              {Object.values(ExpandedRatingOptions).map((rating) => (
                <MenuItem value={rating} key={`max-${rating}`}>
                  {rating}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="column" spacing={2}>
          <FormControl sx={{ width: 231 }}>
            <InputLabel id="tax-designation-label">Tax Designation</InputLabel>
            <Select
              displayEmpty
              id="report-tax-designation"
              label="Tax Designation"
              labelId="tax-designation-label"
              value={taxDesignation}
              onChange={({ target }) => setTaxDesignation(target.value)}
            >
              <MenuItem value="Tax-Exempt" key="Tax-Exempt">
                Tax-Exempt
              </MenuItem>
              <MenuItem value="Taxable" key="Taxable">
                Taxable
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="report-cover-bid-limit"
            type="number"
            label="Cover Bid Limit"
            value={coverBidLimit}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={({ target }) =>
              setCoverBidLimit(
                !isNaN(parseFloat(target.value)) ? parseFloat(target.value) : ""
              )
            }
            sx={{
              width: 231,
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
              },
            }}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Button
            disabled={
              minMaturity === "" ||
              maxMaturity === "" ||
              minRating === "" ||
              maxRating === "" ||
              taxDesignation === "" ||
              coverBidLimit === ""
            }
            onClick={handleAnalyze}
            variant="contained"
          >
            Analyze
          </Button>
          <Button
            disabled={!analysisData?.rows?.length}
            onClick={handleExport}
            variant="outlined"
          >
            Export
          </Button>
        </Stack>
      </Stack>
      {!analysisData || !analysisData?.rows?.length ? (
        <EmptyState>
          {!analysisData
            ? "Please make analysis selection above"
            : "No rows matched filters. Please adjust options above"}
        </EmptyState>
      ) : (
        <Stack direction="column" height="100%" width="100%" flex={1}>
          <TableVirtuoso
            style={{
              height: "100%",
              tableLayout: "fixed",
              userSelect: "none",
              boxShadow: "none",
            }}
            scrollerRef={handleTopTableScrollerRef}
            onScroll={handleTopTableScroll}
            data={analysisData.rows}
            components={TableComponents}
            fixedHeaderContent={() => (
              <TableRow sx={{ backgroundColor: "white" }}>
                {dataFields.map((dataField) => (
                  <Header
                    {...dataField}
                    filters={[]}
                    addOrUpdateFilter={() => {}}
                    removeFilter={() => {}}
                  />
                ))}
              </TableRow>
            )}
            itemContent={(index, row) => (
              <>
                {dataFields.map(({ dataName, width, align, formatter }) => {
                  const dataValue = row[dataName];
                  return (
                    <CellContents
                      key={dataName + index}
                      width={width}
                      align={align}
                      color={
                        typeof dataValue === "number" && dataValue < 0
                          ? "red"
                          : "black"
                      }
                    >
                      {formatter
                        ? formatter(dataValue, index === 0)
                        : dataValue}
                    </CellContents>
                  );
                })}
              </>
            )}
          />
          <TableVirtuoso
            style={{
              marginTop: 10,
              height: 170,
              tableLayout: "fixed",
              userSelect: "none",
              boxShadow: "none",
            }}
            scrollerRef={handleBottomTableScrollerRef}
            onScroll={handleBottomTableScroll}
            data={[
              analysisData.totalsAndAverages.allBidsAverages,
              analysisData.totalsAndAverages.lostBidsAverages,
              analysisData.totalsAndAverages.lostBidsUnderCoverLimitAverages,
            ]}
            components={TableComponents}
            fixedHeaderContent={() => (
              <TableRow sx={{ backgroundColor: "white" }}>
                {bottomHeaderFields.map((dataField) => {
                  let dataName = "";
                  if (dataField.dataName === "amount") {
                    dataName = `TOTAL: ${dollarFormatter.format(
                      analysisData.totalsAndAverages.totalAmount
                    )}`;
                  } else if (dataField.dataName === "fiCurve") {
                    dataName = "AVERAGES";
                  }
                  return (
                    <Header
                      {...dataField}
                      key={"bottom" + dataField.dataName}
                      borderColor="transparent"
                      tableName=""
                      dataName={dataName}
                      filters={[]}
                      addOrUpdateFilter={() => {}}
                      removeFilter={() => {}}
                    />
                  );
                })}
              </TableRow>
            )}
            itemContent={(index, data) =>
              bottomDataFields.map(({ dataName, width, align, colSpan }) => {
                let content = "";
                let color = "black";
                let padding = "5px 0";
                let borderProps = { border: "none" };
                let totalWidth = width;
                if (dataName === "winningBidder" && index === 0) {
                  content = "All Bid Data:";
                  padding = "8px 5px 8px 0";
                } else if (dataName === "winningBidder" && index === 1) {
                  content = "Lost Bids Only:";
                  padding = "8px 5px 8px 0";
                } else if (dataName === "winningBidder" && index === 2) {
                  content = "Lost Bids With Cover Limit:";
                  padding = "8px 5px 8px 0";
                } else if (dataName === "fiCurve") {
                  content = data.fiCurveCount
                    ? percentFormatter(data.fiCurve / data.fiCurveCount)
                    : "-";
                  totalWidth = totalWidth - 1;
                  borderProps = {
                    ...borderProps,
                    borderLeft: "1px solid #1976d2",
                  };
                  if (index === 0) {
                    borderProps = {
                      ...borderProps,
                      borderTopLeftRadius: 3,
                    };
                  } else if (index === 2) {
                    borderProps = {
                      ...borderProps,
                      borderBottomLeftRadius: 3,
                    };
                  }
                } else if (dataName === "AAA") {
                  content = data.AAACount
                    ? percentFormatter(data.AAA / data.AAACount)
                    : "-";
                } else if (dataName === "UST") {
                  content = data.USTCount
                    ? percentFormatter(data.UST / data.USTCount)
                    : "-";
                } else if (dataName === "FHLB") {
                  content = data.FHLBCount
                    ? percentFormatter(data.FHLB / data.FHLBCount)
                    : "-";
                  totalWidth = totalWidth - 1;
                  borderProps = {
                    ...borderProps,
                    borderRight: "1px solid #1976d2",
                  };
                  if (index === 0) {
                    borderProps = {
                      ...borderProps,
                      borderTopRightRadius: 3,
                    };
                  } else if (index === 2) {
                    borderProps = {
                      ...borderProps,
                      borderBottomRightRadius: 3,
                    };
                  }
                }
                if (["fiCurve", "AAA", "UST", "FHLB"].includes(dataName)) {
                  if (index === 0) {
                    borderProps = {
                      ...borderProps,
                      borderTop: "1px solid #1976d2",
                    };
                  } else if (index === 2) {
                    borderProps = {
                      ...borderProps,
                      borderBottom: "1px solid #1976d2",
                    };
                  }
                }
                if (content !== "-" && content.startsWith("-")) {
                  color = "red";
                }
                return (
                  <CellContents
                    key={"bottom" + dataName + index}
                    width={totalWidth}
                    align={align}
                    color={color}
                    colSpan={colSpan}
                    padding={padding}
                    {...borderProps}
                  >
                    {content}
                  </CellContents>
                );
              })
            }
          />
        </Stack>
      )}
    </Box>
  );
};

export default Analyze;
