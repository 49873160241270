
import { useEffect, useState } from "react";

export default function useLocalStorageDeal() {
  const [deals, setDeals] = useState([]);
  const keys = Object.keys(localStorage);

  useEffect(() => {
    const dealRegex = new RegExp(/(deal_)\w+/);
    const dealKeys = keys.filter((key) => dealRegex.test(key));
    const result = dealKeys.map((dealKey) => ({
      id: dealKey,
      ...JSON.parse(localStorage.getItem(dealKey)),
    }));
    setDeals(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys.length]);
  return deals;
}
