import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FiCurveInput } from "../inputs/inputs";
import moment from "moment";
import {
  BondSolutions,
  CouponSelection,
  PrincipalRate,
  RatingAssumption,
  SaleMethod,
} from "../constants";
import useLocalStorageDeal from "../deal-builder/useLocalStorageDeal";
import { transferLocalStorageDeals } from "../deal-builder/transferLocalStorageDeals";
import FiCurveApi from "../tools/ficurve-api";
import { ErrorContext } from "./ErrorContext";
import { DealBuilderService } from "../deal-builder/deal-builder-service";
import {
  combineSourcesAndUses,
  createTicAdjustingExpensesFromCategory,
  dateValidation,
  getCapitalizedInterestDateFromCategory,
  separateSourcesAndUses,
} from "../tools/utils";

const MINIMUM_PAR_AMOUNT_DELTA = 3000000;

const getSourcesAndUsesWithoutIds = (sourcesAndUses) => ({
  sources: sourcesAndUses.sources.map(({ id, items = [], ...source }) => ({
    ...source,
    items: items.map(({ id, ...item }) => ({
      ...item,
    })),
  })),
  uses: sourcesAndUses.uses.map(({ id, items = [], ...source }) => ({
    ...source,
    items: items.map(({ id, ...item }) => ({
      ...item,
    })),
  })),
});

export const DealBuilderContext = createContext({
  dealBuilderInputs: {},
  dealBuilder: null,
  sourcesAndUses: { sources: [], uses: [] },
  setSourcesAndUses: () => {},
  ticAdjustingExpenses: undefined,
  capitalizedInterestDate: undefined,
  usePrincipalValues: false,
  setUsePrincipalValues: () => {},
  principalValues: [],
  setPrincipalValues: () => {},
  initialInputValues: {},
  isSavingDeal: false,
  isBuildingDeal: false,
  isLoadingDeal: false,
  isBuildManagement: false,
  handleOpenDeal: () => {},
  currentDealID: null,
  setCurrentDealID: () => {},
  buildDeal: () => {},
  showOverwriteDialog: false,
  setShowOverwriteDialog: () => {},
  showOutOfSyncDialog: false,
  setShowOutOfSyncDialog: () => {},
  showManualPrincipalWarningDialog: false,
  setShowManualPrincipalWarningDialog: () => {},
  showPrincipalInputs: false,
  setShowPrincipalInputs: () => {},
  handleDisablePrincipalValues: () => {},
  handleRebuildAndSave: () => {},
  handleSaveWithoutRebuild: () => {},
  handleOverwriteExisting: () => {},
  handleSaveNew: () => {},
  handleSaveDealToDrafts: () => {},
  handleSourceAndUses: () => {},
  handleClearDeal: () => {},
  handleDeleteCurrentDeal: () => {},
  handleSaveToPricingManagement: () => {},
});

const pushPrincipalValues = ({
  dealBuilder,
  setUsePrincipalValues,
  principalValues = [],
}) => {
  if (!principalValues.length) return;
  // Update state to use principal values
  if (principalValues.some((value) => value !== null)) {
    setUsePrincipalValues(true);
  }

  // Push values
  dealBuilder.updatePrincipals(principalValues);
};

const useDealBuilderInputs = ({
  initialInputValues,
  setShouldCallApi,
  setInitialInputValues,
  setFormDirty,
}) => {
  const isDecember = Boolean(moment().month() === 11);
  const currentYear = moment().year();
  // DEAL BUILDER INPUTS
  const defaultCallback = () => {
    setFormDirty(true);
  };

  const callApiCallback = () => {
    setShouldCallApi(true);
    setInitialInputValues({});
    defaultCallback();
  };

  const frequencyValidation = (startDate, endDate, frequency) => {
    const monthDayFormat = "MM/DD";
    const [start, end] = [moment(startDate), moment(endDate)];
    const endMonthDay = end.format(monthDayFormat);

    if (frequency === PrincipalRate.Annual) {
      return endMonthDay !== start.format(monthDayFormat)
        ? `Must be on ${start.format("MMM Do")}`
        : null;
    } else {
      const startMonth = start.month();
      const validAltMonth = startMonth > 6 ? startMonth - 6 : startMonth + 6;
      const validAltDate = moment(start).month(validAltMonth);

      if (
        endMonthDay === start.format(monthDayFormat) ||
        endMonthDay === validAltDate.format(monthDayFormat)
      ) {
        return null;
      } else {
        return `Must be either ${start.format(
          "MMM Do"
        )} or ${validAltDate.format("MMM Do")}`;
      }
    }
  };

  const parAmount = new FiCurveInput(
    "Par Amount",
    useState(initialInputValues?.parAmount || 10000000),
    {
      validations: [
        (val) =>
          val % denomination.getValue() !== 0
            ? "Must be divisible by Minimum Denomination"
            : null,
      ],
      setValueCallback: () => {},
    }
  );

  const denomination = new FiCurveInput(
    "Minimum Denomination",
    useState(initialInputValues?.denomination || 5000),
    {
      validations: [
        (val) =>
          val >= parAmount.getValue() ? "Must be less than Par Amount" : null,
      ],
      setValueCallback: defaultCallback,
    }
  );

  const deliveryDate = new FiCurveInput(
    "Delivery Date",
    useState(
      moment(
        initialInputValues?.deliveryDate ||
          moment().add(90, "days").format("MM/DD/YYYY")
      )
    ),
    { validations: [dateValidation], setValueCallback: defaultCallback }
  );

  const marketConditionsDate = new FiCurveInput(
    "Date of Market Conditions",
    useState(
      moment(
        initialInputValues?.marketConditionsDate ||
          moment().subtract(1, "day").format("MM/DD/YYYY")
      )
    ),
    {
      setValueCallback: callApiCallback,
      validations: [
        dateValidation,
        (val) =>
          moment(val).isBefore("06/01/2021")
            ? "Must be on or after June 1, 2021"
            : null,
      ],
    }
  );

  const firstCoupon = new FiCurveInput(
    "First Coupon",
    useState(
      moment(
        initialInputValues?.firstCoupon ||
          `12/01/${isDecember ? currentYear + 1 : currentYear}`
      )
    ),
    {
      validations: [
        dateValidation,
        (val) =>
          !moment(val).isAfter(deliveryDate.getValue())
            ? "Must be after Delivery Date"
            : null,
        (val) =>
          moment(val).isBefore(marketConditionsDate.getValue())
            ? "Cannot be before Date of Market Conditions"
            : null,
      ],
      setValueCallback: defaultCallback,
    }
  );

  const principalFrequency = new FiCurveInput(
    "Principal Frequency",
    useState(initialInputValues?.principalFrequency || PrincipalRate.Annual),
    {
      setValueCallback: defaultCallback,
    }
  );

  const firstPrincipal = new FiCurveInput(
    "First Principal",
    useState(
      moment(
        initialInputValues?.firstPrincipal ||
          `12/01/${isDecember ? currentYear + 1 : currentYear}`
      )
    ),
    {
      validations: [
        dateValidation,
        (val) =>
          moment(val).isBefore(firstCoupon.getValue())
            ? "Cannot be before First Coupon"
            : null,
        (val) =>
          moment(val).isSameOrBefore(marketConditionsDate.getValue())
            ? "Must be after Date of Market Conditions"
            : null,
        (val) =>
          frequencyValidation(
            firstCoupon.getValue(),
            val,
            PrincipalRate.SemiAnnual
          ),
      ],
      setValueCallback: defaultCallback,
    }
  );

  const finalPrincipal = new FiCurveInput(
    "Final Principal",
    useState(
      moment(initialInputValues?.finalPrincipal || `12/01/${currentYear + 19}`)
    ),
    {
      validations: [
        dateValidation,
        (val) =>
          moment(val).isBefore(firstPrincipal.getValue())
            ? "Cannot be before First Principal"
            : null,
        (val) =>
          moment(val).diff(deliveryDate.getValue(), "months", true) > 30 * 12
            ? "Delivery Date to Final Principal cannot be longer than 30 years"
            : null,
        (val) =>
          frequencyValidation(
            firstPrincipal.getValue(),
            val,
            principalFrequency.getValue()
          ),
      ],
      setValueCallback: defaultCallback,
    }
  );

  const useCallDate = new FiCurveInput(
    "Use Call Date",
    useState(
      initialInputValues?.useCallDate !== undefined
        ? Boolean(initialInputValues.useCallDate)
        : true
    ),
    {
      setValueCallback: callApiCallback,
    }
  );
  const callDate = new FiCurveInput(
    "Call Date",
    useState(
      moment(initialInputValues?.callDate || `12/01/${currentYear + 9}`)
    ),
    {
      setValueCallback: callApiCallback,
      disableValidations: !useCallDate.getValue(),
      validations: [
        dateValidation,
        (val) =>
          moment(val).isBefore(deliveryDate.getValue())
            ? "Cannot be before Delivery Date"
            : null,
        (val) =>
          moment(val).isAfter(finalPrincipal.getValue())
            ? "Cannot be after Final Principal"
            : null,
      ],
    }
  );

  const usePricingCushion = new FiCurveInput(
    "Use Pricing Cushion",
    useState(!!initialInputValues?.usePricingCushion || false),
    {
      setValueCallback: defaultCallback,
    }
  );
  const cushion = new FiCurveInput(
    "Cushion",
    useState(initialInputValues?.cushion || 0),
    {
      disableValidations: !usePricingCushion.getValue(),
      validations: [
        (val) => (isNaN(val) ? "Pricing Cushion is invalid" : null),
      ],
      setValueCallback: defaultCallback,
    }
  );

  const useCustomCouponYield = new FiCurveInput(
    "Use Custom Coupon/Yield",
    useState(!!initialInputValues?.useCustomCouponYield || false),
    {
      setValueCallback: callApiCallback,
    }
  );

  const customCouponYield = new FiCurveInput(
    "Coupon/Yield",
    useState(initialInputValues?.customCouponYield || 0),
    {
      setValueCallback: callApiCallback,
      disableValidations: !useCustomCouponYield.getValue(),
      validations: [(val) => (isNaN(val) ? "Coupon/Yield is invalid" : null)],
    }
  );

  const dealBuilderInputs = {
    bondSolutionOption: new FiCurveInput(
      "Sizing Solution",
      useState(initialInputValues?.bondSolutionOption || BondSolutions[0]),
      {
        setValueCallback: defaultCallback,
      }
    ),
    parAmount,
    denomination,
    borrower: new FiCurveInput(
      "Borrower",
      useState(initialInputValues?.borrower || ""),
      { isRequired: false, setValueCallback: defaultCallback }
    ),
    issuanceTitle: new FiCurveInput(
      "Issuance Title",
      useState(initialInputValues?.issuanceTitle || ""),
      {
        isRequired: false,
        setValueCallback: defaultCallback,
      }
    ),
    stateOfIssuance: new FiCurveInput(
      "State of Issuance",
      useState(initialInputValues?.stateOfIssuance || "Alabama"),
      { setValueCallback: callApiCallback }
    ),
    securityType: new FiCurveInput(
      "Security Type",
      useState(
        initialInputValues?.securityType || "Unlimited Tax General Obligation"
      ),
      { setValueCallback: callApiCallback }
    ),
    ratingAssumption: new FiCurveInput(
      "Rating Assumption",
      useState(initialInputValues?.ratingAssumption || RatingAssumption.AA),
      {
        setValueCallback: callApiCallback,
      }
    ),
    taxDesignation: new FiCurveInput(
      "Tax Designation",
      useState(initialInputValues?.taxDesignation || "Non-Bank Qualified"),
      { setValueCallback: callApiCallback }
    ),
    stateEnhancement: new FiCurveInput(
      "State Enhancement",
      useState(!!initialInputValues?.stateEnhancement || false),
      {
        setValueCallback: callApiCallback,
      }
    ),
    bondInsurance: new FiCurveInput(
      "Bond Insurance",
      useState(!!initialInputValues?.bondInsurance || false),
      {
        setValueCallback: callApiCallback,
      }
    ),
    deliveryDate,
    firstCoupon,
    firstPrincipal,
    finalPrincipal,
    useCallDate,
    callDate,
    principalFrequency,
    marketConditionsDate,
    usePricingCushion,
    cushion,

    useCustomCouponYield,
    customCouponYield,

    useFiCurveParPricing: new FiCurveInput(
      "fiCurve Par Pricing",
      useState(!!initialInputValues?.useFiCurveParPricing || false),
      {
        setValueCallback: defaultCallback,
      }
    ),
    couponSelection: new FiCurveInput(
      "Coupon Selection",
      useState(CouponSelection.FivePercentCoupon),
      {
        setValueCallback: callApiCallback,
      }
    ),
    saleMethod: new FiCurveInput(
      "Sale Method",
      useState(initialInputValues?.saleMethod || SaleMethod.Negotiated),
      {
        setValueCallback: callApiCallback,
      }
    ),
    enhancedRating: new FiCurveInput(
      "Enhanced Rating",
      useState(initialInputValues?.enhancedRating || RatingAssumption.AAPlus),
      {
        setValueCallback: callApiCallback,
      }
    ),
  };
  return dealBuilderInputs;
};

const constructDealBuilderData = (dealBuilderInputs) => ({
  parAmount: dealBuilderInputs.parAmount.getValue(),
  denomination: dealBuilderInputs.denomination.getValue(),
  deliveryDate: dealBuilderInputs.deliveryDate.getValue(),
  firstCoupon: dealBuilderInputs.firstCoupon.getValue(),
  firstPrincipal: dealBuilderInputs.firstPrincipal.getValue(),
  finalPrincipal: dealBuilderInputs.finalPrincipal.getValue(),
  useCallDate: dealBuilderInputs.useCallDate?.getValue(),
  callDate: dealBuilderInputs.callDate.getValue(),
  principalFrequency: dealBuilderInputs.principalFrequency.getValue(),
  borrower: dealBuilderInputs.borrower?.getValue(),
  issuanceTitle: dealBuilderInputs.issuanceTitle?.getValue(),
  ratingAssumption: dealBuilderInputs.ratingAssumption?.getValue(),
  taxDesignation: dealBuilderInputs.taxDesignation?.getValue(),
  marketConditionsDate: dealBuilderInputs.marketConditionsDate.getValue(),
  usePricingCushion: dealBuilderInputs.usePricingCushion?.getValue(),
  cushion: dealBuilderInputs.cushion?.getValue(),
  useCustomCouponYield: dealBuilderInputs.useCustomCouponYield?.getValue(),
  customCouponYield: dealBuilderInputs.customCouponYield?.getValue(),
  useFiCurveParPricing: dealBuilderInputs.useFiCurveParPricing?.getValue(),
  couponSelection: dealBuilderInputs.couponSelection?.getValue(),
  stateEnhancement: dealBuilderInputs.stateEnhancement?.getValue(),
  saleMethod: dealBuilderInputs.saleMethod?.getValue(),
  enhancementRating: dealBuilderInputs.enhancementRating?.getValue(),
});

export function DealBuilderProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const { setActiveError } = useContext(ErrorContext);
  const [dealBuilder, setDealBuilder] = useState(null);
  const [isSavingDeal, setIsSavingDeal] = useState(false);
  const [isBuildingDeal, setIsBuildingDeal] = useState(false);
  const [isLoadingDeal, setIsLoadingDeal] = useState(false);
  const [isPricingManagement, setIsPricingManagement] = useState(false);
  const [currentDealID, setCurrentDealID] = useState(null);
  const [shouldCallApi, setShouldCallApi] = useState(true);
  const [initialInputValues, setInitialInputValues] = useState({});
  const [rawEngineResponse, setRawEngineResponse] = useState(null);
  const [showOverwriteDialog, setShowOverwriteDialog] = useState(false);
  const [shouldAutoBuildDeal, setShouldAutoBuildDeal] = useState(false);
  const [editedSourcesAndUses, setEditedSourcesAndUses] = useState(false);
  const [sourcesAndUses, setSourcesAndUses] = useState({
    sources: [],
    uses: [],
  });
  const [originalParAmount, setOriginalParAmount] = useState(undefined);
  const [parAmountOnBuild, setParAmountOnBuild] = useState(undefined);
  const [usePrincipalValues, setUsePrincipalValues] = useState(false);
  const [principalValues, setPrincipalValues] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [saveToDrafts, setSaveToDrafts] = useState(false);
  const [showOutOfSyncDialog, setShowOutOfSyncDialog] = useState(false);
  const [showPrincipalInputs, setShowPrincipalInputs] = useState(false);
  const [ticAdjustingExpenses, setTicAdjustingExpenses] = useState(undefined);
  const [capitalizedInterestDate, setCapitalizedInterestDate] =
    useState(undefined);
  const [
    showManualPrincipalWarningDialog,
    setShowManualPrincipalWarningDialog,
  ] = useState(false);
  const dealBuilderInputs = useDealBuilderInputs({
    initialInputValues,
    setShouldCallApi,
    setInitialInputValues,
    setFormDirty,
  });
  const currentParAmount = dealBuilderInputs.parAmount.getValue();
  const parAmountDirty = useMemo(
    () =>
      Math.abs(currentParAmount - parAmountOnBuild) > MINIMUM_PAR_AMOUNT_DELTA,
    [parAmountOnBuild, currentParAmount]
  );
  const localStorageDeals = useLocalStorageDeal();

  useEffect(() => {
    if (localStorageDeals?.length) {
      transferLocalStorageDeals(localStorageDeals, getAccessTokenSilently);
    }
  }, [localStorageDeals, getAccessTokenSilently]);

  // fetch uses & sources and saved pricing when open deal
  useEffect(() => {
    if (Object.keys(initialInputValues).length > 0) {
      setShouldAutoBuildDeal(true);
    }
  }, [initialInputValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldAutoBuildDeal) {
      loadSavedDeal();
    }
  }, [dealBuilderInputs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (usePrincipalValues && !originalParAmount) {
      setOriginalParAmount(dealBuilderInputs.parAmount.getValue());
    }
  }, [usePrincipalValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dealBuilder?.setTicAdjustingExpenses(ticAdjustingExpenses);
  }, [dealBuilder, ticAdjustingExpenses]);

  useEffect(() => {
    dealBuilder?.setCapitalizedInterestDate(capitalizedInterestDate);
  }, [dealBuilder, capitalizedInterestDate]);

  const loadSavedDeal = async () => {
    setShouldAutoBuildDeal(false);
    setIsLoadingDeal(true);
    try {
      await getUsesAndSources();
      const pricing = await getPricing();
      if (!pricing?.length) {
        await buildDeal();
      } else {
        setRawEngineResponse(pricing);
        const dealBuilderData = constructDealBuilderData(dealBuilderInputs);
        if (originalParAmount) {
          dealBuilderData.parAmount = originalParAmount;
        }
        const newDealBuilder = new DealBuilderService(
          undefined,
          dealBuilderData,
          pricing
        );
        pushPrincipalValues({
          dealBuilder: newDealBuilder,
          setUsePrincipalValues,
          principalValues,
        });
        setDealBuilder(newDealBuilder);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDeal(false);
      setFormDirty(false);
    }
  };

  const runBuildQuery = () =>
    new Promise(async (resolve) => {
      const accessToken = await getAccessTokenSilently();
      const api = new FiCurveApi(accessToken);

      api.runDealBuilder(
        dealBuilderInputs,
        (response) => {
          setRawEngineResponse(response);
          setShouldCallApi(false);
          const dealBuilderData = constructDealBuilderData(dealBuilderInputs);
          if (originalParAmount) {
            dealBuilderData.parAmount = originalParAmount;
          }
          setParAmountOnBuild(dealBuilderData.parAmount);
          const newDealBuilder = new DealBuilderService(
            undefined,
            dealBuilderData,
            response
          );
          pushPrincipalValues({
            dealBuilder: newDealBuilder,
            setUsePrincipalValues,
            principalValues,
          });
          newDealBuilder.setTicAdjustingExpenses(ticAdjustingExpenses);
          newDealBuilder.setCapitalizedInterestDate(capitalizedInterestDate);
          setDealBuilder(newDealBuilder);
          setIsBuildingDeal(false);
          resolve();
        },
        (err) => {
          console.debug(err);
          setIsBuildingDeal(false);
          setActiveError(
            `There was an error communicating with the API: ${
              err.message ?? err
            }`
          );
          resolve();
        }
      );
    });

  const buildDeal = async () => {
    const dealInputs = Object.values(dealBuilderInputs);
    for (let i = 0; i < dealInputs.length; i++) {
      const valErr = dealInputs[i].validate();
      if (!!valErr) {
        setActiveError(`${dealInputs[i].name} is invalid: ${valErr.message}`);
        return;
      }
    }

    if (dealBuilderInputs?.useCustomCouponYield?.getValue()) {
      const dealBuilderData = constructDealBuilderData(dealBuilderInputs);
      if (originalParAmount) {
        dealBuilderData.parAmount = originalParAmount;
      }
      const newDealBuilder = new DealBuilderService(
        undefined,
        dealBuilderData,
        []
      );
      pushPrincipalValues({
        dealBuilder: newDealBuilder,
        setUsePrincipalValues,
        principalValues,
      });
      newDealBuilder.setTicAdjustingExpenses(ticAdjustingExpenses);
      newDealBuilder.setCapitalizedInterestDate(capitalizedInterestDate);
      setDealBuilder(newDealBuilder);
    } else {
      setActiveError(null);
      setIsBuildingDeal(true);

      if (shouldCallApi || !rawEngineResponse || parAmountDirty) {
        setDealBuilder(null);
        await runBuildQuery();
      } else {
        const dealBuilderData = constructDealBuilderData(dealBuilderInputs);
        if (originalParAmount) {
          dealBuilderData.parAmount = originalParAmount;
        }
        const newDealBuilder = new DealBuilderService(
          undefined,
          dealBuilderData,
          rawEngineResponse
        );
        pushPrincipalValues({
          dealBuilder: newDealBuilder,
          setUsePrincipalValues,
          principalValues,
        });
        newDealBuilder.setTicAdjustingExpenses(ticAdjustingExpenses);
        newDealBuilder.setCapitalizedInterestDate(capitalizedInterestDate);
        setDealBuilder(newDealBuilder);
        setIsBuildingDeal(false);
      }
    }

    setFormDirty(false);
  };

  const getPricing = async () =>
    new Promise(async (resolve, reject) => {
      const accessToken = await getAccessTokenSilently();
      const api = new FiCurveApi(accessToken);
      api.runGetPricing(
        currentDealID,
        (pricing) => {
          resolve(pricing);
        },
        (err) => {
          console.debug(err);
          setActiveError(
            `There was an error communicating with the API: ${
              err.message ?? err
            }`
          );
          reject();
        }
      );
    });

  const savePricing = async (dealId, pricing) => {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runSavePricing(
      dealId,
      pricing,
      () => {},
      (err) => {
        console.debug(err);
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  };

  const getUsesAndSources = async () => {
    const accessToken = await getAccessTokenSilently();
    const api = new FiCurveApi(accessToken);
    api.runGetCategories(
      currentDealID,
      (categories) => {
        const sources = [];
        const uses = [];
        let newTicAdjustingExpenses, newCapitalizedInterestDate;
        categories.forEach(({ type, ...category }) => {
          if (type === "Source") {
            sources.push(category);
          } else if (category.isTicCategory) {
            newTicAdjustingExpenses =
              createTicAdjustingExpensesFromCategory(category);
          } else if (category.isCapitalizedInterest) {
            newCapitalizedInterestDate =
              getCapitalizedInterestDateFromCategory(category);
          } else {
            uses.push(category);
          }
        });
        setSourcesAndUses({
          sources,
          uses,
        });
        setTicAdjustingExpenses(newTicAdjustingExpenses);
        setCapitalizedInterestDate(newCapitalizedInterestDate);
      },
      (err) => {
        console.debug(err);
        setActiveError(
          `There was an error communicating with the API: ${err.message ?? err}`
        );
      }
    );
  };

  const saveDeal = async (dealId, _isPricingManagement = false) =>
    new Promise(async (resolve, reject) => {
      const dealInputs = Object.keys(dealBuilderInputs)?.reduce(
        (obj, key) => {
          return { ...obj, [key]: dealBuilderInputs?.[key]?.getValue() };
        },
        {
          parAmountOnBuild,
          originalParAmount,
          isBidManagement: _isPricingManagement,
          principalValues: JSON.stringify(principalValues),
        }
      );

      const accessToken = await getAccessTokenSilently();
      const api = new FiCurveApi(accessToken);

      if (!dealId) {
        api.runCreateDeal(
          dealInputs,
          (response) => {
            setCurrentDealID(response.id);
            setIsSavingDeal(false);
            setIsPricingManagement(_isPricingManagement);
            resolve(response.id);
          },
          (err) => {
            console.debug(err);
            setIsSavingDeal(false);
            setActiveError(
              `There was an error communicating with the API: ${
                err.message ?? err
              }`
            );
            reject();
          }
        );
      } else {
        api.runUpdateDeal(
          dealId,
          dealInputs,
          () => {
            setIsPricingManagement(_isPricingManagement);
            setIsSavingDeal(false);
            resolve(currentDealID);
          },
          (err) => {
            console.debug(err);
            setIsSavingDeal(false);
            setActiveError(
              `There was an error communicating with the API: ${
                err.message ?? err
              }`
            );
            reject();
          }
        );
      }
      setShowOverwriteDialog(false);
    });

  const handleSaveDealToDrafts = async ({ outOfSyncOverride = false }) => {
    if ((formDirty || parAmountDirty) && !outOfSyncOverride) {
      setShowOutOfSyncDialog(true);
      setSaveToDrafts(true);
    } else if (currentDealID && !isPricingManagement) {
      setShowOverwriteDialog(true);
    } else if (editedSourcesAndUses) {
      setEditedSourcesAndUses(false);
      handleSaveNew();
    } else {
      const dealId = await saveDeal(currentDealID);
      if (rawEngineResponse) {
        await savePricing(dealId, rawEngineResponse);
      }
    }
  };

  const handleSaveNew = async () => {
    const dealId = await saveDeal();
    const sourcesAndUsesWithoutIds =
      getSourcesAndUsesWithoutIds(sourcesAndUses);
    const data = combineSourcesAndUses(
      sourcesAndUsesWithoutIds,
      ticAdjustingExpenses,
      capitalizedInterestDate
    );
    await handleSourceAndUses({
      data,
      dealId,
    });
    if (rawEngineResponse) {
      await savePricing(dealId, rawEngineResponse);
    }
    return dealId;
  };
  const handleOverwriteExisting = async () => {
    const dealId = await saveDeal(currentDealID);
    if (rawEngineResponse) {
      await savePricing(dealId, rawEngineResponse);
    }
  };

  const handleOpenDeal = (id, data) => {
    setIsPricingManagement(data.isPricingManagement);
    const newPrincipalValues = data.principalValues
      ? JSON.parse(data.principalValues)
      : [];
    setOriginalParAmount(data.originalParAmount);
    setParAmountOnBuild(data.parAmountOnBuild || data.parAmount);
    setPrincipalValues(newPrincipalValues);
    setUsePrincipalValues(!!newPrincipalValues.length);
    setShowPrincipalInputs(false);
    setInitialInputValues(data);
    setCurrentDealID(id);
  };

  const handleSourceAndUses = ({ data, dealId = currentDealID }) =>
    new Promise(async (resolve, reject) => {
      if (!dealId) {
        const [
          newSourcesAndUses,
          newTicAdjustingExpenses,
          newCapitalizedInterestDate,
        ] = separateSourcesAndUses(data);
        setSourcesAndUses(newSourcesAndUses);
        setTicAdjustingExpenses(newTicAdjustingExpenses);
        setCapitalizedInterestDate(newCapitalizedInterestDate);
        setEditedSourcesAndUses(true);
        resolve([
          newSourcesAndUses,
          newTicAdjustingExpenses,
          newCapitalizedInterestDate,
        ]);
      } else {
        setActiveError(null);
        const accessToken = await getAccessTokenSilently();
        const api = new FiCurveApi(accessToken);
        api.runSaveCategories(
          dealId,
          data,
          (categories) => {
            const sources = [];
            const uses = [];
            let newTicAdjustingExpenses, newCapitalizedInterestDate;
            categories.forEach(({ type, ...category }) => {
              if (type === "Source") {
                sources.push(category);
              } else if (category.isTicCategory) {
                newTicAdjustingExpenses =
                  createTicAdjustingExpensesFromCategory(category);
              } else if (category.isCapitalizedInterest) {
                newCapitalizedInterestDate =
                  getCapitalizedInterestDateFromCategory(category);
              } else {
                uses.push(category);
              }
            });
            const newSourcesAndUses = {
              sources,
              uses,
            };
            setSourcesAndUses(newSourcesAndUses);
            setTicAdjustingExpenses(newTicAdjustingExpenses);
            setCapitalizedInterestDate(newCapitalizedInterestDate);
            resolve([
              newSourcesAndUses,
              newTicAdjustingExpenses,
              newCapitalizedInterestDate,
            ]);
          },
          (err) => {
            console.debug(err);
            setActiveError(
              `There was an error communicating with the API: ${
                err.message ?? err
              }`
            );
            reject();
          }
        );
      }
    });

  const handleClearDeal = () => {
    setDealBuilder(null);
    setShouldCallApi(true);
    setSourcesAndUses({
      sources: [],
      uses: [],
    });
    setPrincipalValues([]);
    setUsePrincipalValues(false);
    setShowPrincipalInputs(false);
    setEditedSourcesAndUses(false);
    setCurrentDealID(null);
    setOriginalParAmount(undefined);
    setIsPricingManagement(false);
    setTicAdjustingExpenses();
    setCapitalizedInterestDate();
    setFormDirty(false);
  };

  const handleDeleteCurrentDeal = () => {
    setCurrentDealID(null);
    setIsPricingManagement(false);
    if (sourcesAndUses.sources.length || sourcesAndUses.uses.length) {
      setSourcesAndUses(getSourcesAndUsesWithoutIds(sourcesAndUses));
      setEditedSourcesAndUses(true);
    }
  };

  const handleSaveToPricingManagement = async ({
    outOfSyncOverride = false,
  }) => {
    if ((formDirty || parAmountDirty) && !outOfSyncOverride) {
      setShowOutOfSyncDialog(true);
      setSaveToDrafts(false);
    } else {
      const dealId = await saveDeal(currentDealID, true);
      if (editedSourcesAndUses) {
        await handleSourceAndUses({
          data: combineSourcesAndUses(
            sourcesAndUses,
            ticAdjustingExpenses,
            capitalizedInterestDate
          ),
          dealId,
        });
        setEditedSourcesAndUses(false);
      }
      if (rawEngineResponse) {
        await savePricing(dealId, rawEngineResponse);
      }
    }
  };

  const handleSaveWithoutRebuild = () => {
    setShowOutOfSyncDialog(false);
    if (saveToDrafts) {
      handleSaveDealToDrafts({ outOfSyncOverride: true });
    } else {
      handleSaveToPricingManagement({ outOfSyncOverride: true });
    }
  };

  const handleRebuildAndSave = async () => {
    setShowOutOfSyncDialog(false);
    await buildDeal();
    if (saveToDrafts) {
      handleSaveDealToDrafts({ outOfSyncOverride: true });
    } else {
      handleSaveToPricingManagement({ outOfSyncOverride: true });
    }
  };

  const handleDisablePrincipalValues = () => {
    setUsePrincipalValues(false);
    setShowPrincipalInputs(false);
    setPrincipalValues([]);
    if (originalParAmount) {
      dealBuilderInputs.parAmount.setValue(originalParAmount, true);
      dealBuilder.parAmount = originalParAmount;
    } else {
      dealBuilder.parAmount = dealBuilderInputs.parAmount.getValue();
    }
    dealBuilder.buildTableData();
    setShowManualPrincipalWarningDialog(false);
  };

  return (
    <DealBuilderContext.Provider
      value={{
        dealBuilderInputs,
        dealBuilder,
        sourcesAndUses,
        setSourcesAndUses,
        ticAdjustingExpenses,
        capitalizedInterestDate,
        usePrincipalValues,
        setUsePrincipalValues,
        principalValues,
        setPrincipalValues,
        initialInputValues,
        isSavingDeal,
        isBuildingDeal,
        isLoadingDeal,
        isPricingManagement,
        handleOpenDeal,
        currentDealID,
        buildDeal,
        showOverwriteDialog,
        setShowOverwriteDialog,
        showOutOfSyncDialog,
        setShowOutOfSyncDialog,
        showManualPrincipalWarningDialog,
        setShowManualPrincipalWarningDialog,
        showPrincipalInputs,
        setShowPrincipalInputs,
        handleSaveWithoutRebuild,
        handleRebuildAndSave,
        handleOverwriteExisting,
        handleSaveNew,
        handleSaveDealToDrafts,
        handleSourceAndUses,
        handleClearDeal,
        handleDeleteCurrentDeal,
        handleSaveToPricingManagement,
        handleDisablePrincipalValues,
      }}
    >
      {children}
    </DealBuilderContext.Provider>
  );
}
