import { Container, Grid, Paper, Box } from "@mui/material";
import NationalBenchMarks from "./national-benchmarks";
import MunicipalMarkets from "./municipal-markets";
import HistoricalGraphs from "./historical-graphs";
import { useState } from "react";
// import EquityMarketEconomicData from "./equity-markets-economic-data";

const SAVED_INPUT_LOCAL_STORAGE_KEY = "marketMonitorInputValues";

export default function MarketMonitor() {
  const localStorageValue = localStorage.getItem(SAVED_INPUT_LOCAL_STORAGE_KEY);
  const [savedInputValues, setSavedInputValues] = useState(
    localStorageValue ? JSON.parse(localStorageValue) : {}
  );
  const handleInputUpdate = ({ key, value }) => {
    const newInputValues = { ...savedInputValues, [key]: value };
    setSavedInputValues(newInputValues);
    localStorage.setItem(
      SAVED_INPUT_LOCAL_STORAGE_KEY,
      JSON.stringify(newInputValues)
    );
  };
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} pt={2}>
        <Grid item xs={4.5}>
          <Paper component={Box} p={2} height="100%">
            <NationalBenchMarks
              handleInputUpdate={handleInputUpdate}
              savedInputValues={savedInputValues}
            />
          </Paper>
        </Grid>

        <Grid item xs={3.5}>
          <Paper component={Box} p={2} height="100%">
            <HistoricalGraphs
              handleInputUpdate={handleInputUpdate}
              savedInputValues={savedInputValues}
            />
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper component={Box} p={2} height="100%">
            <MunicipalMarkets
              handleInputUpdate={handleInputUpdate}
              savedInputValues={savedInputValues}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* <Grid container spacing={4}>
        <Grid item xs={7}>
          <Box
            border="solid 1px #000"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Typography>
              INSERT STOCK MARKETS GRAPH (Dow Jones, S&P 500, NASDAQ)
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <EquityMarketEconomicData />
        </Grid>
      </Grid> */}
    </Container>
  );
}
