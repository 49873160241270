import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Button, ButtonGroup, Divider, Stack } from "@mui/material";
import {
  BondSolutions,
  PrincipalRate,
  RatingAssumption,
  SecurityType,
  States,
  TaxDesignation,
  CouponSelection,
  SaleMethod,
} from "../constants";
import { useReactToPrint } from "react-to-print";
import {
  FiCurveInputCheckbox,
  FiCurveInputCurrency,
  FiCurveInputDate,
  FiCurveInputPercent,
  FiCurveInputSelect,
  FiCurveInputSwitch,
  FiCurveInputText,
} from "../inputs/inputs";
import { Download, Print } from "@mui/icons-material";
import { CSVLink } from "react-csv/lib";
import UploadImageInput from "../components/upload-image-input";
import { DealBuilderContext } from "../contexts/DealBuilderContext";

function DealBuilderInputPanel(props) {
  const { sx, reactToPrintContent, setFooterLogo } = props;

  const {
    initialInputValues,
    dealBuilderInputs,
    isBuildingDeal,
    isLoadingDeal,
    isSavingDeal,
    dealBuilder,
    principalValues,
    usePrincipalValues,
    buildDeal,
    handleSaveDealToDrafts,
    handleClearDeal,
    handleSaveToPricingManagement,
    setShowManualPrincipalWarningDialog,
  } = useContext(DealBuilderContext);

  useEffect(() => {
    if (!!Object.keys(initialInputValues)?.length) {
      Object.keys(dealBuilderInputs).forEach((key) => {
        if (![null, undefined]?.includes(initialInputValues?.[key])) {
          dealBuilderInputs?.[key]?.setValue(initialInputValues?.[key]);
        }
      });
    }
  }, [initialInputValues, dealBuilderInputs]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
  });

  const handleAddLogo = (state) => {
    setFooterLogo(state);
  };

  const csvLink = useRef();

  const disablePrincipalSensitiveFields = useMemo(() => {
    return (
      usePrincipalValues ||
      principalValues?.filter((value) => value !== null).length
    );
  }, [usePrincipalValues, principalValues]);

  const handleShowWarningDialog =
    !isBuildingDeal && disablePrincipalSensitiveFields
      ? () => setShowManualPrincipalWarningDialog(true)
      : undefined;

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
      sx={{ m: 1, ...sx }}
    >
      <ButtonGroup fullWidth>
        <Button
          onClick={() => {
            buildDeal();
          }}
          color="success"
          disabled={isBuildingDeal || isLoadingDeal}
          variant="contained"
        >
          Build Deal
        </Button>
        <Button
          color="success"
          disabled={!dealBuilder || isSavingDeal}
          onClick={handleSaveDealToDrafts}
        >
          Save To Drafts
        </Button>
      </ButtonGroup>
      {!!dealBuilder && (
        <Button onClick={handleSaveToPricingManagement} variant="outlined">
          Save To Pricing Management
        </Button>
      )}
      {!!dealBuilder && (
        <Button color="warning" onClick={handleClearDeal} variant="outlined">
          Clear Deal
        </Button>
      )}
      {!!dealBuilder && (
        <>
          <ButtonGroup variant="contained" fullWidth>
            <Button onClick={handlePrint} disabled={isBuildingDeal}>
              <Print />
              &nbsp;Print
            </Button>

            <Button
              onClick={() => csvLink.current.link.click()}
              disabled={isBuildingDeal}
            >
              <Download />
              &nbsp;Save as CSV
            </Button>
            <CSVLink
              data={dealBuilder.getTableRowsAsCsv()}
              filename="fiCurve_deal_builder.csv"
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </ButtonGroup>

          <UploadImageInput
            disabled={isBuildingDeal}
            onChange={handleAddLogo}
            text="Add Footer Logo"
          />
        </>
      )}
      <Divider sx={{ "&&": { my: 2 } }} />
      <FiCurveInputSelect
        disabled={isBuildingDeal}
        input={dealBuilderInputs.bondSolutionOption}
        options={BondSolutions}
      />
      <FiCurveInputCurrency
        disabled={isBuildingDeal || disablePrincipalSensitiveFields}
        input={dealBuilderInputs.parAmount}
        handleEditButtonClick={handleShowWarningDialog}
      />
      <FiCurveInputCurrency
        disabled={isBuildingDeal}
        input={dealBuilderInputs.denomination}
      />
      <FiCurveInputText
        disabled={isBuildingDeal}
        input={dealBuilderInputs.borrower}
      />
      <FiCurveInputText
        disabled={isBuildingDeal}
        input={dealBuilderInputs.issuanceTitle}
      />

      <FiCurveInputSwitch
        disabled={isBuildingDeal}
        input={dealBuilderInputs.useCustomCouponYield}
      />
      {dealBuilderInputs.useCustomCouponYield.getValue() && (
        <FiCurveInputPercent input={dealBuilderInputs.customCouponYield} />
      )}

      {!dealBuilderInputs.useCustomCouponYield.getValue() && (
        <>
          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.couponSelection}
            options={Object.values(CouponSelection)}
          />
          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.stateOfIssuance}
            options={Object.values(States)}
          />

          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.securityType}
            options={Object.values(SecurityType)}
          />

          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.ratingAssumption}
            options={Object.values(RatingAssumption)}
          />

          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.taxDesignation}
            options={Object.values(TaxDesignation)}
          />

          <FiCurveInputSelect
            disabled={isBuildingDeal}
            input={dealBuilderInputs.saleMethod}
            options={Object.values(SaleMethod)}
          />

          <FiCurveInputSwitch
            disabled={isBuildingDeal}
            input={dealBuilderInputs.stateEnhancement}
          />

          {dealBuilderInputs.stateEnhancement.getValue() && (
            <FiCurveInputSelect
              disabled={isBuildingDeal}
              input={dealBuilderInputs.enhancedRating}
              options={Object.values(RatingAssumption)}
            />
          )}

          <FiCurveInputCheckbox
            disabled={isBuildingDeal}
            input={dealBuilderInputs.bondInsurance}
          />
        </>
      )}

      <FiCurveInputDate
        disabled={isBuildingDeal}
        input={dealBuilderInputs.deliveryDate}
      />
      <FiCurveInputDate
        disabled={isBuildingDeal || disablePrincipalSensitiveFields}
        input={dealBuilderInputs.firstCoupon}
        handleEditButtonClick={handleShowWarningDialog}
      />
      <FiCurveInputDate
        disabled={isBuildingDeal || disablePrincipalSensitiveFields}
        input={dealBuilderInputs.firstPrincipal}
        handleEditButtonClick={handleShowWarningDialog}
      />
      <FiCurveInputDate
        disabled={isBuildingDeal || disablePrincipalSensitiveFields}
        input={dealBuilderInputs.finalPrincipal}
        handleEditButtonClick={handleShowWarningDialog}
      />
      <FiCurveInputSelect
        disabled={isBuildingDeal || disablePrincipalSensitiveFields}
        input={dealBuilderInputs.principalFrequency}
        options={Object.values(PrincipalRate)}
        handleEditButtonClick={handleShowWarningDialog}
      />
      <FiCurveInputDate
        disabled={isBuildingDeal}
        input={dealBuilderInputs.marketConditionsDate}
        disableDate="1/1/2021"
      />
      <FiCurveInputSwitch
        disabled={isBuildingDeal}
        input={dealBuilderInputs.useCallDate}
      />
      {dealBuilderInputs.useCallDate.getValue() && (
        <FiCurveInputDate
          disabled={isBuildingDeal}
          input={dealBuilderInputs.callDate}
        />
      )}

      <FiCurveInputSwitch
        disabled={isBuildingDeal}
        input={dealBuilderInputs.usePricingCushion}
      />
      {dealBuilderInputs.usePricingCushion.getValue() && (
        <FiCurveInputPercent
          disabled={isBuildingDeal}
          input={dealBuilderInputs.cushion}
        />
      )}
      {!dealBuilderInputs.useCustomCouponYield.getValue() && (
        <FiCurveInputSwitch
          disabled={isBuildingDeal}
          input={dealBuilderInputs.useFiCurveParPricing}
        />
      )}
    </Stack>
  );
}

export { DealBuilderInputPanel };
