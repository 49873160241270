import moment from "moment";
import { CouponSelection } from "../constants";

const ApiUrl = process.env.REACT_APP_API_URL;
const DealBuilderEndpoint = "deal-builder";
const DealEndpoints = "deal";
const CategoryEndpoints = "categories";
const PricingEndpoints = "pricing";
const GetDealsEndpoint = "deals";
const GetPricingManagementDealsEndpoint = "bid-management-deals";
const BidsEndpoints = "bids";
const LocalStorageDealEndpoint = "localstorage-deal";
const UserInfoEndpoint = "user-info";
const TeamsEndpoint = "teams";

const responseJsonParser = (text) => {
  try {
    return JSON.parse(text);
  } catch (_) {
    throw new Error(`Failed to parse response as JSON: ${text}`);
  }
};

const responseErrorHandler = (jsonBody) => {
  if (jsonBody?.["error"]) {
    throw jsonBody["error"];
  }
  return jsonBody;
};

const getDealBody = (dealInputs) => {
  const body = {
    modelVersion: process.env.REACT_APP_DEAL_BUILDER_MODEL_VERSION,
  };
  for (const [key, value] of Object.entries(dealInputs)) {
    let inputValue = value;
    if (inputValue?._isAMomentObject) {
      inputValue = inputValue.unix();
    }
    body[key] = inputValue;
  }
  return body;
};

const getCategoriesBody = ({ sources, uses }) => {
  const categories = [
    ...sources.map((source) => ({ ...source, type: "Source" })),
    ...uses.map((use) => ({ ...use, type: "Use" })),
  ];
  return { categories };
};

const getLocalStorageDealBody = ({
  inputs,
  sourcesAndUses: { sources = [], uses = [] },
}) => {
  const deal = {};
  for (const [key, value] of Object.entries(inputs)) {
    let inputValue = value;
    if (
      [
        "callDate",
        "deliveryDate",
        "finalPrincipal",
        "firstCoupon",
        "firstPrincipal",
        "marketConditionsDate",
      ].includes(key)
    ) {
      inputValue = moment(inputValue).unix();
    }
    deal[key] = inputValue;
  }

  return {
    deal,
    categories: [
      ...sources.map(({ name, items }) => ({
        name,
        items: items.map(({ k, v }) => ({ name: k, value: v })),
        type: "Source",
      })),
      ...uses.map(({ name, items }) => ({
        name,
        items: items.map(({ k, v }) => ({ name: k, value: v })),
        type: "Use",
      })),
    ],
  };
};

class FiCurveApi {
  authToken;

  constructor(authToken) {
    this.authToken = authToken;
  }

  runDealBuilder(dealBuilderInputs, responseHandler, errHandler) {
    if (!this.authToken) {
      if (!!errHandler) {
        errHandler("Auth token cannot be empty");
      }
      return;
    }
    const couponUserInput =
      dealBuilderInputs.couponSelection.getValue() ===
      CouponSelection.FivePercentCoupon;

    let body = {
      parAmount: dealBuilderInputs.parAmount.getValue(),
      marketConditionsDate: !!dealBuilderInputs.marketConditionsDate.getValue()
        ? moment(dealBuilderInputs.marketConditionsDate.getValue()).unix()
        : "",
      callDate:
        dealBuilderInputs.useCallDate.getValue() &&
        !!dealBuilderInputs.callDate.getValue()
          ? moment(dealBuilderInputs.callDate.getValue()).unix()
          : "",
      bondInsurance: dealBuilderInputs.bondInsurance.getValue(),
      enhancedRating: !!dealBuilderInputs.stateEnhancement.getValue()
        ? dealBuilderInputs.enhancedRating.getValue()
        : "",
      saleMethod: dealBuilderInputs.saleMethod.getValue(),
      stateEnhancement: dealBuilderInputs.stateEnhancement.getValue(),
      stateOfIssuance: dealBuilderInputs.stateOfIssuance.getValue(),
      securityType: dealBuilderInputs.securityType.getValue(),
      ratingAssumption: dealBuilderInputs.ratingAssumption.getValue(),
      taxDesignation: dealBuilderInputs.taxDesignation.getValue(),
      couponUserInput,
      modelVersion: process.env.REACT_APP_DEAL_BUILDER_MODEL_VERSION,
    };

    if (dealBuilderInputs?.useCustomCouponYield.getValue()) {
      body = {
        parAmount: dealBuilderInputs.parAmount.getValue(),
        marketConditionsDate:
          !!dealBuilderInputs.marketConditionsDate.getValue()
            ? moment(dealBuilderInputs.marketConditionsDate.getValue()).unix()
            : "",
        callDate:
          dealBuilderInputs.useCallDate.getValue() &&
          !!dealBuilderInputs.callDate.getValue()
            ? moment(dealBuilderInputs.callDate.getValue()).unix()
            : "",
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(body),
    };

    fetch(this.buildUrl(DealBuilderEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runCreateDeal(dealInputs, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(getDealBody(dealInputs)),
    };

    fetch(this.buildUrl(DealEndpoints), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runUpdateDeal(dealId, dealInputs, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(getDealBody(dealInputs)),
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runDuplicateDeal(dealId, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/duplicate`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: {},
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runReassignDeal(dealId, userId, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/reassign`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify({ user_id: userId }),
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runGetDeals(responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(GetDealsEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runGetCategories(dealId, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/${CategoryEndpoints}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runDeleteDeal(dealId, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runGetPricing(dealId, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/${PricingEndpoints}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runSavePricing(dealId, pricing, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/${PricingEndpoints}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify({ pricing }),
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runSaveCategories(dealId, sourcesAndUses, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const endpoint = `${DealEndpoints}/${dealId}/${CategoryEndpoints}`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(getCategoriesBody(sourcesAndUses)),
    };

    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler);
  }

  runSaveLocalStorageDeal(localStorageRecord, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(getLocalStorageDealBody(localStorageRecord)),
    };

    fetch(this.buildUrl(LocalStorageDealEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler)
      .catch(errHandler);
  }

  runGetPricingManagementDeals(responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(GetPricingManagementDealsEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler)
      .catch(errHandler);
  }

  runSaveBidsToDeal(dealId, bids, responseHandler, errHandler) {
    if (!this.authToken) {
      errHandler("Auth token cannot be empty");
      return;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify({ bids }),
    };

    const endpoint = `${DealEndpoints}/${dealId}/${BidsEndpoints}`;
    fetch(this.buildUrl(endpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler)
      .catch(errHandler);
  }

  runGetUserInfo(responseHandler, errHandler) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(UserInfoEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler)
      .catch(errHandler);
  }

  runGetTeams(responseHandler, errHandler) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    };

    fetch(this.buildUrl(TeamsEndpoint), requestOptions)
      .then((res) => res.text())
      .then(responseJsonParser)
      .then(responseErrorHandler)
      .then(responseHandler, errHandler)
      .catch(errHandler);
  }

  buildUrl(endpoint) {
    return `${ApiUrl}/${endpoint}`;
  }
}

export default FiCurveApi;
