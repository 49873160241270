import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import useFHLBRawData from "../../pricing-management/useFHLBRawData";
import useUSTreasuryRawData from "../national-benchmarks/useUSTreasuryRawData";
import useAAABenchmarkRawData from "../national-benchmarks/useAAABenchmarkRawData";
import moment from "moment";
import { Line } from "react-chartjs-2";

const TIME_SELECT_OPTIONS = [
  "1-Month",
  "3-Month",
  "6-Month",
  "1-Year",
  "2-Year",
  "3-Year",
];

const DATASET_OPTIONS = [
  "AAA (5Yr)",
  "UST (5Yr)",
  "FHLB (5Yr)",
  "AAA (10Yr)",
  "UST (10Yr)",
  "FHLB (10Yr)",
  "AAA (20Yr)",
  "UST (20Yr)",
  "FHLB (20Yr)",
  "AAA (30Yr)",
  "UST (30Yr)",
  "FHLB (30Yr)",
];

const COLORS = {
  "AAA (5Yr)": {
    borderColor: "rgb(54, 162, 235)",
    backgroundColor: "rgb(54, 162, 235, 0.5)",
  },
  "AAA (10Yr)": {
    borderColor: "rgb(114, 126, 149)",
    backgroundColor: "rgb(114, 126, 149, 0.5)",
  },
  "AAA (20Yr)": {
    borderColor: "rgb(116, 191, 232)",
    backgroundColor: "rgb(116, 191, 232, 0.5)",
  },
  "AAA (30Yr)": {
    borderColor: "rgb(101, 196, 181)",
    backgroundColor: "rgb(101, 196, 181, 0.5)",
  },
  "UST (5Yr)": {
    borderColor: "rgb(155, 168, 232)",
    backgroundColor: "rgb(155, 168, 232, 0.5)",
  },
  "UST (10Yr)": {
    borderColor: "rgb(0, 68, 104)",
    backgroundColor: "rgb(0, 68, 104, 0.5)",
  },
  "UST (20Yr)": {
    borderColor: "rgb(12, 100, 158)",
    backgroundColor: "rgb(12, 100, 158, 0.5)",
  },
  "UST (30Yr)": {
    borderColor: "rgb(64, 138, 127)",
    backgroundColor: "rgb(64, 138, 127, 0.5)",
  },
  "FHLB (5Yr)": {
    borderColor: "rgb(64, 76, 99)",
    backgroundColor: "rgb(64, 76, 99, 0.5)",
  },
  "FHLB (10Yr)": {
    borderColor: "rgb(37, 128, 63)",
    backgroundColor: "rgb(37, 128, 63, 0.5)",
  },
  "FHLB (20Yr)": {
    borderColor: "rgb(107, 120, 184)",
    backgroundColor: "rgb(107, 120, 184, 0.5)",
  },
  "FHLB (30Yr)": {
    borderColor: "rgb(12, 157, 158)",
    backgroundColor: "rgb(12, 157, 158, 0.5)",
  },
};

const getFormattedAAAData = (AAAData) =>
  AAAData.map(({ Date, ...values }) => {
    if (Date) {
      const splitDateArray = Date.replace("Z", "").split("-");
      const newDateString = [
        splitDateArray[1],
        splitDateArray[2],
        splitDateArray[0],
      ].join("/");
      return {
        date: moment(newDateString),
        5: +values["5"],
        10: +values["10"],
        20: +values["20"],
        30: +values["30"],
      };
    }
    return {};
  });

const getFormattedFHLBData = (FHLBData) =>
  FHLBData.map(({ Date, ...values }) => {
    if (Date) {
      return {
        date: moment(Date),
        5: +values["5"],
        10: +values["10"],
        20: +values["20"],
        30: +values["30"],
      };
    }
    return {};
  });

const getFormattedUSTData = (USTData) =>
  USTData.map(({ Date, ...values }) => {
    if (Date) {
      return {
        date: moment(Date),
        5: +values['"5 Yr"'],
        10: +values['"10 Yr"'],
        20: +values['"20 Yr"'],
        30: +values['"30 Yr"'],
      };
    }
    return {};
  }).sort((a, b) => a.date?.unix() - b.date?.unix());

const getFilteredData = ({ dataSet, selectedTimeframe }) => {
  let startDate;
  switch (selectedTimeframe) {
    // 3-Month
    case TIME_SELECT_OPTIONS[1]:
      startDate = moment().subtract(3, "months");
      break;
    // 6-Month
    case TIME_SELECT_OPTIONS[2]:
      startDate = moment().subtract(6, "months");
      break;
    // 1-Year
    case TIME_SELECT_OPTIONS[3]:
      startDate = moment().subtract(1, "years");
      break;
    // 2-Year
    case TIME_SELECT_OPTIONS[4]:
      startDate = moment().subtract(2, "years");
      break;
    // 3-Year
    case TIME_SELECT_OPTIONS[5]:
      startDate = moment().subtract(3, "years");
      break;
    // 1-Month
    default:
      startDate = moment().subtract(1, "months");
  }
  const filteredData = dataSet.filter(({ date }) => date?.isAfter(startDate));
  return filteredData;
};

const getHistoricalBenchmarkData = ({
  selectedDatasets,
  AAAData,
  USTData,
  FHLBData,
}) => {
  const datasets = [];

  // AAA
  if (
    [
      DATASET_OPTIONS[0],
      DATASET_OPTIONS[3],
      DATASET_OPTIONS[6],
      DATASET_OPTIONS[9],
    ].some((option) => selectedDatasets.includes(option))
  ) {
    const shouldInclude5Year = selectedDatasets.includes(DATASET_OPTIONS[0]);
    const shouldInclude10Year = selectedDatasets.includes(DATASET_OPTIONS[3]);
    const shouldInclude20Year = selectedDatasets.includes(DATASET_OPTIONS[6]);
    const shouldInclude30Year = selectedDatasets.includes(DATASET_OPTIONS[9]);
    const fiveYearAAAData = {
      data: [],
      label: DATASET_OPTIONS[0],
      ...COLORS["AAA (5Yr)"],
    };
    const tenYearAAAData = {
      data: [],
      label: DATASET_OPTIONS[3],
      ...COLORS["AAA (10Yr)"],
    };
    const twentyYearAAAData = {
      data: [],
      label: DATASET_OPTIONS[6],
      ...COLORS["AAA (20Yr)"],
    };
    const thirtyYearAAAData = {
      data: [],
      label: DATASET_OPTIONS[9],
      ...COLORS["AAA (30Yr)"],
    };

    AAAData.forEach((dataPoint) => {
      // 5-Year
      if (shouldInclude5Year) {
        fiveYearAAAData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[5],
        });
      }
      // 10-Year
      if (shouldInclude10Year) {
        tenYearAAAData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[10],
        });
      }
      // 20-Year
      if (shouldInclude20Year) {
        twentyYearAAAData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[20],
        });
      }
      // 30-Year
      if (shouldInclude30Year) {
        thirtyYearAAAData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[30],
        });
      }
    });

    if (shouldInclude5Year) {
      datasets.push(fiveYearAAAData);
    }
    if (shouldInclude10Year) {
      datasets.push(tenYearAAAData);
    }
    if (shouldInclude20Year) {
      datasets.push(twentyYearAAAData);
    }
    if (shouldInclude30Year) {
      datasets.push(thirtyYearAAAData);
    }
  }

  // UST
  if (
    [
      DATASET_OPTIONS[1],
      DATASET_OPTIONS[4],
      DATASET_OPTIONS[7],
      DATASET_OPTIONS[10],
    ].some((option) => selectedDatasets.includes(option))
  ) {
    const shouldInclude5Year = selectedDatasets.includes(DATASET_OPTIONS[1]);
    const shouldInclude10Year = selectedDatasets.includes(DATASET_OPTIONS[4]);
    const shouldInclude20Year = selectedDatasets.includes(DATASET_OPTIONS[7]);
    const shouldInclude30Year = selectedDatasets.includes(DATASET_OPTIONS[10]);
    const fiveYearUSTData = {
      data: [],
      label: DATASET_OPTIONS[1],
      ...COLORS["UST (5Yr)"],
    };
    const tenYearUSTData = {
      data: [],
      label: DATASET_OPTIONS[4],
      ...COLORS["UST (10Yr)"],
    };
    const twentyYearUSTData = {
      data: [],
      label: DATASET_OPTIONS[7],
      ...COLORS["UST (20Yr)"],
    };
    const thirtyYearUSTData = {
      data: [],
      label: DATASET_OPTIONS[10],
      ...COLORS["UST (30Yr)"],
    };

    USTData.forEach((dataPoint) => {
      // 5-Year
      if (shouldInclude5Year) {
        fiveYearUSTData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[5],
        });
      }
      // 10-Year
      if (shouldInclude10Year) {
        tenYearUSTData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[10],
        });
      }
      // 20-Year
      if (shouldInclude20Year) {
        twentyYearUSTData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[20],
        });
      }
      // 30-Year
      if (shouldInclude30Year) {
        thirtyYearUSTData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[30],
        });
      }
    });

    if (shouldInclude5Year) {
      datasets.push(fiveYearUSTData);
    }
    if (shouldInclude10Year) {
      datasets.push(tenYearUSTData);
    }
    if (shouldInclude20Year) {
      datasets.push(twentyYearUSTData);
    }
    if (shouldInclude30Year) {
      datasets.push(thirtyYearUSTData);
    }
  }
  // FHLB
  if (
    [
      DATASET_OPTIONS[2],
      DATASET_OPTIONS[5],
      DATASET_OPTIONS[8],
      DATASET_OPTIONS[11],
    ].some((option) => selectedDatasets.includes(option))
  ) {
    const shouldInclude5Year = selectedDatasets.includes(DATASET_OPTIONS[2]);
    const shouldInclude10Year = selectedDatasets.includes(DATASET_OPTIONS[5]);
    const shouldInclude20Year = selectedDatasets.includes(DATASET_OPTIONS[8]);
    const shouldInclude30Year = selectedDatasets.includes(DATASET_OPTIONS[11]);
    const fiveYearFHLBData = {
      data: [],
      label: DATASET_OPTIONS[2],
      ...COLORS["FHLB (5Yr)"],
    };
    const tenYearFHLBData = {
      data: [],
      label: DATASET_OPTIONS[5],
      ...COLORS["FHLB (10Yr)"],
    };
    const twentyYearFHLBData = {
      data: [],
      label: DATASET_OPTIONS[8],
      ...COLORS["FHLB (20Yr)"],
    };
    const thirtyYearFHLBData = {
      data: [],
      label: DATASET_OPTIONS[11],
      ...COLORS["FHLB (30Yr)"],
    };

    FHLBData.forEach((dataPoint) => {
      // 5-Year
      if (shouldInclude5Year) {
        fiveYearFHLBData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[5],
        });
      }
      // 10-Year
      if (shouldInclude10Year) {
        tenYearFHLBData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[10],
        });
      }
      // 20-Year
      if (shouldInclude20Year) {
        twentyYearFHLBData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[20],
        });
      }
      // 30-Year
      if (shouldInclude30Year) {
        thirtyYearFHLBData.data.push({
          x: dataPoint.date.format("M/D/YYYY"),
          y: dataPoint[30],
        });
      }
    });

    if (shouldInclude5Year) {
      datasets.push(fiveYearFHLBData);
    }
    if (shouldInclude10Year) {
      datasets.push(tenYearFHLBData);
    }
    if (shouldInclude20Year) {
      datasets.push(twentyYearFHLBData);
    }
    if (shouldInclude30Year) {
      datasets.push(thirtyYearFHLBData);
    }
  }

  return {
    datasets,
    labels: AAAData.map(({ date }) => date.format("M/D/YYYY")),
  };
};

const getHistoricalRatiosData = ({ AAAData, USTData }) => {
  const fiveYearData = {
    data: [],
    label: "AAA/UST (5Yr)",
    ...COLORS["UST (5Yr)"],
  };
  const tenYearData = {
    data: [],
    label: "AAA/UST (10Yr)",
    ...COLORS["UST (10Yr)"],
  };
  const twentyYearData = {
    data: [],
    label: "AAA/UST (20Yr)",
    ...COLORS["UST (20Yr)"],
  };
  const thirtyYearData = {
    data: [],
    label: "AAA/UST (30Yr)",
    ...COLORS["UST (30Yr)"],
  };
  AAAData.forEach((dataPoint) => {
    if (!dataPoint.date) return;
    const matchingUSTDataPoint = USTData.find((point) =>
      point.date?.isSame(dataPoint.date, "day")
    );

    if (matchingUSTDataPoint) {
      // 5-Year
      fiveYearData.data.push({
        x: dataPoint.date.format("M/D/YYYY"),
        y: dataPoint[5] / matchingUSTDataPoint[5],
      });
      // 10-Year
      tenYearData.data.push({
        x: dataPoint.date.format("M/D/YYYY"),
        y: dataPoint[10] / matchingUSTDataPoint[10],
      });
      // 20-Year
      twentyYearData.data.push({
        x: dataPoint.date.format("M/D/YYYY"),
        y: dataPoint[20] / matchingUSTDataPoint[20],
      });
      // 30-Year
      thirtyYearData.data.push({
        x: dataPoint.date.format("M/D/YYYY"),
        y: dataPoint[30] / matchingUSTDataPoint[30],
      });
    }
  });

  return {
    datasets: [fiveYearData, tenYearData, twentyYearData, thirtyYearData],
    labels: AAAData.map(({ date }) => date.format("M/D/YYYY")),
  };
};

const HistoricalGraphs = ({ handleInputUpdate, savedInputValues }) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState(
    savedInputValues?.selectedTimeframe ?? TIME_SELECT_OPTIONS[3]
  );
  const [selectedDatasets, setSelectedDatasets] = useState(
    savedInputValues?.selectedDatasets ?? [
      DATASET_OPTIONS[3],
      DATASET_OPTIONS[4],
    ]
  );
  const { rawData: AAAData, loading: AAALoading } = useAAABenchmarkRawData({
    header: true,
  });
  const { rawData: USTData, loading: USTLoading } = useUSTreasuryRawData({
    header: true,
  });
  const { rawData: FHLBData, loading: FHLBLoading } = useFHLBRawData({
    header: true,
  });
  const formattedAAAData = useMemo(
    () => getFormattedAAAData(AAAData),
    [AAAData]
  );
  const formattedUSTData = useMemo(
    () => getFormattedUSTData(USTData),
    [USTData]
  );
  const formattedFHLBData = useMemo(
    () => getFormattedFHLBData(FHLBData),
    [FHLBData]
  );
  const filteredAAAData = useMemo(
    () => getFilteredData({ dataSet: formattedAAAData, selectedTimeframe }),
    [formattedAAAData, selectedTimeframe]
  );
  const filteredUSTData = useMemo(
    () => getFilteredData({ dataSet: formattedUSTData, selectedTimeframe }),
    [formattedUSTData, selectedTimeframe]
  );
  const filteredFHLBData = useMemo(
    () => getFilteredData({ dataSet: formattedFHLBData, selectedTimeframe }),
    [formattedFHLBData, selectedTimeframe]
  );

  const chartData = useMemo(() => {
    if (AAALoading || USTLoading || FHLBLoading) {
      return {
        datasets: [],
        labels: [],
      };
    }
    return getHistoricalBenchmarkData({
      selectedDatasets,
      AAAData: filteredAAAData,
      USTData: filteredUSTData,
      FHLBData: filteredFHLBData,
    });
  }, [
    selectedDatasets,
    filteredAAAData,
    filteredUSTData,
    filteredFHLBData,
    AAALoading,
    USTLoading,
    FHLBLoading,
  ]);

  const ratioChartData = useMemo(() => {
    if (AAALoading || USTLoading || FHLBLoading) {
      return {
        datasets: [],
        labels: [],
      };
    }
    return getHistoricalRatiosData({
      AAAData: filteredAAAData,
      USTData: filteredUSTData,
    });
  }, [filteredAAAData, filteredUSTData, AAALoading, USTLoading, FHLBLoading]);

  const handleToggleDataset = (dataset) => {
    let newSelectedDatasets;
    if (selectedDatasets.includes(dataset)) {
      newSelectedDatasets = [...selectedDatasets.filter((v) => v !== dataset)];
      setSelectedDatasets(newSelectedDatasets);
    } else {
      newSelectedDatasets = [...selectedDatasets, dataset];
      setSelectedDatasets(newSelectedDatasets);
    }
    handleInputUpdate({ key: "selectedDatasets", value: newSelectedDatasets });
  };

  const handleSelectedTimeframeUpdate = (value) => {
    setSelectedTimeframe(value);
    handleInputUpdate({ key: "selectedTimeframe", value });
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {USTLoading || AAALoading || FHLBLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box>
            <Typography textAlign="center" fontWeight="bold" mb={1}>
              Historical Graphs
            </Typography>
            <Divider />
          </Box>
          <Box>
            <Typography textAlign="center" mb={1}>
              Historical Benchmark Rates
            </Typography>
            <Divider sx={{ borderColor: "black" }} />
          </Box>
          <FormControl
            sx={{ width: "150px", alignSelf: "flex-end" }}
            size="small"
          >
            <Select
              id="timeframe-select"
              value={selectedTimeframe}
              onChange={({ target }) =>
                handleSelectedTimeframeUpdate(target.value)
              }
            >
              {TIME_SELECT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 250 }}>
            <Line
              data={chartData}
              options={{
                maintainAspectRatio: false,
                spanGaps: true,
                parsing: false,
                normalized: true,
                elements:
                  selectedTimeframe === TIME_SELECT_OPTIONS[0]
                    ? {}
                    : {
                        point: {
                          radius: 0, // default to disabled in all datasets
                        },
                        line: {
                          borderWidth: 1, // thinner line for visibility
                        },
                      },
                scales: {
                  y: {
                    title: { text: "Yield", display: true },
                    ticks: {
                      callback: (v) => `${v.toFixed(2)}%`,
                    },
                  },
                },
                plugins: {
                  colors: {
                    enabled: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) =>
                        `${context.dataset.label}: ${context.raw.y.toFixed(
                          2
                        )}%`,
                    },
                  },
                },
              }}
            />
          </div>
          <Grid container>
            {DATASET_OPTIONS.map((option) => (
              <Grid item xs={4} key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 14,
                          color: COLORS[option].borderColor,
                        },
                        padding: 0,
                      }}
                    />
                  }
                  sx={{ "& .MuiFormControlLabel-label": { fontSize: 12 } }}
                  label={option}
                  checked={selectedDatasets.includes(option)}
                  onChange={() => handleToggleDataset(option)}
                />
              </Grid>
            ))}
          </Grid>

          <Box>
            <Typography textAlign="center" mb={1}>
              Historical Ratios
            </Typography>
            <Divider sx={{ borderColor: "black" }} />
          </Box>
          <div style={{ height: 250 }}>
            <Line
              data={ratioChartData}
              options={{
                maintainAspectRatio: false,
                spanGaps: true,
                parsing: false,
                normalized: true,
                elements:
                  selectedTimeframe === TIME_SELECT_OPTIONS[0]
                    ? {}
                    : {
                        point: {
                          radius: 0, // default to disabled in all datasets
                        },
                        line: {
                          borderWidth: 1, // thinner line for visibility
                        },
                      },
                scales: {
                  y: {
                    title: { text: "Ratios", display: true },
                    ticks: {
                      callback: (v) => `${v.toFixed(2)}%`,
                    },
                  },
                },
                plugins: {
                  colors: {
                    enabled: true,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) =>
                        `${context.dataset.label}: ${context.raw.y.toFixed(
                          2
                        )}%`,
                    },
                  },
                },
              }}
            />
          </div>
        </>
      )}
    </Stack>
  );
};

export default HistoricalGraphs;
