import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  Grid,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import { FiCurveInput, FiCurveInputSelect } from "../../inputs/inputs";
import { useState } from "react";
import { TaxDesignation } from "../../constants";
import useMunicipalMarketData from "./useMunicipalMarketData";
import formatter from "../../tools/formatter";
import moment from "moment";

export default function MunicipalMarketTable({
  handleInputUpdate,
  savedInputValues,
}) {
  const taxDesignation = new FiCurveInput(
    "",
    useState(savedInputValues?.taxDesignation ?? "Non-Bank Qualified"),
    {
      setValueCallback: (value) =>
        handleInputUpdate({ key: "taxDesignation", value }),
    }
  );
  const { data, referenceDate, loading } = useMunicipalMarketData(
    taxDesignation.getValue()
  );

  return (
    <Box>
      <Typography textAlign="center">
        Unlimited Tax General Obligation - New York
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        borderBottom="solid 1px #000"
        pb={1}
        mb={1}
      >
        {loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <Typography textAlign="center" variant="subtitle2">
            ({moment(referenceDate).format("MM/DD/YYYY")})
          </Typography>
        )}
      </Box>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Box} marginBottom={2}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Maturity</TableCell>
                  <TableCell align="center">AAA</TableCell>
                  <TableCell align="center">AA</TableCell>
                  <TableCell align="center">A</TableCell>
                  <TableCell align="center">BBB</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data || {})?.map((maturity) => (
                  <TableRow key={maturity}>
                    <TableCell align="center">{maturity}</TableCell>
                    <TableCell align="center">
                      {formatter.percent(data[maturity].TIC.AAA)}
                    </TableCell>
                    <TableCell align="center">
                      {formatter.percent(data[maturity].TIC.AA)}
                    </TableCell>
                    <TableCell align="center">
                      {formatter.percent(data[maturity].TIC.A)}
                    </TableCell>
                    <TableCell align="center">
                      {formatter.percent(data[maturity].TIC.BBB)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography>Tax Designation:</Typography>
            </Grid>
            <Grid item>
              <FiCurveInputSelect
                input={taxDesignation}
                options={Object.values(TaxDesignation)}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
