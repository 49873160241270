import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {createTheme, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Auth0Provider} from "@auth0/auth0-react";
import App from "./app";

function Main() {
    const darkTheme = createTheme({
        palette: {
            navy: {
                main: "#004467",
                contrastText: "#FFFFFF"
            },
            mode: "light",
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "0px 16px",
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                    authorizationParams={{
                        redirect_uri: window.location.origin,
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE
                    }}
                >
                    <App />
                </Auth0Provider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
