import moment from "moment";
import { lerp } from "./solvers";
import { CallPriceDefault } from "../constants";
import days360, { US_NASD } from "days360";
import { DealBuilderService } from "../deal-builder/deal-builder-service";

export function convertPercentageStringToNumber(s) {
  s.replace(/\d+% ?/g, "");
  return Number(s) / 100;
}

export function calcYTM(
  maturityDate,
  deliveryDate,
  rate: Number,
  pr: Number,
  ytc: Number
) {
  const settlement = deliveryDate;
  const redemption = CallPriceDefault;
  const freq = 2;

  let periodStart = getPeriodStart(settlement, maturityDate);
  let periodEnd = moment(periodStart).add(6, "month");

  const N = customDays360(periodEnd, maturityDate) / 180 + 1;
  let yld: Number;
  if (N === 1) {
    const rateOverFreq = rate / freq;
    const E = 360 / freq;
    const A = customDays360(periodStart, settlement);
    const DSR = E - A;
    const mod = pr / 100 + (A / E) * rateOverFreq;

    yld = redemption / 100 + rateOverFreq - mod;
    yld *= (E * freq) / (DSR * mod);
  } else {
    yld = lerp(
      (x) => {
        return calcPrice(maturityDate, deliveryDate, rate, x);
      },
      pr,
      ytc,
      ytc * 1.1,
      0.0001
    );
  }
  return round(yld, 4);
}

export function calcPrice(
  maturityDate,
  deliveryDate,
  rate: Number,
  yld: Number
): Number {
  if (round(rate, 4) === round(yld, 4)) return 100.0;

  const settlement = deliveryDate;
  const redemption = CallPriceDefault;
  const freq = 2;

  let periodStart = getPeriodStart(settlement, maturityDate);
  let periodEnd = moment(periodStart).add(6, "month");

  const N = customDays360(periodEnd, maturityDate) / 180 + 1;
  const E = 360 / freq;
  const A = customDays360(periodStart, settlement);
  const yldOverFreq = yld / freq;
  const rateOverFreq = rate / freq;

  let price = 0.0;
  if (N > 1) {
    const DSC = customDays360(settlement, periodEnd);
    const expMod = DSC / E;
    price += redemption / Math.pow(yldOverFreq + 1, N - 1 + expMod);
    for (let k = 1; N >= k; k++) {
      price += (rateOverFreq * 100) / Math.pow(yldOverFreq + 1, expMod + k - 1);
    }
    price -= (rateOverFreq * A * 100) / E;
  } else {
    const DSR = E - A;
    const T1 = rateOverFreq * 100 + redemption;
    const T2 = (yldOverFreq * DSR) / E + 1;
    const T3 = (rateOverFreq * A * 100) / E;
    price = T1 / T2 - T3;
  }

  return round(price, 3, Math.floor);
}

export function customDays360(start, end, basis = US_NASD): Number {
  return days360(moment(start).toDate(), moment(end).toDate(), basis);
}

export function getPeriodStart(settlement, maturity) {
  let periodStart = moment(maturity);
  periodStart.year(moment(settlement).year());
  while (periodStart.isAfter(settlement)) {
    periodStart.subtract(6, "month");
  }
  return periodStart;
}

export function round(num, dec, roundingFunction = null) {
  const places = Math.pow(10, dec);
  const rFunc = roundingFunction ?? Math.round;
  return rFunc((num + Number.EPSILON) * places) / places;
}

export function deepCopy(val) {
  return JSON.parse(JSON.stringify(val));
}

// Debug purposes only
export function logDealBuilder(dealBuilder) {
  const returnObject = {};
  for (const [key, value] of Object.entries(dealBuilder)) {
    if (moment.isMoment(value)) {
      returnObject[key] = value.toString();
    } else if (key === "tableRows") {
      const newTableRows = [];
      for (const tableRowIndex in value) {
        newTableRows[tableRowIndex] = {};
        for (const [tableRowKey, tableRowValue] of Object.entries(
          value[tableRowIndex]
        )) {
          if (moment.isMoment(tableRowValue)) {
            newTableRows[tableRowIndex][tableRowKey] = tableRowValue.toString();
          } else {
            newTableRows[tableRowIndex][tableRowKey] = tableRowValue;
          }
        }
      }
      returnObject[key] = newTableRows;
    } else {
      returnObject[key] = value;
    }
  }
  return returnObject;
}

export function formatDeals(deals) {
  const newDeals = [];
  deals.forEach((deal) => {
    const newDeal = {};
    for (const [key, value] of Object.entries(deal)) {
      let inputValue = value;
      if (
        [
          "bidDate",
          "callDate",
          "deliveryDate",
          "finalPrincipal",
          "firstCoupon",
          "firstPrincipal",
          "marketConditionsDate",
          "dateSaved",
        ].includes(key)
      ) {
        inputValue = moment.utc(inputValue).local();
      }
      newDeal[key] = inputValue;
    }
    newDeals.push(newDeal);
  });
  return newDeals;
}

export function getPricingManagementFields(deal) {
  const parsedPricing = JSON.parse(deal.pricing);
  if (!deal.useCustomCouponYield && !parsedPricing?.length) {
    return { tic: 0, variance: 0, savedDealBuilder: { averageLife: 0 } };
  }
  const savedDealBuilder = new DealBuilderService(
    undefined,
    deal,
    parsedPricing
  );
  const tic = round(savedDealBuilder.TIC * 100, 2).toString();
  const dealBuilderWithNewBidRate = new DealBuilderService(
    undefined,
    {
      ...deal,
      useCustomCouponYield: true,
      customCouponYield: round((deal.bidRate || 400) / 100, 2),
    },
    []
  );
  const dealBuilderWithTicRate = new DealBuilderService(
    undefined,
    {
      ...deal,
      useCustomCouponYield: true,
      customCouponYield: tic,
    },
    []
  );
  return {
    tic,
    variance:
      round(dealBuilderWithNewBidRate.totalInterest, 0) -
      round(dealBuilderWithTicRate.totalInterest, 0),
    savedDealBuilder,
  };
}

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const percentFormatter = (rate) => `${Number(rate).toFixed(2)}%`;

export const combineSourcesAndUses = (
  sourcesAndUses,
  ticAdjustingExpenses,
  capitalizedInterestDate
) => {
  const uses = deepCopy(sourcesAndUses.uses);

  // TIC Adjusting Expenses
  if (ticAdjustingExpenses) {
    uses.push({
      name: "TIC Adjusting Expenses",
      is_tic_category: true,
      items: [
        {
          name: "Underwriter's Discount",
          value: ticAdjustingExpenses["Underwriter's Discount"] * 1000,
        },
        {
          name: "Bond Insurance",
          value: ticAdjustingExpenses["Bond Insurance"] * 1000,
        },
        {
          name: "Cost of Issuance",
          value: ticAdjustingExpenses["Cost of Issuance"],
        },
      ],
    });
  }

  // Capitalized Interest
  if (capitalizedInterestDate) {
    uses.push({
      name: "Capitalized Interest",
      is_capitalized_interest: true,
      items: [{ name: "End Date", date_value: capitalizedInterestDate }],
    });
  }

  return {
    sources: sourcesAndUses.sources,
    uses,
  };
};

export const separateSourcesAndUses = (sourcesAndUses) => {
  // Sources & Uses
  const newSourcesAndUses = {
    sources: sourcesAndUses.sources,
    uses: sourcesAndUses.uses.filter(
      (category) =>
        !category.is_tic_category && !category.is_capitalized_interest
    ),
  };

  // TIC Adjusting Expenses
  const ticAdjustingCategory = sourcesAndUses.uses.find(
    (category) => category.is_tic_category
  );
  const newTicAdjustingExpenses = ticAdjustingCategory
    ? createTicAdjustingExpensesFromCategory(ticAdjustingCategory)
    : undefined;

  // Capitalized Interest Date
  const capitalizedInterestCategory = sourcesAndUses.uses.find(
    (category) => category.is_capitalized_interest
  );
  const newCapitalizedInterestDate = capitalizedInterestCategory
    ? getCapitalizedInterestDateFromCategory(capitalizedInterestCategory)
    : undefined;
  return [
    newSourcesAndUses,
    newTicAdjustingExpenses,
    newCapitalizedInterestDate,
  ];
};

export const createTicAdjustingExpensesFromCategory = (category) => {
  return category.items.reduce((accumulator, item) => {
    if (["Underwriter's Discount", "Bond Insurance"].includes(item.name)) {
      accumulator[item.name] = item.value / 1000;
    } else {
      accumulator[item.name] = item.value;
    }
    return accumulator;
  }, {});
};

export const getCapitalizedInterestDateFromCategory = (category) => {
  const item = category.items[0];
  if (item.date_value) return moment(item.date_value);
  if (item.dateValue) return moment(item.dateValue);
  return moment(item.value);
};

export const dateValidation = (val) => {
  if (moment(val).isValid()) return null;

  return val
    ? `${val._i} is not a valid date; use format MM/DD/YYYY`
    : "Invalid date; use format MM/DD/YYYY";
};
