import React, { useContext, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Divider, Stack } from "@mui/material";
import formatter from "../../tools/formatter";
import ReportPageHeader from "./report-page-header";
import {
  FiCurveInput,
  FiCurveInputCurrency,
  FiCurveInputDate,
  FiCurveInputPercent,
  FiCurveInputText,
} from "../../inputs/inputs";
import { AddCircle, Delete, Edit, Save, Undo } from "@mui/icons-material";
import { DealBuilderContext } from "../../contexts/DealBuilderContext";
import {
  combineSourcesAndUses,
  dateValidation,
  deepCopy,
} from "../../tools/utils";
import moment from "moment";

function SourcesAndUses() {
  const {
    dealBuilder: db,
    sourcesAndUses: originalSourcesAndUses,
    ticAdjustingExpenses: originalTicAdjustingExpenses,
    capitalizedInterestDate: originalCapitalizedInterestDate,
    dealBuilderInputs,
    handleSourceAndUses,
  } = useContext(DealBuilderContext);

  const [sourcesAndUses, setSourcesAndUses] = useState(
    deepCopy(originalSourcesAndUses)
  );
  const [ticAdjustingExpenses, setTicAdjustingExpenses] = useState(
    originalTicAdjustingExpenses && deepCopy(originalTicAdjustingExpenses)
  );
  const [capitalizedInterestDate, setCapitalizedInterestDate] = useState(
    originalCapitalizedInterestDate
  );

  useEffect(() => {
    setSourcesAndUses(deepCopy(originalSourcesAndUses));
  }, [originalSourcesAndUses]);

  useEffect(() => {
    if (originalTicAdjustingExpenses) {
      setTicAdjustingExpenses(deepCopy(originalTicAdjustingExpenses));
    } else {
      setTicAdjustingExpenses();
    }
  }, [originalTicAdjustingExpenses]);

  const [editMode, setEditMode] = useState(false);

  const moneyEntry = (
    name,
    value,
    inEditMode = false,
    SoU = null,
    categoryIndex = null,
    itemIndex = null
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: "30px" }}
        key={itemIndex}
      >
        {!inEditMode && (
          <>
            <Box>{name}</Box>
            <Box>{formatter.money(value, true, true)}</Box>
          </>
        )}
        {inEditMode && (
          <>
            <FiCurveInputText
              input={
                new FiCurveInput(
                  `${sourcesAndUses[SoU][categoryIndex].name} item name ${
                    itemIndex + 1
                  }`,
                  [
                    name,
                    (newVal) => {
                      const newSoU = deepCopy(sourcesAndUses);
                      newSoU[SoU][categoryIndex].items[itemIndex].name = newVal;
                      setSourcesAndUses(newSoU);
                    },
                  ]
                )
              }
            />
            <FiCurveInputCurrency
              input={
                new FiCurveInput(
                  `${sourcesAndUses[SoU][categoryIndex].items[itemIndex].name} value`,
                  [
                    value,
                    (newVal) => {
                      const newSoU = deepCopy(sourcesAndUses);
                      newSoU[SoU][categoryIndex].items[itemIndex].value =
                        newVal;
                      setSourcesAndUses(newSoU);
                    },
                  ],
                  { disableValidations: true }
                )
              }
            />
            <Button
              variant="outlined"
              color="error"
              onClick={() => deleteItem(SoU, categoryIndex, itemIndex)}
            >
              <Delete color="error" />
            </Button>
          </>
        )}
      </Stack>
    );
  };

  const ticAdjustingExpenseEntry = (
    name,
    value,
    inEditMode,
    isPercent = false,
    dollarValue = null
  ) => {
    const Input = isPercent ? FiCurveInputPercent : FiCurveInputCurrency;
    const formattedValue = isPercent
      ? formatter.money(dollarValue, true, true)
      : formatter.money(value, true, true);
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: "30px" }}
      >
        <Box width="100%">{name}</Box>
        {inEditMode ? (
          <Input
            input={
              new FiCurveInput(
                name,
                [
                  value,
                  (newValue) => {
                    const newTicAdjustingExpenses =
                      deepCopy(ticAdjustingExpenses);
                    newTicAdjustingExpenses[name] = newValue || 0;
                    setTicAdjustingExpenses(newTicAdjustingExpenses);
                  },
                ],
                { isRequired: false }
              )
            }
            inputProps={isPercent ? { decimalScale: 3 } : {}}
          />
        ) : (
          <Box>{formattedValue}</Box>
        )}
      </Stack>
    );
  };

  const capitalizedInterestEntry = (inEditMode) => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: "30px" }}
    >
      <Box width="100%">Capitalized Interest</Box>
      {inEditMode ? (
        <FiCurveInputDate
          input={
            new FiCurveInput(
              "End Date",
              [capitalizedInterestDate, setCapitalizedInterestDate],
              {
                isRequired: false,
                validations: [
                  dateValidation,
                  (val) =>
                    moment(val).isSameOrBefore(
                      dealBuilderInputs.deliveryDate.getValue()
                    )
                      ? "Must be after Delivery Date"
                      : null,
                  (val) => {
                    const maxDate = dealBuilderInputs.deliveryDate
                      .getValue()
                      .clone();
                    maxDate.add(5, "y");
                    return moment(val).isAfter(maxDate)
                      ? "Must be within 5 years of Delivery Date"
                      : null;
                  },
                ],
              }
            )
          }
        />
      ) : (
        <Box>{formatter.money(db.totalCapitalizedInterest, true, true)}</Box>
      )}
    </Stack>
  );

  const renderCategory = (
    category,
    inEditMode = false,
    SoU = null,
    categoryIndex = null
  ) => {
    return (
      <>
        <Stack direction="column" spacing={inEditMode ? 0.5 : 0}>
          {!inEditMode && <Box>{category.name}</Box>}
          {inEditMode && (
            <Stack direction="row" alignItems="center">
              <FiCurveInputText
                input={
                  new FiCurveInput(
                    `${SoU} category name ${categoryIndex + 1}`,
                    [
                      category.name,
                      (newName) => {
                        const newSoU = deepCopy(sourcesAndUses);
                        newSoU[SoU][categoryIndex].name = newName;
                        setSourcesAndUses(newSoU);
                      },
                    ]
                  )
                }
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteCategory(SoU, categoryIndex)}
              >
                <Delete color="error" />
              </Button>
            </Stack>
          )}
          {category.items.map((item, itemIndex) =>
            moneyEntry(
              item.name,
              item.value,
              inEditMode,
              SoU,
              categoryIndex,
              itemIndex
            )
          )}
          {inEditMode && (
            <Button onClick={() => addItem(SoU, categoryIndex)}>
              <AddCircle /> Add Item
            </Button>
          )}
        </Stack>
        <Divider />
      </>
    );
  };

  const renderTicAdjustingExpenses = (inEditMode = false) => (
    <>
      <Stack direction="column" spacing={inEditMode ? 0.5 : 0}>
        <Box>TIC Adjusting Expenses</Box>
        {ticAdjustingExpenseEntry(
          "Underwriter's Discount",
          ticAdjustingExpenses["Underwriter's Discount"],
          inEditMode,
          true,
          db.parAmount * (ticAdjustingExpenses["Underwriter's Discount"] / 100)
        )}
        {ticAdjustingExpenseEntry(
          "Bond Insurance",
          ticAdjustingExpenses["Bond Insurance"],
          inEditMode,
          true,
          db.totalTotalDebtService *
            (ticAdjustingExpenses["Bond Insurance"] / 100)
        )}
        {ticAdjustingExpenseEntry(
          "Cost of Issuance",
          ticAdjustingExpenses["Cost of Issuance"],
          inEditMode
        )}
      </Stack>
      <Divider />
    </>
  );

  const renderCapitalizedInterest = (inEditMode = false) => (
    <>
      <Stack direction="column" spacing={inEditMode ? 0.5 : 0}>
        <Box>Capitalized Interest</Box>
        {capitalizedInterestEntry(inEditMode)}
      </Stack>
      <Divider />
    </>
  );

  const addItem = (SoU, categoryIndex) => {
    const newSaU = deepCopy(sourcesAndUses);
    newSaU[SoU][categoryIndex].items.push({ name: "", value: 0.0 });
    setSourcesAndUses(newSaU);
  };

  const deleteItem = (SoU, categoryIndex, itemIndex) => {
    const newSaU = deepCopy(sourcesAndUses);
    newSaU[SoU][categoryIndex].items.splice(itemIndex, 1);
    setSourcesAndUses(newSaU);
  };

  const addCategory = (SoU) => {
    const newSaU = deepCopy(sourcesAndUses);
    newSaU[SoU].push({ name: "", items: [{ name: "", value: 0.0 }] });
    setSourcesAndUses(newSaU);
  };

  const addTicAdjustingExpenses = () => {
    setTicAdjustingExpenses({
      "Underwriter's Discount": 0,
      "Bond Insurance": 0,
      "Cost of Issuance": 0,
    });
  };

  const removeTicAdjustingExpenses = () => {
    setTicAdjustingExpenses();
  };

  const addCapitalizedInterest = () => {
    const defaultDate = dealBuilderInputs.deliveryDate.getValue().clone();
    defaultDate.add(1, "y");
    setCapitalizedInterestDate(defaultDate);
  };

  const removeCapitalizedInterest = () => {
    setCapitalizedInterestDate();
  };

  const deleteCategory = (SoU, categoryIndex) => {
    const newSaU = deepCopy(sourcesAndUses);
    newSaU[SoU].splice(categoryIndex, 1);
    setSourcesAndUses(newSaU);
  };

  const renderList = (SoU) => {
    const dataSource = editMode ? sourcesAndUses : originalSourcesAndUses;
    const out = dataSource[SoU].map((cat, i) =>
      renderCategory(cat, editMode, SoU, i)
    );
    if (SoU === "uses" && ticAdjustingExpenses) {
      out.push(renderTicAdjustingExpenses(editMode));
    }
    if (SoU === "uses" && capitalizedInterestDate) {
      out.push(renderCapitalizedInterest(editMode));
    }
    if (editMode) {
      if (SoU === "uses") {
        out.push(
          <Stack direction="row" justifyContent="center">
            <Button onClick={() => addCategory(SoU)}>
              <AddCircle /> Add Category
            </Button>
            {!ticAdjustingExpenses ? (
              <Button onClick={() => addTicAdjustingExpenses()}>
                <AddCircle /> Add TIC Adjusting Expenses
              </Button>
            ) : (
              <Button
                color="error"
                onClick={() => removeTicAdjustingExpenses()}
              >
                <Delete /> Remove TIC Adjusting Expenses
              </Button>
            )}
            {!capitalizedInterestDate ? (
              <Button onClick={() => addCapitalizedInterest()}>
                <AddCircle /> Add Capitalized Interest
              </Button>
            ) : (
              <Button color="error" onClick={() => removeCapitalizedInterest()}>
                <Delete /> Remove Capitalized Interest
              </Button>
            )}
          </Stack>
        );
      } else {
        out.push(
          <Button onClick={() => addCategory(SoU)}>
            <AddCircle /> Add Category
          </Button>
        );
      }
    }
    return out;
  };

  const saveChanges = async () => {
    const sourcesAndUsesWithTicAdjustingExpenses = combineSourcesAndUses(
      sourcesAndUses,
      ticAdjustingExpenses,
      capitalizedInterestDate
    );
    const [
      newSourcesAndUses,
      newTicAdjustingExpenses,
      newCapitalizedInterestDate,
    ] = await handleSourceAndUses({
      data: sourcesAndUsesWithTicAdjustingExpenses,
    });
    setSourcesAndUses(newSourcesAndUses);
    setTicAdjustingExpenses(newTicAdjustingExpenses);
    setCapitalizedInterestDate(newCapitalizedInterestDate);
    setEditMode(false);
  };

  const discardChanges = () => {
    setSourcesAndUses(deepCopy(originalSourcesAndUses));
    setTicAdjustingExpenses(
      originalTicAdjustingExpenses && deepCopy(originalTicAdjustingExpenses)
    );
    setCapitalizedInterestDate(originalCapitalizedInterestDate);
    setEditMode(false);
  };

  const bondProceeds = {
    name: "Bond Proceeds",
    items: [{ name: "Par Amount", value: db.parAmount }],
  };
  if (!db.useFiCurveParPricing && !db.useCustomCouponYield) {
    bondProceeds.items.push({
      name: "Premium",
      value: db.totalProduction - db.parAmount,
    });
  }

  const ticAdjustingExpensesTotal = ticAdjustingExpenses
    ? db.parAmount * (ticAdjustingExpenses["Underwriter's Discount"] / 100) +
      db.totalTotalDebtService *
        (ticAdjustingExpenses["Bond Insurance"] / 100) +
      ticAdjustingExpenses["Cost of Issuance"]
    : 0;

  const total =
    db.totalProduction +
    originalSourcesAndUses.sources.reduce(
      (acc, curr) =>
        acc + curr.items.reduce((acc2, currItem) => acc2 + currItem.value, 0.0),
      0.0
    );

  const fundTotal =
    total -
    originalSourcesAndUses.uses.reduce(
      (acc, curr) =>
        acc + curr.items.reduce((acc2, currItem) => acc2 + currItem.value, 0.0),
      0.0
    ) -
    ticAdjustingExpensesTotal -
    db.totalCapitalizedInterest;

  const projectFundTotal = {
    name: "Project Fund Deposits",
    items: [{ name: "Project Fund", value: fundTotal }],
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ReportPageHeader dealBuilder={db} title="SOURCES AND USES OF FUNDS" />
      <Stack className="printableSaU" direction="column" spacing={2}>
        <Box className="noPrint">
          {!editMode && (
            <Button variant="outlined" onClick={() => setEditMode(true)}>
              <Edit /> Edit
            </Button>
          )}
          {editMode && (
            <ButtonGroup variant="outlined">
              <Button onClick={saveChanges}>
                <Save /> Save
              </Button>
              <Button onClick={discardChanges}>
                <Undo /> Discard Changes
              </Button>
            </ButtonGroup>
          )}
        </Box>
        <Box sx={{ fontWeight: "bold" }}>Sources:</Box>
        <Divider sx={{ borderBottomWidth: "5px" }} />
        {renderCategory(bondProceeds)}
        {renderList("sources")}
        <Box sx={{ textAlign: "right", fontWeight: "bold", px: "30px" }}>
          {formatter.money(total, true, true)}
        </Box>
        <Divider sx={{ borderBottomWidth: "5px" }} />

        <Box sx={{ pt: "20px", fontWeight: "bold" }}>Uses:</Box>
        <Divider />
        {renderCategory(projectFundTotal)}
        {renderList("uses")}
        <Box sx={{ textAlign: "right", fontWeight: "bold", px: "30px" }}>
          {formatter.money(total, true, true)}
        </Box>
        <Divider sx={{ borderBottomWidth: "5px" }} />
      </Stack>
    </Box>
  );
}

export default SourcesAndUses;
