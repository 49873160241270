import React from "react";
import { Box, Button, LinearProgress, Stack } from "@mui/material";
import FiCurveLogo from "./tools/ficurve-logo";
import { useAuth0 } from "@auth0/auth0-react";

function Login({ isLoading }) {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={5}
        sx={{ width: "400px" }}
      >
        <FiCurveLogo isLogin sx={{ width: "90%" }} />
        {isLoading ? (
          <LinearProgress color="navy" sx={{ width: "100%" }} />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="navy"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export default Login;
