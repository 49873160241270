import React, { useContext } from "react";
import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import FiCurveLogo from "./tools/ficurve-logo";
import { useAuth0 } from "@auth0/auth0-react";
import { DealBuilderContext } from "./contexts/DealBuilderContext";
import { MainTabContext, TAB_NAMES } from "./contexts/MainTabContext";
import { UserInfoContext } from "./contexts/UserInfoContext";

function Header({ ...props }) {
  const { logout } = useAuth0();
  const { activeTab, setActiveTab } = useContext(MainTabContext);
  const { isBuildingDeal } = useContext(DealBuilderContext);
  const { userInfo } = useContext(UserInfoContext);

  const stackProps = {
    ...props,
    direction: props.direction ?? "row",
    alignItems: props.alignItems ?? "stretch",
    justifyContent: props.justifyContent ?? "flex-start",
    spacing: props.spacing ?? 5,
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const onLogout = () => {
    localStorage.removeItem("acknowledge");
    localStorage.removeItem("nationalPrivatePlacementsFilters");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Stack {...stackProps}>
      <FiCurveLogo
        sx={{
          width: "350px",
          ml: 3,
          objectFit: "contain",
        }}
      />
      <Stack sx={{ flexGrow: 1 }} justifyContent="flex-end">
        <Tabs
          value={activeTab}
          onChange={(e, v) => {
            setActiveTab(v);
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="page tabs"
        >
          <Tab
            label="Market Monitor"
            value={TAB_NAMES.MARKET_MONITOR}
            {...a11yProps(0)}
          />
          {userInfo?.organization?.hasNationalPrivatePlacement && (
            <Tab
              label={
                <>
                  National Private
                  <br />
                  Placements
                </>
              }
              value={TAB_NAMES.NATIONAL_PRIVATE_PLACEMENTS}
              {...a11yProps(1)}
            />
          )}
          <Tab
            label="Deal Builder"
            {...a11yProps(2)}
            value={TAB_NAMES.DEAL_BUILDER}
          />
          <Tab
            label="Draft Transactions"
            value={TAB_NAMES.DRAFT_TRANSACTIONS}
            {...a11yProps(3)}
          />
          <Tab
            label="Pricing Management"
            value={TAB_NAMES.PRICING_MANAGEMENT}
            {...a11yProps(4)}
          />
        </Tabs>
      </Stack>
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
        {!!userInfo && (
          <Stack>
            <Typography>
              Welcome,{" "}
              <Typography sx={{ fontWeight: "bold", display: "inline" }}>
                {[userInfo.firstName, userInfo.lastName].join(" ")}
              </Typography>
            </Typography>
            <Typography>
              Organization:{" "}
              <Typography sx={{ fontWeight: "bold", display: "inline" }}>
                {userInfo.organization.name}
              </Typography>
            </Typography>
            {!!userInfo.team && (
              <Typography>
                Team:{" "}
                <Typography sx={{ fontWeight: "bold", display: "inline" }}>
                  {userInfo.isAdmin ? "Admin" : userInfo.team.name}
                </Typography>
              </Typography>
            )}
          </Stack>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={isBuildingDeal}
          onClick={onLogout}
        >
          Log out
        </Button>
      </Box>
    </Stack>
  );
}

export default Header;
