import React, { useContext, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { TabPanel } from "../tab-panel";
import BondDebtService from "./tabs/bond-debt-service";
import BondPricing from "./tabs/bond-pricing";
import SourcesAndUses from "./tabs/sources-and-uses";
import BondSummaryStatistics from "./tabs/bond-summary-statistics";
import { DealBuilderContext } from "../contexts/DealBuilderContext";

const TAB_NAMES = {
  SOURCES_AND_USES: "SourcesAndUses",
  DEBT_SERVICE: "DebtService",
  BOND_PRICING: "BondPricing",
  BOND_SUMMARY_STATISTICS: "BondSummaryStatistics",
};

function DealBuilderDisplay(props) {
  const { printContentRef } = props;

  const [activeDealBuilderTab, setActiveDealBuilderTab] = useState(
    TAB_NAMES.SOURCES_AND_USES
  );
  const { dealBuilder, isBuildingDeal, isLoadingDeal } =
    useContext(DealBuilderContext);

  const boxProps = {
    alignItems: "center",
    justifyContent: "flex-start",
    sx: { flexGrow: 1, px: "10px" },
  };

  if (!dealBuilder) {
    return isBuildingDeal || isLoadingDeal ? (
      <Stack {...boxProps}>
        <Box>
          <CircularProgress />
        </Box>
      </Stack>
    ) : null;
  }

  return (
    <>
      <Container fixed sx={{ fontWeight: "regular" }}>
        <Tabs
          value={activeDealBuilderTab}
          onChange={(e, v) => {
            setActiveDealBuilderTab(v);
          }}
        >
          <Tab
            label="Sources & Uses"
            id="deal-builder-tab-0"
            value={TAB_NAMES.SOURCES_AND_USES}
          />
          <Tab
            label="Debt Service"
            id="deal-builder-tab-1"
            value={TAB_NAMES.DEBT_SERVICE}
          />
          <Tab
            label="Pricing & Production"
            id="deal-builder-tab-2"
            value={TAB_NAMES.BOND_PRICING}
          />
          <Tab
            label="Bond Summary Statistics"
            id="deal-builder-tab-3"
            value={TAB_NAMES.BOND_SUMMARY_STATISTICS}
          />
        </Tabs>
        <Divider sx={{ "&&": { my: 2 } }} />
        <Box {...boxProps} pb={4}>
          <TabPanel
            activeTab={activeDealBuilderTab}
            name={TAB_NAMES.SOURCES_AND_USES}
          >
            <SourcesAndUses />
          </TabPanel>
          <TabPanel
            activeTab={activeDealBuilderTab}
            name={TAB_NAMES.DEBT_SERVICE}
          >
            <BondDebtService />
          </TabPanel>
          <TabPanel
            activeTab={activeDealBuilderTab}
            name={TAB_NAMES.BOND_PRICING}
          >
            <BondPricing />
          </TabPanel>
          <TabPanel
            activeTab={activeDealBuilderTab}
            name={TAB_NAMES.BOND_SUMMARY_STATISTICS}
          >
            <BondSummaryStatistics />
          </TabPanel>
        </Box>
      </Container>
      <Box className="onlyPrint" ref={printContentRef} {...boxProps}>
        <SourcesAndUses />
        <div className="pagebreak"></div>
        <BondDebtService />
        <div className="pagebreak"></div>
        <BondPricing />
        <div className="pagebreak"></div>
        <BondSummaryStatistics />
      </Box>
    </>
  );
}

export default DealBuilderDisplay;
