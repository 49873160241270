import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Divider, Typography } from "@mui/material";
import { TabPanel } from "./tab-panel";
import { DealBuilderMain } from "./deal-builder/deal-builder-main";
import Header from "./header";
import DealBuilderDraftTransactions from "./deal-builder/deal-builder-draft-transactions";
import PromptDialog from "./components/prompt-dialog";
import MarketMonitor from "./market-monitor";
import PricingManagement from "./pricing-management";
import NationalPrivatePlacements from "./national-private-placements";
import { ErrorContext } from "./contexts/ErrorContext";
import { MainTabContext, TAB_NAMES } from "./contexts/MainTabContext";

function MainWindow() {
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const { activeTab } = useContext(MainTabContext);
  const { activeError } = useContext(ErrorContext);

  useEffect(() => {
    const acknowledge = localStorage.getItem("acknowledge");
    if (acknowledge) {
      setOpenDisclaimer(false);
    } else {
      setOpenDisclaimer(true);
    }
  }, []);

  const boxSx = {
    bgcolor: "background.paper",
    color: "text.primary",
    overflow: "scroll",
    boxShadow: 1,
    fontWeight: "bold",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  };

  const handleOk = () => {
    localStorage.setItem("acknowledge", true);
    setOpenDisclaimer(false);
  };

  if (openDisclaimer) {
    return (
      <PromptDialog
        title="DISCLAIMER"
        open={openDisclaimer}
        maxWidth="lg"
        okBtnText="Acknowledge"
        onOk={handleOk}
      >
        <Typography paragraph>
          FICURVE IS SOLELY A MARKET DATA ANALYTICAL PRODUCT, IS PROVIDED FOR
          INFORMATION PURPOSES ONLY, AND SHOULD NOT BE CONSTRUED AS LEGAL, TAX,
          INVESTMENT OR FINANCIAL ADVICE. FICURVE IS AN INDEPENDENT TECHNOLOGY
          COMPANY AND IS NOT AFFILIATED WITH A BROKER-DEALER. FICURVE IS NOT
          REGISTERED OR AFFILIATED WITH THE SEC, FINRA, OR ANY OTHER REGULATORY
          BODY OR AGENCY. FICURVE USES DATA AND INFORMATION FROM VARIOUS THIRD
          PARTY MARKET DATA SOURCES AND ASSUMES NO RESPONSIBILITY OR LIABILITY
          FOR SUCH THIRD PARTY INFORMATION OR ANY ERRORS, INACCURACIES, OR
          OMISSIONS THEREIN. FICURVE PRODUCES MARKET ESTIMATES BASED ON SELECT
          HISTORICAL DATA ANALYSIS; HOWEVER, THERE IS NO ASSURANCE,
          REPRESENTATION, WARRANTY, OR GUARANTEE THAT FICURVE ESTIMATES MAY BE
          USEFUL OR RELIABLE FOR THEORETICAL, ACTUAL, OR FUTURE TRANSACTIONS
          (AND FICURVE DISCLAIMS ANY AND ALL SUCH REPRESENTATIONS AND
          WARRANTIES). MARKETS ARE DYNAMIC AND SUBJECT TO RAPID AND
          UNPREDICTABLE CHANGES. FICURVE MAKES NO REPRESENTATIONS OR WARRANTIES
          OF ANY KIND REGARDING ITS PRODUCT INCLUDING, BUT NOT LIMITED TO, THE
          DATA, INFORMATION, AND MARKET ESTIMATES OR THE ACCURACY OF SUCH DATA,
          INFORMATION, OR ESTIMATES. FICURVE DISCLAIMS ALL LIABILITY FOR ANY
          CLAIMS OR DAMAGES ARISING FROM ANY DECISIONS MADE BASED ON INFORMATION
          OR DATA MADE AVAILABLE BY FICURVE AND ALL USE OF SUCH INFORMATION AND
          DATA IS THE USER’S SOLE RESPONSIBILITY AND RISK. FICURVE DISCLAIMS ANY
          AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS,
          IMPLIED OR OTHERWISE. FICURVE FURTHER EXPRESSLY DISCLAIMS ANY AND ALL
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          SATISFACTION OF CUSTOMER REQUIREMENTS, ARISING OUT OF A COURSE OF
          PERFORMANCE, DEALING OR TRADE USAGE, OR OF FREEDOM FROM INTERRUPTION,
          VIRUS, MALWARE OR OTHER DISABLING ROUTINE. FICURVE FURTHER EXPRESSLY
          DISCLAIMS ANY AND ALL WARRANTIES OF TITLE, DATA, OR INFORMATION
          ACCURACY, AND MARKET ESTIMATE ACCURACY. FICURVE SHALL HAVE NO
          LIABILITY OR RESPONSIBILITY WHATSOEVER RELATING TO THE ACCURACY OF ITS
          PRODUCT INCLUDING, BUT NOT LIMITED TO, ACCURACY OR INACCURACY RELATING
          TO ANY DATA, INFORMATION, OR MARKET ESTIMATES PROVIDED THEREIN.
          FICURVE SHALL NOT BE LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL,
          PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR FOR ANY
          DAMAGES FROM LOSS OR INTERRUPTION OF BUSINESS, LOST DATA OR LOST
          PROFITS, ARISING OUT OF RELATING TO ITS PRODUCT INCLUDING, BUT NOT
          LIMITED TO, THE ACCURACY OF DATA, INFORMATION, AND MARKET ESTIMATES
          PROVIDED THEREIN.
        </Typography>
        <Typography paragraph>
          NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY FICURVE, ITS
          EMPLOYEES, DISTRIBUTORS, DEALERS OR AGENTS SHALL INCREASE THE SCOPE OF
          THE ABOVE WARRANTIES OR CREATE ANY NEW WARRANTIES. FICURVE IS NOT
          RESPONSIBLE FOR PROBLEMS CAUSED BY CHANGES IN THE OPERATING
          CHARACTERISTICS OF COMPUTER HARDWARE OR COMPUTER OPERATING SYSTEMS
          WHICH ARE MADE AFTER THE RELEASE OF THE SOFTWARE, NOR FOR PROBLEMS IN
          THE INTERACTION OF THE SOFTWARE WITH NON-FICURVE PRODUCTS. FICURVE’S
          LIABILITY FOR ANY CLAIM RELATING TO, OR ARISING UNDER THIS AGREEMENT,
          SHALL BE LIMITED TO NO GREATER AMOUNT THAN THE TOTAL USER FEE PAID BY
          LICENSEE UNDER THIS AGREEMENT.
        </Typography>
        <Typography paragraph>
          UNAUTHORIZED DISSEMINATION OF PASSWORDS OF USERS AND/OR UNAUTHORIZED
          ACCESS GRANTED BY AN ACCOUNT USER TO UNAUTHORIZED PERSONNEL WILL
          RESULT IN REVOCATION OF AN ACCOUNT WITH NO REFUND OF THE SUBSCRIPTION
          FEE.
        </Typography>
        <Typography paragraph>
          PURSUANT TO THE TERMS OF AGREEMENT WITH YOUR ORGANIZATION, YOU MAY NOT
          (I) ALLOW FICURVE TO BE ACCESSED BY ANY PARTY WHO DEVELOPS, OR MAY
          SEEK TO DEVELOP, AND/OR DISTRIBUTES SOFTWARE APPLICATIONS WHICH, IN
          THE REASONABLE OPINION OF FICURVE, COMPETE, DIRECTLY OR INDIRECTLY,
          WITH FICURVE, (II) EXPORT, RE-EXPORT, OR OTHERWISE TRANSMIT, DIRECTLY
          OR INDIRECTLY, ANY INFORMATION RECEIVED THROUGH USE OF FICURVE WHICH
          IS EXPRESSLY LIMITED TO VIEW-ONLY, OR (III) ACCESS OR USE FICURVE, OR
          PERMIT THE ACCESS OR USE FICURVE, BY ANY EMPLOYEE, AGENT, OR OTHER
          USER LOCATED OUTSIDE THE UNITED STATES OF AMERICA.
        </Typography>
      </PromptDialog>
    );
  }

  return (
    <>
      <Box sx={boxSx}>
        <Header sx={{ p: 1, maxHeight: "15vh" }} />
        <Divider />
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {activeError !== null && (
            <Box sx={{ height: 50, width: "100%" }}>
              <Alert severity="error">{activeError}</Alert>
            </Box>
          )}
          <Box sx={{ display: "flex", flex: 1 }}>
            <TabPanel activeTab={activeTab} name={TAB_NAMES.MARKET_MONITOR}>
              <MarketMonitor />
            </TabPanel>
            <TabPanel
              activeTab={activeTab}
              name={TAB_NAMES.NATIONAL_PRIVATE_PLACEMENTS}
            >
              <NationalPrivatePlacements />
            </TabPanel>
            <TabPanel activeTab={activeTab} name={TAB_NAMES.DEAL_BUILDER}>
              <DealBuilderMain />
            </TabPanel>
            <TabPanel activeTab={activeTab} name={TAB_NAMES.DRAFT_TRANSACTIONS}>
              <DealBuilderDraftTransactions />
            </TabPanel>
            <TabPanel activeTab={activeTab} name={TAB_NAMES.PRICING_MANAGEMENT}>
              <PricingManagement />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MainWindow;
