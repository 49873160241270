import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Stack,
  Chip,
  ClickAwayListener,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  FilterList,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { visuallyHidden } from "@mui/utils";
import PricingManagementTableRow from "./pricing-management-table-row";
import PromptDialog from "../../components/prompt-dialog";
import { round } from "../../tools/utils";
import moment from "moment";
import { ActivityReport } from "./activity-report";
import { pdf } from "@react-pdf/renderer";
import { RATING_RANKS } from "../constants";

const headCells = [
  {
    label: "Bid Date",
    dataName: "bidDate",
    comparitor: (a, b) => new Date(a.bidDate) - new Date(b.bidDate),
  },
  {
    label: "Closing Date",
    dataName: "deliveryDate",
    comparitor: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
  },
  {
    label: "Transaction Details",
  },
  {
    label: "State",
    dataName: "stateOfIssuance",
  },
  {
    label: "Tax Status",
    dataName: "taxDesignation",
  },
  {
    label: "Average Life",
  },
  {
    label: "Par Amount",
    dataName: "parAmount",
    comparitor: (a, b) => b.parAmount - a.parAmount,
  },
  {
    label: "Rating Grade",
    dataName: "ratingAssumption",
    comparitor: (a, b) =>
      RATING_RANKS[b.ratingAssumption] - RATING_RANKS[a.ratingAssumption],
  },
  {
    label: "BID STATISTICS",
    colSpan: 2,
  },
  { label: "", colSpan: 2 },
];

const getSortDirection = ({ index, orderBy, reverseSort }) => {
  const isCurrentSort = orderBy === index;
  return isCurrentSort || !reverseSort ? "desc" : "asc";
};

const activityReportColumns = [
  {
    label: "Bid Date",
    dataName: "bidDate",
    align: "alignCenter",
    width: "5.5%",
    formatter: (value) => moment(value).format("M/D/YYYY"),
  },
  {
    label: "State",
    dataName: "stateOfIssuance",
    align: "alignLeft",
    width: "5.5%",
  },
  {
    label: "Issuer",
    dataName: "borrower",
    align: "alignLeft",
    width: "15.5%",
  },
  {
    label: "Issue",
    dataName: "issuanceTitle",
    align: "alignLeft",
    width: "22%",
  },
  {
    label: "Advisor",
    dataName: "advisor",
    align: "alignRight",
    width: "10%",
  },
  {
    label: "Tax Designation",
    dataName: "taxDesignation",
    align: "alignRight",
    width: "7%",
  },
  {
    label: "Amount",
    dataName: "parAmount",
    align: "alignRight",
    width: "7%",
    formatter: (value, isFirst) =>
      isFirst ? `$${value.toLocaleString()}` : value.toLocaleString(),
  },
  {
    label: "Rating Grade",
    dataName: "ratingAssumption",
    align: "alignRight",
    width: "5.5%",
  },
  {
    label: "Avg. Life",
    dataName: "averageLife",
    align: "alignRight",
    width: "4.5%",
    formatter: (value) => value.toFixed(1),
  },
  {
    label: "Coupon",
    dataName: "bidRate",
    align: "alignRight",
    width: "4.5%",
    formatter: (value) => `${round(value / 100, 2).toFixed(2)}%`,
  },
  {
    label: "Cover Bid",
    dataName: "winningBid",
    align: "alignRight",
    width: "4.5%",
    formatter: (value) =>
      value !== "-" ? `${round(value / 100, 2).toFixed(2)}%` : value,
  },
  {
    label: "Variance To Market",
    dataName: "variance",
    align: "alignRight",
    width: "8.5%",
    formatter: (value, isFirst) => {
      let returnValue = Math.abs(value).toLocaleString();
      if (value < 0) {
        returnValue = `(${returnValue})`;
      }
      if (isFirst) {
        returnValue = `$${returnValue}`;
      }
      return returnValue;
    },
  },
];

const getSortedAndFilteredDeals = ({ deals, filter, orderBy, reverseSort }) =>
  deals
    .filter((deal) => {
      if (!filter.column) {
        return true;
      }
      const lowerCaseFieldValue = `${deal[filter.column]}`.toLowerCase();
      return filter.filterType === "text-contains"
        ? lowerCaseFieldValue.includes(filter.value.toLowerCase())
        : lowerCaseFieldValue === filter.value.toLowerCase();
    })
    .toSorted(
      reverseSort
        ? (a, b) => headCells[orderBy].comparitor(b, a)
        : headCells[orderBy].comparitor
    );

const HeaderContent = ({ headCell, filter, setFilter }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    // Reset filter text when another column is filtered
    if (filter.column !== headCell.dataName) {
      setFilterText("");
    }
  }, [filter, headCell.dataName]);

  const isFiltering = useMemo(
    () => filter.column === headCell.dataName,
    [filter, headCell.dataName]
  );

  const saveAndClose = useCallback(() => {
    setAnchorEl();
    if (filterText) {
      setFilter({
        column: headCell.dataName,
        value: filterText,
        filterType: headCell.filterType,
      });
    }
  }, [filterText, headCell.dataName, headCell.filterType, setFilter]);

  const onClickAway = useCallback(() => {
    if (anchorEl) {
      saveAndClose();
    }
  }, [anchorEl, saveAndClose]);

  const toggleAnchor = useCallback(
    (e) => {
      e.stopPropagation();
      if (anchorEl) {
        saveAndClose();
      } else {
        setAnchorEl(e.currentTarget);
      }
    },
    [anchorEl, saveAndClose]
  );

  return (
    <>
      <Typography>{headCell.label}</Typography>
      {headCell.filterType && (
        <ClickAwayListener onClickAway={onClickAway}>
          <div>
            {/* root div is necessary for the click away listener */}
            <Button
              id="filter-menu"
              variant="text"
              startIcon={<FilterList />}
              aria-expanded={!!anchorEl ? "true" : undefined}
              aria-haspopup="true"
              onClick={toggleAnchor}
              className={anchorEl ? "filter-menu-open" : undefined}
              sx={{
                "& .MuiButton-startIcon": { mx: 0 },
                minWidth: 24,
                py: 0.3,
                px: 0,
                backgroundColor: "white",
                transition: "transform 0.1s linear",
                transform: "rotate(0deg)",
                "&.filter-menu-open": {
                  transform: "rotate(-90deg)",
                },
              }}
            />
            <Popper
              open={anchorEl}
              anchorEl={anchorEl}
              sx={{ zIndex: 2 }}
              placement="right"
            >
              <TextField
                label="Search by"
                value={filterText}
                onClick={(e) => e.stopPropagation()}
                onChange={({ target }) => setFilterText(target.value)}
                onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
                size="small"
                sx={{ backgroundColor: "white" }}
              />
            </Popper>
          </div>
        </ClickAwayListener>
      )}
      {isFiltering && (
        <Chip
          label={filter.value}
          onDelete={() =>
            setFilter({ column: "", value: "", filterType: "text-contains" })
          }
          size="small"
          variant="outlined"
          sx={{ maxWidth: 80 }}
        />
      )}
    </>
  );
};

function Manage({
  deals,
  allDeals,
  handleUpdateDeal,
  handleDeleteDeal,
  handleDuplicateDeal,
  handleReassignDeal,
  AAAData,
  USTData,
  FHLBData,
  defaultOrderColumnIndex = 0,
}) {
  const storedFilter = localStorage.getItem("bidManagementFilter");
  const [selectedDealID, setSelectedDealID] = useState();
  const [orderBy, setOrderBy] = useState(defaultOrderColumnIndex);
  const [reverseSort, setReverseSort] = useState(true);
  const [reportDateRange, setReportDateRange] = useState([
    moment().subtract(1, "month"),
    moment(),
  ]);
  const [reportError, setReportError] = useState("");
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false);
  const [filter, setFilter] = useState(
    storedFilter
      ? JSON.parse(storedFilter)
      : {
          column: null,
          value: null,
          type: "text-contains",
        }
  );
  const sortedAndFilteredDeals = useMemo(
    () =>
      getSortedAndFilteredDeals({
        deals,
        filter,
        orderBy,
        reverseSort,
      }),
    [deals, filter, orderBy, reverseSort]
  );

  const handleSortChange = useCallback(
    (newOrderBy) => {
      if (orderBy === newOrderBy) {
        setReverseSort(!reverseSort);
      } else {
        setOrderBy(newOrderBy);
        setReverseSort(false);
      }
    },
    [orderBy, reverseSort]
  );

  const handleFilterChange = (newFilter) => {
    localStorage.setItem("bidManagementFilter", JSON.stringify(newFilter));
    setFilter(newFilter);
  };

  const handleGenerateReport = async () => {
    setReportError("");

    const activityReportDeals = allDeals.filter(
      ({ bidDate }) =>
        bidDate >= reportDateRange[0].startOf("day") &&
        bidDate <= reportDateRange[1].endOf("day")
    );

    if (!activityReportDeals.length) {
      setReportError("No deals found within the specified date range");
      return;
    }

    let won = [];
    let lost = [];
    const wonTotalsAndAverages = {
      advisor: "TOTALS/AVERAGES",
      parAmount: 0,
      averageLife: 0,
      bidRate: 0,
      winningBid: 0,
      variance: 0,
    };
    const lostTotalsAndAverages = {
      advisor: "TOTALS/AVERAGES",
      parAmount: 0,
      averageLife: 0,
      bidRate: 0,
      winningBid: 0,
      variance: 0,
    };
    let coverBidCount = 0;
    let winningBidCount = 0;
    activityReportDeals.forEach((deal) => {
      const bidRate = deal.bidRate || 400;
      const bids = deal.bids.toSorted(
        ({ rate: rate1 }, { rate: rate2 }) => rate1 - rate2
      );
      const averageLife = round(deal.savedDealBuilder.averageLife, 1);
      const winningBid = bids.length ? bids[0].rate - bidRate : "-";
      if (deal.status === "Won" || deal.status === "Closed") {
        won.push({
          ...deal,
          bidRate,
          averageLife,
          winningBid,
        });
        wonTotalsAndAverages.parAmount += deal.parAmount;
        wonTotalsAndAverages.averageLife += averageLife;
        wonTotalsAndAverages.bidRate += bidRate;
        if (bids.length) {
          coverBidCount++;
          wonTotalsAndAverages.winningBid += winningBid;
        }
        wonTotalsAndAverages.variance += deal.variance;
      } else if (deal.status === "Lost") {
        lost.push({
          ...deal,
          bidRate,
          averageLife,
          winningBid,
        });
        lostTotalsAndAverages.parAmount += deal.parAmount;
        lostTotalsAndAverages.averageLife += averageLife;
        lostTotalsAndAverages.bidRate += bidRate;
        if (bids.length) {
          winningBidCount++;
          lostTotalsAndAverages.winningBid += winningBid;
        }
        lostTotalsAndAverages.variance += deal.variance;
      }
    });

    wonTotalsAndAverages.averageLife = round(
      wonTotalsAndAverages.averageLife / won.length,
      2
    );
    wonTotalsAndAverages.bidRate = round(
      wonTotalsAndAverages.bidRate / won.length,
      0
    );
    wonTotalsAndAverages.winningBid = round(
      wonTotalsAndAverages.winningBid / coverBidCount,
      2
    );
    lostTotalsAndAverages.averageLife = round(
      lostTotalsAndAverages.averageLife / lost.length,
      2
    );
    lostTotalsAndAverages.bidRate = round(
      lostTotalsAndAverages.bidRate / lost.length,
      0
    );
    lostTotalsAndAverages.winningBid = round(
      lostTotalsAndAverages.winningBid / winningBidCount,
      2
    );

    const activityReportData = {
      title: "Public Finance Deal Activity Report",
      dateRange: reportDateRange,
      sections: [
        {
          title: "WON",
          color: "#aad08e",
          rows: won.toSorted(
            ({ bidDate: date1 }, { bidDate: date2 }) => date1 - date2
          ),
          totalsAndAverages: wonTotalsAndAverages,
          columns: activityReportColumns,
        },
        {
          title: "LOST",
          color: "#ddebf7",
          rows: lost.toSorted(
            ({ bidDate: date1 }, { bidDate: date2 }) => date1 - date2
          ),
          totalsAndAverages: lostTotalsAndAverages,
          columns: [
            ...activityReportColumns.slice(0, 10),
            { ...activityReportColumns[10], label: "Winning Bid" },
            ...activityReportColumns.slice(11, activityReportColumns.length),
          ],
        },
      ],
    };
    try {
      const blob = await pdf(
        <ActivityReport {...activityReportData} />
      ).toBlob();
      const pdfUrl = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = pdfUrl;
      tempLink.setAttribute(
        "download",
        `activity-report-${moment().format("MM-DD-YYYY-HH:mm:ss:SS")}.pdf`
      );
      tempLink.click();
      tempLink.remove();
      setIsGenerateReportModalOpen(false);
    } catch (err) {
      console.debug(err);
      setReportError("There was an error generating the PDF");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "left",
        flex: 1,
        m: 3,
      }}
    >
      <PromptDialog
        title="Delete Confirmation"
        open={!!selectedDealID}
        onCancel={() => setSelectedDealID()}
        onOk={async () => {
          await handleDeleteDeal(selectedDealID);
          setSelectedDealID();
        }}
      >
        <Typography>Are you sure you want to delete this deal?</Typography>
      </PromptDialog>
      <PromptDialog
        title="Generate Activity Report"
        open={isGenerateReportModalOpen}
        onCancel={() => setIsGenerateReportModalOpen(false)}
        onOk={handleGenerateReport}
        okBtnText="Submit"
      >
        <Stack direction="column">
          {reportError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {reportError}
            </Alert>
          )}
          <Stack direction="row" gap={1}>
            <DatePicker
              id="report-start-date"
              label="Start Bid Date"
              inputFormat="MM/DD/YYYY"
              value={reportDateRange[0]}
              onChange={(e) => setReportDateRange([e, reportDateRange[1]])}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
              id="report-end-date"
              label="End Bid Date"
              inputFormat="MM/DD/YYYY"
              value={reportDateRange[1]}
              onChange={(e) => setReportDateRange([reportDateRange[0], e])}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </Stack>
        </Stack>
      </PromptDialog>
      <Button
        variant="contained"
        onClick={() => setIsGenerateReportModalOpen(true)}
        sx={{ alignSelf: "flex-end", mb: 2, mt: -2 }}
      >
        Generate Report
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell
                key={headCell.label}
                sortDirection={getSortDirection({
                  index,
                  orderBy,
                  reverseSort,
                })}
                colSpan={headCell.colSpan}
              >
                {headCell.comparitor ? (
                  <TableSortLabel
                    active={orderBy === index}
                    direction={getSortDirection({
                      index,
                      orderBy,
                      reverseSort,
                    })}
                    onClick={() => handleSortChange(index)}
                    IconComponent={
                      reverseSort
                        ? KeyboardDoubleArrowUp
                        : KeyboardDoubleArrowDown
                    }
                    sx={{
                      "& .MuiTableSortLabel-icon.MuiSvgIcon-root": {
                        color: "#1976d2",
                      },
                    }}
                  >
                    <HeaderContent
                      headCell={headCell}
                      filter={filter}
                      setFilter={handleFilterChange}
                    />
                    {orderBy === index ? (
                      <Box component="span" sx={visuallyHidden}>
                        {reverseSort ? "sorted ascending" : "sorted descending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Typography>{headCell.label}</Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAndFilteredDeals.map((deal, index) => (
            <PricingManagementTableRow
              key={deal.id}
              deal={deal}
              setDeal={handleUpdateDeal}
              setSelectedDealID={setSelectedDealID}
              handleDuplicateDeal={handleDuplicateDeal}
              handleReassignDeal={handleReassignDeal}
              AAAData={AAAData}
              USTData={USTData}
              FHLBData={FHLBData}
              isFinalRow={index === sortedAndFilteredDeals.length - 1}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default Manage;
