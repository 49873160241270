import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./login";
import MainWindow from "./main-window";
import { DealBuilderProvider } from "./contexts/DealBuilderContext";
import { ErrorProvider } from "./contexts/ErrorContext";
import { MainTabProvider } from "./contexts/MainTabContext";
import { BidAnalyzerProvider } from "./contexts/BidAnalyzerContext";
import { UserInfoProvider } from "./contexts/UserInfoContext";

function App() {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const checkAuth = async () => {
    try {
      await getAccessTokenSilently();
    } catch {} // Don't throw. Direct to login if token fetch fails
  };
  useEffect(() => {
    checkAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!isAuthenticated) return <Login isLoading={isLoading} />;
  return (
    <ErrorProvider>
      <MainTabProvider>
        <DealBuilderProvider>
          <BidAnalyzerProvider>
            <UserInfoProvider>
              <MainWindow />
            </UserInfoProvider>
          </BidAnalyzerProvider>
        </DealBuilderProvider>
      </MainTabProvider>
    </ErrorProvider>
  );
}

export default App;
