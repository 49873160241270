import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import formatter from "../../tools/formatter";
import ReportPageHeader from "./report-page-header";
import { DealBuilderContext } from "../../contexts/DealBuilderContext";
import { deepCopy } from "../../tools/utils";
import { Edit, Save } from "@mui/icons-material";

function DebtServiceRow({
  row,
  showPrincipalInput,
  showInputError,
  handlePrincipalBlur,
  hasCapitalizedInterest,
  index,
  inputRefs,
}) {
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      } else if (inputRefs.current[index + 2]) {
        inputRefs.current[index + 2].focus();
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      } else if (inputRefs.current[index - 2]) {
        inputRefs.current[index - 2].focus();
      }
    }
  };

  return (
    <TableRow>
      <TableCell align="right">
        {row.paymentDate.format("MM/DD/YYYY")}
      </TableCell>
      <TableCell align="right">
        {showPrincipalInput ? (
          <TextField
            type="number"
            size="small"
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            defaultValue={row.principal}
            onBlur={handlePrincipalBlur}
            onKeyDown={handleKeyDown}
            error={showInputError}
            helperText={
              showInputError
                ? "Must be divisible by minimum denomination"
                : undefined
            }
            sx={{
              my: 0.8,
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
              },
            }}
          />
        ) : (
          formatter.money(row.principal)
        )}
      </TableCell>
      <TableCell align="right">{formatter.percent(row.couponRate)}</TableCell>
      <TableCell align="right">{formatter.money(row.interest)}</TableCell>
      {hasCapitalizedInterest ? (
        <TableCell align="right">
          {row.capitalizedInterest
            ? `(${formatter.money(row.capitalizedInterest)})`
            : ""}
        </TableCell>
      ) : null}
      <TableCell align="right">
        {formatter.money(row.totalDebtService)}
      </TableCell>
      <TableCell align="right">
        {formatter.money(row.annualDebtService)}
      </TableCell>
    </TableRow>
  );
}

function BondDebtService() {
  const principalInputRefs = useRef([]);
  const {
    dealBuilder,
    dealBuilderInputs,
    usePrincipalValues,
    setUsePrincipalValues,
    principalValues,
    setPrincipalValues,
    setShowManualPrincipalWarningDialog,
    showPrincipalInputs,
    setShowPrincipalInputs,
  } = useContext(DealBuilderContext);
  const [errorRows, setErrorRows] = useState([]);
  const hasCapitalizedInterest = !!dealBuilder.displayOnlyTableRows;

  const handlePrincipalBlur = (index, value) => {
    const integerValue = +value;
    if (integerValue % dealBuilder.denomination === 0) {
      dealBuilder.updateRowPrincipal(index, integerValue);
      dealBuilderInputs.parAmount.setValue(dealBuilder.parAmount, true);

      const newPrincipalValues = deepCopy(principalValues);
      newPrincipalValues[index] = integerValue;
      setPrincipalValues(newPrincipalValues);

      setErrorRows(errorRows.filter((i) => i !== index));
    } else if (!errorRows.includes(index)) {
      setErrorRows([...errorRows, index]);
    }
  };

  const handlePrincipalButtonClick = () => {
    if (usePrincipalValues) {
      setShowManualPrincipalWarningDialog(true);
    } else {
      setUsePrincipalValues(true);
      setShowPrincipalInputs(true);
    }
  };

  const tableData = hasCapitalizedInterest
    ? dealBuilder.displayOnlyTableRows
    : dealBuilder.tableRows;
  const finalRow = (
    <TableRow sx={{ "& .MuiTableCell-root": { fontWeight: "bold" } }}>
      <TableCell>TOTALS</TableCell>
      <TableCell align="right">
        {formatter.money(dealBuilder.totalPrincipal)}
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        {formatter.money(dealBuilder.totalInterest)}
      </TableCell>
      {hasCapitalizedInterest ? (
        <TableCell align="right">
          {formatter.money(dealBuilder.totalCapitalizedInterest)}
        </TableCell>
      ) : null}
      <TableCell align="right">
        {formatter.money(
          hasCapitalizedInterest
            ? dealBuilder.displayOnlyTotalTotalDebtService
            : dealBuilder.totalTotalDebtService
        )}
      </TableCell>
      <TableCell align="right">
        {formatter.money(
          hasCapitalizedInterest
            ? dealBuilder.displayOnlyTotalAnnualDebtService
            : dealBuilder.totalAnnualDebtService
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ReportPageHeader dealBuilder={dealBuilder} title="BOND DEBT SERVICE" />
      <TableContainer className="printableTable">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow className="noPrint">
              <TableCell colSpan={hasCapitalizedInterest ? 7 : 6} align="left">
                <ButtonGroup variant="outlined" sx={{ mb: 1 }}>
                  <Button onClick={handlePrincipalButtonClick}>
                    {usePrincipalValues
                      ? "Use Automatic Amortization"
                      : "Add Manual Inputs"}
                  </Button>
                  {usePrincipalValues && (
                    <Button
                      startIcon={showPrincipalInputs ? <Save /> : <Edit />}
                      onClick={() =>
                        setShowPrincipalInputs(!showPrincipalInputs)
                      }
                    >
                      {showPrincipalInputs ? "Save" : "Edit"}
                    </Button>
                  )}
                </ButtonGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Payment Date</TableCell>
              <TableCell align="right">Principal</TableCell>
              <TableCell align="right">Coupon Rate</TableCell>
              <TableCell align="right">Interest</TableCell>
              {hasCapitalizedInterest ? (
                <TableCell align="right">Capitalized Interest</TableCell>
              ) : null}
              <TableCell align="right">Total Debt Service</TableCell>
              <TableCell align="right">Annual Debt Service</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <DebtServiceRow
                key={index}
                row={row}
                showPrincipalInput={
                  usePrincipalValues &&
                  showPrincipalInputs &&
                  row.principal !== null
                }
                showInputError={errorRows.includes(index)}
                handlePrincipalBlur={(e) =>
                  handlePrincipalBlur(index, e.target.value || 0)
                }
                hasCapitalizedInterest={hasCapitalizedInterest}
                index={index}
                inputRefs={principalInputRefs}
              />
            ))}
            {finalRow}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BondDebtService;
