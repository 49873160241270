import { round } from "../tools/utils";

const getMatchingUSTRate = (record, averageLife) => {
  // Round down to the nearest treasury time increment
  if (averageLife < 0.1667) return record?.['"1 Mo"'];
  if (averageLife < 0.25) return record?.['"2 Mo"'];
  if (averageLife < 0.3333) return record?.['"3 Mo"'];
  if (averageLife < 0.5) return record?.['"4 Mo"'];
  if (averageLife < 1) return record?.['"6 Mo"'];
  if (averageLife < 2) return record?.['"1 Yr"'];
  if (averageLife < 3) return record?.['"2 Yr"'];
  if (averageLife < 5) return record?.['"3 Yr"'];
  if (averageLife < 7) return record?.['"5 Yr"'];
  if (averageLife < 10) return record?.['"7 Yr"'];
  if (averageLife < 20) return record?.['"10 Yr"'];
  if (averageLife < 30) return record?.['"20 Yr"'];
  return record?.['"30 Yr"'];
};

export const getDealSpreadRecords = ({ deal, AAAData, USTData, FHLBData }) => {
  const bidDateAsFHLBString = deal.bidDate?.format("YYYY-MM-DD");
  const bidDateAsAAAString = `${bidDateAsFHLBString}Z`;
  const bidDateAsUSTString = deal.bidDate?.format("MM/DD/YYYY");
  const roundedAverageLife = round(deal.savedDealBuilder.averageLife, 0);

  // AAA
  let matchingAAARecord, matchingAAARate;
  if (
    [
      "Bank Qualified",
      "Non-Bank Qualified",
      "Alternative Minimum Tax",
    ].includes(deal.taxDesignation)
  ) {
    matchingAAARecord = AAAData.find(({ Date }) => Date === bidDateAsAAAString);
    matchingAAARate = matchingAAARecord?.[roundedAverageLife];
  }

  // UST
  const matchingUSTRecord = USTData.find(
    ({ Date }) => Date === bidDateAsUSTString
  );
  const matchingUSTRate = getMatchingUSTRate(
    matchingUSTRecord,
    deal.savedDealBuilder.averageLife
  );

  // FHLB
  const matchingFHLBRecord = FHLBData.find(
    ({ Date }) => Date === bidDateAsFHLBString
  );
  const matchingFHLBRate = matchingFHLBRecord?.[roundedAverageLife];

  return {
    matchingAAARate,
    matchingUSTRate,
    matchingFHLBRate,
  };
};
