import { CouponSelection, PrincipalRate } from "../constants";
import moment from "moment";
import { lerp } from "../tools/solvers";
import {
  calcPrice,
  calcYTM,
  customDays360,
  deepCopy,
  round,
} from "../tools/utils";

const DenominationDefault = 5000;

const Field = {
  Coupon: "coupon",
  AAA: "AAA",
  Spread: "spread",
};

class DealBuilderService {
  parAmount;
  denomination = DenominationDefault;
  deliveryDate;
  firstCoupon;
  firstPrincipal;
  finalPrincipal;
  useCallDate;
  callDate;
  principalFrequency;

  borrower;
  issuanceTitle;
  ratingAssumption;
  taxDesignation;
  marketConditionsDate;

  engineResponse;

  tableRows = [];
  displayOnlyTableRows;
  totalPrincipal = 0.0;
  totalCapitalizedInterest = 0.0;
  totalInterest = 0.0;
  totalTotalDebtService = 0.0; // lol
  displayOnlyTotalTotalDebtService = 0.0;
  totalAnnualDebtService = 0.0;
  displayOnlyTotalAnnualDebtService = 0.0;
  totalProduction = 0.0;
  totalBondYears = 0.0;

  maxAnnualDebtService = 0.0;

  NIC = 0.0;
  TIC = 0.0;
  expenseAdjustedTIC = 0.0;
  WAM = 0.0;
  averageLife = 0.0;
  averageCoupon = 0.0;

  sources = [];
  uses = [];

  ticAdjustingExpenses = undefined;
  capitalizedInterestDate = undefined;

  errors = [];

  usePricingCushion;
  cushion = 0.0;

  useCustomCouponYield;
  customCouponYield = 0.0;

  useFiCurveParPricing = false;

  principalStartRow = 0;

  dealHasYTM = false;

  constructor(inputData, data, engineResponse) {
    if (inputData) {
      this.constructWithInputData(inputData);
    } else {
      this.constructWithStandardData(data);
    }

    this.engineResponse = this.parseEngineResponse(engineResponse);

    this.buildTableData();
  }

  constructWithInputData(data) {
    this.parAmount = data.parAmount.getValue();
    this.denomination = data.denomination.getValue();
    this.deliveryDate =
      typeof data.deliveryDate.getValue() === "string"
        ? moment(data.deliveryDate?.getValue())
        : data.deliveryDate?.getValue();
    this.firstCoupon =
      typeof data.firstCoupon.getValue() === "string"
        ? moment(data.firstCoupon.getValue())
        : data.firstCoupon.getValue();
    this.firstPrincipal =
      typeof data.firstPrincipal.getValue() === "string"
        ? moment(data.firstPrincipal.getValue())
        : data.firstPrincipal.getValue();
    this.finalPrincipal =
      typeof data.finalPrincipal.getValue() === "string"
        ? moment(data.finalPrincipal.getValue())
        : data.finalPrincipal.getValue();
    this.useCallDate = data.useCallDate?.getValue();
    this.callDate =
      typeof data.callDate.getValue() === "string"
        ? moment(data.callDate.getValue())
        : data.callDate.getValue();
    this.principalFrequency = data.principalFrequency.getValue();

    this.borrower = data.borrower?.getValue();
    this.issuanceTitle = data.issuanceTitle?.getValue();
    this.ratingAssumption = data.ratingAssumption?.getValue();
    this.taxDesignation = data.taxDesignation?.getValue();
    this.marketConditionsDate =
      typeof data.marketConditionsDate.getValue() === "string"
        ? moment(data.marketConditionsDate.getValue())
        : data.marketConditionsDate.getValue();

    this.usePricingCushion = data.usePricingCushion?.getValue();
    this.cushion = (data.cushion?.getValue() ?? 0) / 100;

    this.useCustomCouponYield = data.useCustomCouponYield?.getValue();
    this.customCouponYield = data.customCouponYield?.getValue() ?? 0;

    this.useFiCurveParPricing = data.useFiCurveParPricing?.getValue();

    this.couponSelection =
      data.couponSelection?.getValue() ===
      CouponSelection.FiCurveCouponPrediction
        ? "yes"
        : "no";
  }

  constructWithStandardData(data) {
    this.parAmount = data.parAmount;
    this.denomination = data.denomination;
    this.deliveryDate =
      typeof data.deliveryDate === "string"
        ? moment(data.deliveryDate)
        : data.deliveryDate;
    this.firstCoupon =
      typeof data.firstCoupon === "string"
        ? moment(data.firstCoupon)
        : data.firstCoupon;
    this.firstPrincipal =
      typeof data.firstPrincipal === "string"
        ? moment(data.firstPrincipal)
        : data.firstPrincipal;
    this.finalPrincipal =
      typeof data.finalPrincipal === "string"
        ? moment(data.finalPrincipal)
        : data.finalPrincipal;
    this.useCallDate = data.useCallDate;
    this.callDate =
      typeof data.callDate === "string" ? moment(data.callDate) : data.callDate;
    this.principalFrequency = data.principalFrequency;

    this.borrower = data.borrower;
    this.issuanceTitle = data.issuanceTitle;
    this.ratingAssumption = data.ratingAssumption;
    this.taxDesignation = data.taxDesignation;
    this.marketConditionsDate =
      typeof data.marketConditionsDate === "string"
        ? moment(data.marketConditionsDate)
        : data.marketConditionsDate;

    this.usePricingCushion = data.usePricingCushion;
    this.cushion = (data.cushion ?? 0) / 100;

    this.useCustomCouponYield = data.useCustomCouponYield;
    this.customCouponYield = data.customCouponYield ?? 0;

    this.useFiCurveParPricing = data.useFiCurveParPricing;

    this.couponSelection =
      data.couponSelection === CouponSelection.FiCurveCouponPrediction
        ? "yes"
        : "no";
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  getCoupon(principalRows, principalIndex) {
    if (!!this.useCustomCouponYield) {
      return this.customCouponYield / 100;
    }
    return principalRows[principalIndex]?.couponRate;
  }

  buildTableData() {
    const totalTimeSpan = this.getTotalTimeSpan();
    const principalTimeSpan = this.getPrincipalTimeSpan();
    if (totalTimeSpan < 0 || totalTimeSpan % 6 !== 0) {
      this.errors.push(
        `Time difference between First Coupon and Final Principal must be in 6 month intervals`
      );
    }
    const principalInterval = this.isSemiAnnual() ? 6 : 12;
    if (principalTimeSpan % principalInterval !== 0) {
      this.errors.push(
        `Interval between First Principal and Final Principal must match annual/semi-annual setting`
      );
    }

    if (this.hasErrors()) return;

    const totalRowCount = totalTimeSpan / 6 + 1;
    const principalRowCount = principalTimeSpan / 6 + 1;
    this.principalStartRow = totalRowCount - principalRowCount;

    let tableData = [];
    let principalRows = [];
    let currentPrincipalRow = 0;
    for (let i = 0; i < totalRowCount; i++) {
      const rowDate = moment(this.firstCoupon);
      rowDate.add(6 * i, "month");
      let newRow = this.makeNewRow(rowDate);

      if (this.isPrincipalRow(i)) {
        newRow.principal = 0.0;
        newRow.couponRate = this.rowValue(Field.Coupon, currentPrincipalRow);

        newRow.AAA = this.rowValue(Field.AAA, currentPrincipalRow);
        newRow.spread = this.rowValue(Field.Spread, currentPrincipalRow);
        newRow.YTC = round(newRow.AAA + newRow.spread, 4);

        const rowHasYTM =
          this.shouldUseCallDate() &&
          newRow.paymentDate > this.callDate &&
          newRow.YTC < newRow.couponRate;

        if (!this.useCustomCouponYield) {
          newRow.price = calcPrice(
            rowHasYTM ? this.callDate : newRow.paymentDate,
            this.deliveryDate,
            newRow.couponRate,
            newRow.YTC
          );
        } else {
          newRow.price = 100.0;
        }

        if (rowHasYTM) {
          newRow.YTM = calcYTM(
            newRow.paymentDate,
            this.deliveryDate,
            newRow.couponRate,
            newRow.price,
            newRow.YTC
          );
          this.dealHasYTM = true;
        }

        if (!this.useCustomCouponYield && this.useFiCurveParPricing) {
          newRow.price = 100.0;
          let YTW = rowHasYTM ? Math.max(newRow.YTC, newRow.YTM) : newRow.YTC;
          YTW = Math.ceil(YTW / 0.0005) * 0.0005;
          newRow.YTC = YTW;
          newRow.spread = newRow.YTC - newRow.AAA;
          newRow.couponRate = YTW;
        }

        principalRows.push(newRow);
        currentPrincipalRow++;
      }

      tableData.push(newRow);
    }

    this.averageCoupon = this.calcAverageCoupon(principalRows);
    const principalAmounts = this.calcPrincipalAmounts(principalRows);

    this.recalculateTableDataForNewPrincipalAmounts(
      principalAmounts,
      tableData
    );
  }

  recalculateTableDataForNewPrincipalAmounts(principalAmounts, tableData) {
    const totalRowCount = tableData.length;
    const annualDebtRowOffset = (totalRowCount + 1) % 2;

    const principalRows = tableData.filter((row) => row.principal !== null);

    this.resetAllTotals();

    let currentPrincipalRow = 0;

    for (let i = 0; i < totalRowCount; i++) {
      let newRow = tableData[i];

      // Recalculate row interest
      newRow.interest = 0.0;
      for (
        let principalIndex = currentPrincipalRow;
        principalIndex < principalAmounts.length;
        principalIndex++
      ) {
        newRow.interest +=
          principalAmounts[principalIndex] *
          this.getCoupon(principalRows, principalIndex);
      }
      if (i === 0) {
        newRow.interest *=
          customDays360(this.deliveryDate, this.firstCoupon) / 360;
      } else {
        newRow.interest = newRow.interest / 2;
      }

      newRow.totalDebtService = newRow.interest;

      // Set principal if this is a principal row
      if (this.isPrincipalRow(i)) {
        newRow.principal = principalAmounts[currentPrincipalRow];
        newRow.totalDebtService += newRow.principal;

        newRow.production = Math.round(newRow.principal * newRow.price) / 100;

        this.totalBondYears +=
          (newRow.principal *
            customDays360(this.deliveryDate, newRow.paymentDate)) /
          360;

        currentPrincipalRow++;
      }

      if ((i + annualDebtRowOffset) % 2 === 0) {
        newRow.annualDebtService = newRow.totalDebtService;
        if (i > 0) {
          newRow.annualDebtService += tableData[i - 1].totalDebtService;
        }
      }

      this.totalPrincipal += newRow.principal ?? 0.0;
      this.totalAnnualDebtService += newRow.annualDebtService ?? 0.0;
      this.totalProduction += newRow.production ?? 0.0;
      this.totalInterest += newRow.interest ?? 0.0;
      this.totalTotalDebtService += newRow.totalDebtService ?? 0.0;

      this.maxAnnualDebtService = Math.max(
        this.maxAnnualDebtService,
        newRow.annualDebtService ?? 0.0
      );
    }

    this.tableRows = tableData;

    this.calcDisplayTableRows();
    this.calcAdditionalInfo();
  }

  isPrincipalRow(rowIndex) {
    const principalYearOffset = this.principalStartRow % 2;
    return (
      rowIndex >= this.principalStartRow &&
      (this.isSemiAnnual() || (rowIndex + principalYearOffset) % 2 === 0)
    );
  }

  // rowIndex should be the global row index, not the principal row index
  updateRowPrincipal(rowIndex, newPrincipal) {
    if (rowIndex >= this.tableRows.length || !this.isPrincipalRow(rowIndex)) {
      throw new Error(
        `Cannot update principal for row: invalid principal row index ${rowIndex}`
      );
    }

    this.parAmount += newPrincipal - this.tableRows[rowIndex].principal;

    this.tableRows[rowIndex].principal = newPrincipal;
    const principalAmounts = this.tableRows.reduce((amounts, tableRow) => {
      if (tableRow.principal !== null) {
        amounts.push(tableRow.principal);
      }
      return amounts;
    }, []);

    this.recalculateTableDataForNewPrincipalAmounts(
      principalAmounts,
      this.tableRows
    );
  }

  // principal values should include empty global row indicies
  updatePrincipals(newPrincipals) {
    for (const [rowIndex, newPrincipal] of newPrincipals.entries()) {
      // skip null values
      if (newPrincipal === null || newPrincipal === undefined) continue;
      if (rowIndex >= this.tableRows.length || !this.isPrincipalRow(rowIndex)) {
        throw new Error(
          `Cannot update principal for row: invalid principal row index ${rowIndex}`
        );
      }

      this.parAmount += newPrincipal - this.tableRows[rowIndex].principal;

      this.tableRows[rowIndex].principal = newPrincipal;
    }

    const principalAmounts = this.tableRows.reduce((amounts, tableRow) => {
      if (tableRow.principal !== null) {
        amounts.push(tableRow.principal);
      }
      return amounts;
    }, []);

    this.recalculateTableDataForNewPrincipalAmounts(
      principalAmounts,
      this.tableRows
    );
  }

  resetAllTotals() {
    this.totalBondYears = 0.0;
    this.totalPrincipal = 0.0;
    this.totalAnnualDebtService = 0.0;
    this.totalProduction = 0.0;
    this.totalInterest = 0.0;
    this.totalTotalDebtService = 0.0;
    this.displayOnlyTotalAnnualDebtService = 0.0;
    this.displayOnlyTotalTotalDebtService = 0.0;
    this.maxAnnualDebtService = 0.0;
  }

  shouldUseCallDate() {
    return this.useCallDate && !!this.callDate;
  }

  showYTM() {
    return this.dealHasYTM;
  }

  getTotalTimeSpan() {
    return this.getMonthSpanBetweenDates(this.firstCoupon, this.finalPrincipal);
  }

  getPrincipalTimeSpan() {
    return this.getMonthSpanBetweenDates(
      this.firstPrincipal,
      this.finalPrincipal
    );
  }

  getMonthSpanBetweenDates(a, b) {
    return (
      moment(b).month() -
      moment(a).month() +
      12 * (moment(b).year() - moment(a).year())
    );
  }

  calcPrincipalAmounts(principalRows) {
    const principalRowCount = principalRows.length;

    let avgR = this.averageCoupon / principalRowCount;

    let x_1 =
      (this.parAmount * 1000 * avgR) /
      (1 - Math.pow(avgR + 1, -principalRowCount));
    let x_2 = x_1 * 0.8;

    let targetPayment = lerp(
      (x) => this.reverseCalcStartingPrincipal(x, principalRows),
      this.parAmount,
      x_1,
      x_2,
      0.01
    );

    let sortedPaymentSizes = [];
    let futureInterest = 0.0;
    let totalPrincipal = 0.0;
    let P = 0.0;
    for (let i = principalRowCount - 1; i >= 0; i--) {
      const R = this.getInterestRateForPrincipalRow(i, principalRows);
      const mod = R / principalRows[i].couponRate;

      P = this.toNearestDenomination(
        (targetPayment - futureInterest * mod) / (R + 1)
      );
      P = Math.max(P, this.denomination);

      totalPrincipal += P;
      futureInterest += P * R;

      const paymentSizeObj = {
        index: i,
        principal: P,
        payment: 0.0,
        rate: principalRows[i].couponRate,
        mod: mod,
      };
      sortedPaymentSizes.unshift(paymentSizeObj);
    }

    // console.debug("INITIAL")
    // console.debug(sortedPaymentSizes.map((v) => {return {...v}}))

    for (let i = 0; i < sortedPaymentSizes.length; i++) {
      let actualPayment = 0.0;
      for (let j = i; j < sortedPaymentSizes.length; j++) {
        actualPayment +=
          sortedPaymentSizes[j].principal * sortedPaymentSizes[j].rate;
      }
      actualPayment =
        actualPayment * sortedPaymentSizes[i].mod +
        sortedPaymentSizes[i].principal;
      sortedPaymentSizes[i].payment = actualPayment;
    }

    // console.debug("AFTER CALC PAYMENTS")
    // console.debug(sortedPaymentSizes.map((v) => {return {...v}}))

    let remainingPrincipal = this.parAmount - totalPrincipal;

    const sortByIndexFunc = (a, b) => {
      return a["index"] - b["index"];
    };

    const sortByPaymentFunc = (a, b) => {
      if (a["payment"] === b["payment"]) {
        return b["index"] - a["index"];
      } else {
        return a["payment"] - b["payment"];
      }
    };

    const adjustPrincipal = (pIndex, amt) => {
      sortedPaymentSizes.sort(sortByIndexFunc);
      sortedPaymentSizes[pIndex]["principal"] += amt;
      sortedPaymentSizes[pIndex]["payment"] += amt;
      const interestChange = sortedPaymentSizes[pIndex]["rate"] * amt;
      for (let k = pIndex; k >= 0; k--) {
        sortedPaymentSizes[k]["payment"] +=
          interestChange * sortedPaymentSizes[k]["mod"];
      }
      sortedPaymentSizes.sort(sortByPaymentFunc);
    };

    sortedPaymentSizes.sort(sortByPaymentFunc);

    // Redistribute leftover principal (results from rounding to denomination increments above)
    const N = sortedPaymentSizes.length - 1;
    while (remainingPrincipal !== 0) {
      if (remainingPrincipal < 0) {
        adjustPrincipal(sortedPaymentSizes[N]["index"], -this.denomination);
        remainingPrincipal += this.denomination;
      } else {
        adjustPrincipal(sortedPaymentSizes[0]["index"], this.denomination);
        remainingPrincipal -= this.denomination;
      }
    }

    // Attempt to shrink spread to within a single denomination
    let remIterations = 10000;
    while (
      remIterations > 0 &&
      sortedPaymentSizes[N]["payment"] - sortedPaymentSizes[0]["payment"] >
        this.denomination
    ) {
      remIterations--;
      // console.debug(`MOVING PRINCIPAL FROM ${sortedPaymentSizes[N]["index"]} TO ${sortedPaymentSizes[0]["index"]}`)
      adjustPrincipal(sortedPaymentSizes[N]["index"], -this.denomination);
      adjustPrincipal(sortedPaymentSizes[0]["index"], this.denomination);
    }
    sortedPaymentSizes.sort(sortByIndexFunc);

    // console.debug(sortedPaymentSizes)
    // console.debug(remIterations)

    return sortedPaymentSizes.map((ps) => ps.principal);
  }

  getInterestRateForPrincipalRow(i, principalRows) {
    let R = this.getCoupon(principalRows, i);
    let periodLength;
    const globalRow =
      this.principalStartRow + i * (this.isSemiAnnual() ? 1 : 2);
    if (globalRow > 1 || (this.isSemiAnnual() && globalRow === 1)) {
      periodLength = this.isSemiAnnual() ? 0.5 : 1.0;
    } else {
      periodLength = customDays360(this.deliveryDate, this.firstCoupon) / 360;
      if (!this.isSemiAnnual() && globalRow === 1) {
        periodLength += 0.5;
      }
    }
    return R * periodLength;
  }

  // Solves for initial principal, given a constant payment each period and number of payments made
  reverseCalcStartingPrincipal(paymentEachPeriod, principalRows) {
    let startingPrincipal = 0.0;
    let futureInterest = 0.0;
    const rowCount = principalRows.length;

    for (let i = rowCount - 1; i >= 0; i--) {
      const R = this.getInterestRateForPrincipalRow(i, principalRows);
      const mod = R / principalRows[i].couponRate;
      const P = (paymentEachPeriod - futureInterest * mod) / (R + 1);
      futureInterest += P * R;
      startingPrincipal += P;
    }

    return startingPrincipal;
  }

  makeNewRow(date) {
    return {
      paymentDate: date,
      principal: null,
      couponRate: null,
      interest: 0.0,
      totalDebtService: 0.0,
      annualDebtService: null,
      fiscalYear: null,
      AAA: null,
      spread: null,
      YTC: null,
      YTM: null,
      price: 100.0,
      production: null,
    };
  }

  getTableRowsAsCsv() {
    return this.tableRows.map((row) => {
      return { ...row, paymentDate: row.paymentDate.format("MM/DD/YYYY") };
    });
  }

  calcTotalPvPayments(tic: Number): Number {
    const ticPercent = tic / 2 + 1;

    let totalPayments = 0.0;
    this.tableRows.forEach((row) => {
      const rowPvDenom = Math.pow(
        ticPercent,
        customDays360(this.deliveryDate, row.paymentDate) / 180
      );
      const rowPv = Math.pow(rowPvDenom, -1);
      totalPayments += row.totalDebtService * rowPv;
    });
    return totalPayments;
  }

  calcTicAdjustingExpensesTotal() {
    if (!this.ticAdjustingExpenses) {
      return 0;
    }
    const underwritersDiscount =
      this.parAmount *
      (this.ticAdjustingExpenses["Underwriter's Discount"] / 100);
    const bondInsurance =
      this.totalTotalDebtService *
      (this.ticAdjustingExpenses["Bond Insurance"] / 100);
    const costOfIssuance = this.ticAdjustingExpenses["Cost of Issuance"];
    return underwritersDiscount + bondInsurance + costOfIssuance;
  }

  calcDisplayTableRows() {
    this.totalCapitalizedInterest = 0.0;
    this.displayOnlyTableRows = undefined;

    if (this.capitalizedInterestDate) {
      this.displayOnlyTotalTotalDebtService = this.totalTotalDebtService;
      this.displayOnlyTotalAnnualDebtService = this.totalAnnualDebtService;
      this.displayOnlyTableRows = deepCopy(this.tableRows);

      for (const rowIndex in this.displayOnlyTableRows) {
        let row = this.displayOnlyTableRows[rowIndex];
        const previousRow = this.displayOnlyTableRows[+rowIndex - 1];
        const nextRow = this.displayOnlyTableRows[+rowIndex + 1];
        const paymentDate = moment(row.paymentDate);
        const previousPaymentDate = previousRow?.paymentDate;
        row.paymentDate = paymentDate;

        if (
          paymentDate.isBefore(this.capitalizedInterestDate) ||
          previousPaymentDate?.isBefore(this.capitalizedInterestDate)
        ) {
          // Calculate ratio of capitalized interest for the current pay period
          let ratio = 1;
          if (moment(paymentDate).isAfter(this.capitalizedInterestDate)) {
            const paymentPeriodInDays = customDays360(
              previousPaymentDate,
              paymentDate
            );
            const capitalizedInterestDays = customDays360(
              previousPaymentDate,
              this.capitalizedInterestDate
            );
            ratio = capitalizedInterestDays / paymentPeriodInDays;
          }

          // Append capitalizedInterest value
          const capitalizedInterest = ratio * row.interest;
          row.capitalizedInterest = capitalizedInterest;

          // Subtract capitalizedInterest from totalDebtService
          row.totalDebtService -= capitalizedInterest;
          this.displayOnlyTotalTotalDebtService -= capitalizedInterest;

          // Subtract capitalizedInterest from annualDebtService
          if (!row.annualDebtService && nextRow?.annualDebtService) {
            nextRow.annualDebtService -= capitalizedInterest;
          } else if (row.annualDebtService) {
            row.annualDebtService -= capitalizedInterest;
          }
          this.displayOnlyTotalAnnualDebtService -= capitalizedInterest;

          // Add capitalizedInterest to totalCapitalizedInterest
          this.totalCapitalizedInterest += capitalizedInterest;
        }
      }
    }
  }

  calcAdditionalInfo() {
    this.NIC =
      (this.parAmount + this.totalInterest - this.totalProduction) /
      this.totalBondYears;
    this.TIC = lerp(
      (x) => {
        return this.calcTotalPvPayments(x);
      },
      this.totalProduction,
      0.05
    );

    const ticAdjustingExensesTotal = this.calcTicAdjustingExpensesTotal();
    if (ticAdjustingExensesTotal > 0) {
      this.expenseAdjustedTIC =
        ticAdjustingExensesTotal <= this.totalProduction
          ? lerp(
              (x) => {
                return this.calcTotalPvPayments(x);
              },
              this.totalProduction - ticAdjustingExensesTotal,
              0.05
            )
          : "N/A";
    } else {
      this.expenseAdjustedTIC = undefined;
    }

    this.WAM =
      this.tableRows
        .filter((x) => x.principal !== null)
        .reduce(
          (total, row) =>
            total +
            (row.production *
              customDays360(this.deliveryDate, row.paymentDate)) /
              360,
          0.0
        ) / this.totalProduction;

    this.averageLife =
      this.tableRows
        .filter((x) => x.principal !== null)
        .reduce(
          (total, row) =>
            total +
            (row.principal *
              customDays360(this.deliveryDate, row.paymentDate)) /
              this.parAmount,
          0.0
        ) / 360;
  }

  getSourcesAndUses() {
    return {
      sources: [...this.sources],
      uses: [...this.uses],
    };
  }

  setSourcesAndUses(newSourcesAndUses) {
    this.sources = newSourcesAndUses.sources;
    this.uses = newSourcesAndUses.uses;
  }

  setTicAdjustingExpenses(newTicAdjustingExpenses) {
    this.ticAdjustingExpenses = newTicAdjustingExpenses;
    this.calcAdditionalInfo();
  }

  setCapitalizedInterestDate(capitalizedInterestDate) {
    this.capitalizedInterestDate = capitalizedInterestDate;
    this.buildTableData();
  }

  toNearestDenomination(num) {
    return Math.round(num / this.denomination) * this.denomination;
  }

  isSemiAnnual() {
    return this.principalFrequency === PrincipalRate.SemiAnnual;
  }

  parseEngineResponse(engineResponse) {
    let i = 0;
    let out = [];
    const firstRowDate = moment(this.firstPrincipal);
    firstRowDate.parseZone();

    // find first row
    for (; i < engineResponse.length; i++) {
      const rowDate = moment(engineResponse[i]["date"]).utc();
      if (!rowDate.isValid()) {
        console.warn(
          `engine response contains invalid date: ${engineResponse[i]["date"]}`
        );
      } else if (
        rowDate.year() === firstRowDate.year() &&
        rowDate.month() === firstRowDate.month()
      ) {
        break;
      }
    }

    // add the rest of the rows
    const rowSkip = this.isSemiAnnual() ? 6 : 12;
    for (; i < engineResponse.length; i += rowSkip) {
      out.push(engineResponse[i]);
    }
    return out;
  }

  calcAverageCoupon(principalRows) {
    if (this.useCustomCouponYield) return this.customCouponYield; // shortcut logic when coupon is constant

    const couponCount = principalRows.length;
    let total = 0.0;
    for (let i = 0; i < couponCount; i++) {
      total += principalRows[i].couponRate;
    }
    return total / couponCount;
  }

  averageAnnualDebtService() {
    return (
      (this.totalAnnualDebtService * 360) /
      customDays360(this.deliveryDate, this.finalPrincipal)
    );
  }

  rowValue(field, principalRowIndex) {
    let val: Number;
    if (this.useCustomCouponYield) {
      val = field === Field.AAA ? 0.0 : this.customCouponYield / 100.0;
    } else {
      val = this.engineResponse[principalRowIndex][field] / 100.0;
      if (field === Field.Spread && this.usePricingCushion) {
        val += this.cushion;
      }
    }
    return round(val, 4);
  }
}

export { DealBuilderService };
