import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Save,
  Edit,
  ControlPoint,
  MoreVert,
  Delete,
} from "@mui/icons-material";
import {
  deepCopy,
  round,
  dollarFormatter,
  percentFormatter,
  getPricingManagementFields,
} from "../../tools/utils";
import { DealBuilderContext } from "../../contexts/DealBuilderContext";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import FiCurveApi from "../../tools/ficurve-api";
import { ErrorContext } from "../../contexts/ErrorContext";
import { MainTabContext, TAB_NAMES } from "../../contexts/MainTabContext";
import { getDealSpreadRecords } from "../utils";
import { UserInfoContext } from "../../contexts/UserInfoContext";

const getSortedBids = (bids) =>
  bids
    .toSorted(({ rate: rate1 }, { rate: rate2 }) => rate1 - rate2)
    .map(({ rate, ...bid }) => ({
      ...bid,
      rate: round(rate / 100, 2).toString(),
    }));

const updateDeal = (dealId, inputs, accessToken) =>
  new Promise((resolve, reject) => {
    const api = new FiCurveApi(accessToken);
    api.runUpdateDeal(
      dealId,
      inputs,
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });

const updateBids = (dealId, bids, accessToken) =>
  new Promise((resolve, reject) => {
    const api = new FiCurveApi(accessToken);
    api.runSaveBidsToDeal(
      dealId,
      bids,
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });

function TransactionDetails({ borrower, issuanceTitle, securityType }) {
  return (
    <>
      {borrower && <Typography>{borrower}</Typography>}
      {issuanceTitle && (
        <Typography fontWeight={400} fontSize={12}>
          {issuanceTitle}
        </Typography>
      )}
      {securityType && (
        <Typography fontWeight={400} fontSize={12}>
          Security: {securityType}
        </Typography>
      )}
    </>
  );
}

function RatingGrade({
  saving,
  editing,
  ratingAssumption,
  tic,
  marketConditionsDate,
  bidRate,
  setBidRate,
}) {
  return (
    <Stack direction="column" sx={{ textAlign: "right" }}>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>{ratingAssumption}</Typography>
        {editing ? (
          <Stack direction="row" gap={1} alignItems="center">
            <TextField
              size="small"
              value={bidRate}
              type="number"
              onChange={({ target }) => setBidRate(target.value)}
              disabled={saving}
              sx={{ backgroundColor: "white", textAlign: "right" }}
            />
            %
          </Stack>
        ) : (
          <Typography>Bid Rate: {percentFormatter(bidRate)}</Typography>
        )}
      </Stack>
      {marketConditionsDate && tic !== 0 && (
        <Typography
          sx={{
            fontStyle: "italic",
            whiteSpace: "nowrap",
            fontWeight: 300,
          }}
        >
          Market Rate {moment(marketConditionsDate).format("M/D/YY")}:{" "}
          {percentFormatter(tic)}
        </Typography>
      )}
      {marketConditionsDate && tic === 0 && (
        <Typography
          color="error"
          sx={{ fontStyle: "italic", whiteSpace: "nowrap" }}
        >
          Error: Unable to calculate market rate
        </Typography>
      )}
    </Stack>
  );
}

function Actions({
  status,
  saving,
  editing,
  bidsOpen,
  setEditing,
  setBidsOpen,
  handleOpenInDealBuilder,
  handleDeleteDeal,
  handleDuplicateDeal,
  handleSaveDeal,
  handleReassignDeal,
  handleStatusChange,
}) {
  const [anchorEl, setAnchorEl] = useState();
  const { userInfo } = useContext(UserInfoContext);

  return (
    <>
      <Stack gap={1} direction="row">
        {editing ? (
          <Button
            variant="contained"
            startIcon={
              saving ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                <Save />
              )
            }
            onClick={handleSaveDeal}
            sx={{
              "& .MuiButton-startIcon": { mx: 0 },
              minWidth: 36,
              px: 0,
            }}
          />
        ) : (
          <Button
            variant="outlined"
            startIcon={<Edit />}
            onClick={() => {
              setEditing(true);
              setBidsOpen(true); // auto-expand bids on edit
            }}
            sx={{
              "& .MuiButton-startIcon": { mx: 0 },
              minWidth: 36,
              px: 0,
              boxShadow: 2,
              backgroundColor: "white",
            }}
          />
        )}
        <Button
          id="more-actions"
          variant="outlined"
          startIcon={<MoreVert />}
          aria-expanded={!!anchorEl ? "true" : undefined}
          aria-haspopup="true"
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          sx={{
            "& .MuiButton-startIcon": { mx: 0 },
            minWidth: 36,
            px: 0,
            boxShadow: 2,
            backgroundColor: "white",
          }}
        />
        <Button
          id="toggle-bids"
          onClick={() => setBidsOpen(!bidsOpen)}
          sx={{ whiteSpace: "nowrap" }}
        >
          {bidsOpen ? "Hide" : "Show"} Bids
        </Button>

        <Select
          value={status}
          onChange={({ target }) => handleStatusChange(target.value)}
          size="small"
          disabled={saving}
          sx={{ backgroundColor: "white" }}
        >
          <MenuItem value="Pending">Pending Bid</MenuItem>
          <MenuItem value="Won">Won</MenuItem>
          <MenuItem value="Closed">Closed</MenuItem>
          <MenuItem value="Lost">Lost</MenuItem>
        </Select>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        MenuListProps={{
          "aria-labelledby": "more-actions",
        }}
        onClose={() => setAnchorEl()}
      >
        <MenuItem
          onClick={() => {
            handleOpenInDealBuilder();
            setAnchorEl();
          }}
        >
          View / Edit in Deal Builder
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDuplicateDeal();
            setAnchorEl();
          }}
        >
          Duplicate Deal
        </MenuItem>
        {!!userInfo?.team && (
          <MenuItem
            onClick={() => {
              handleReassignDeal();
              setAnchorEl();
            }}
          >
            Reassign Deal
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleDeleteDeal();
            setAnchorEl();
          }}
          sx={{ color: "#d32f2f" }}
        >
          <Delete color="error" /> Delete Deal
        </MenuItem>
      </Menu>
    </>
  );
}

function BidsTable({
  bidsOpen,
  editing,
  saving,
  bids,
  advisor,
  spreads,
  handleAddBid,
  handleRemoveBid,
  handleBidValueChange,
  handleAdvisorChange,
  isFinalRow,
}) {
  if (!bidsOpen) {
    return (
      <TableRow
        sx={{
          "& > .MuiTableCell-root": {
            py: 2,
            ...(isFinalRow ? { borderBottom: "unset" } : {}),
          },
        }}
      >
        <TableCell colSpan={12} />
      </TableRow>
    );
  }
  return (
    <>
      <TableRow
        sx={{
          "& > .MuiTableCell-root": {
            pt: 2,
            borderBottom: "unset",
          },
        }}
      >
        <TableCell colSpan={8} />
        {editing ? (
          <TableCell colSpan={4} />
        ) : (
          <>
            <TableCell
              colSpan={2}
              align="center"
              sx={{ textDecoration: "underline", fontWeight: "bold" }}
            >
              Tax-Exempt Spreads
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              sx={{ textDecoration: "underline", fontWeight: "bold" }}
            >
              Taxable Spreads
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow
        sx={{
          "& > .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell colSpan={2} />
        {editing ? (
          <>
            <TableCell colSpan={10} />
          </>
        ) : (
          <>
            <TableCell colSpan={3} sx={{ textDecoration: "underline" }}>
              Transaction Parties
            </TableCell>
            <TableCell colSpan={3} />
            <TableCell align="center" sx={{ textDecoration: "underline" }}>
              fiCurve
            </TableCell>
            <TableCell align="center" sx={{ textDecoration: "underline" }}>
              AAA
            </TableCell>
            <TableCell align="center" sx={{ textDecoration: "underline" }}>
              UST
            </TableCell>
            <TableCell align="center" sx={{ textDecoration: "underline" }}>
              FHLB
            </TableCell>
          </>
        )}
      </TableRow>
      {bids.map((bid, index) => (
        <TableRow
          key={bid.id || bid.tempId}
          sx={{
            "& > .MuiTableCell-root": {
              borderBottom: "unset",
              py: 0.5,
            },
            "& > :first-of-type": {
              pr: 1,
              pl: 0,
            },
          }}
        >
          {index === 0 ? (
            <>
              <TableCell colSpan={2} />
              <TableCell colSpan={3}>
                {editing ? (
                  <TextField
                    label="Advisor"
                    size="small"
                    value={advisor}
                    disabled={saving}
                    onChange={({ target }) => handleAdvisorChange(target.value)}
                  />
                ) : (
                  `Advisor: ${advisor || "N/A"}`
                )}
              </TableCell>
              <TableCell />
            </>
          ) : (
            <TableCell colSpan={6} />
          )}
          <TableCell
            sx={{ fontWeight: index === 0 ? "bold" : 400, pl: 1, pr: 0 }}
          >
            {editing ? (
              <TextField
                placeholder="Bid name"
                size="small"
                value={bid.name}
                disabled={saving || index === 0}
                onChange={({ target }) =>
                  handleBidValueChange({
                    index,
                    value: target.value,
                    key: "name",
                  })
                }
              />
            ) : (
              bid.name
            )}
          </TableCell>
          <TableCell
            align="right"
            sx={{ fontWeight: index === 0 ? "bold" : 400 }}
          >
            {editing ? (
              <Stack direction="row" alignItems="center">
                <TextField
                  placeholder="Bid rate"
                  size="small"
                  value={bid.rate}
                  type="number"
                  disabled={saving}
                  onChange={({ target }) =>
                    handleBidValueChange({
                      index,
                      value: target.value,
                      key: "rate",
                    })
                  }
                />
                <IconButton
                  disableRipple
                  disabled={index === 0}
                  onClick={() => handleRemoveBid({ index })}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Stack>
            ) : (
              percentFormatter(bid.rate)
            )}
          </TableCell>
          {editing ? (
            <TableCell colSpan={6} />
          ) : (
            <>
              <TableCell align="center">{spreads[index].fiCurve}</TableCell>
              <TableCell align="center">{spreads[index].AAA}</TableCell>
              <TableCell align="center">{spreads[index].UST}</TableCell>
              <TableCell align="center">{spreads[index].FHLB}</TableCell>
            </>
          )}
        </TableRow>
      ))}
      <TableRow
        sx={{
          "& > .MuiTableCell-root": {
            pb: 2,
            ...(isFinalRow ? { borderBottom: "unset" } : {}),
          },
        }}
      >
        <TableCell colSpan={6} />
        <TableCell colSpan={6}>
          {editing && (
            <Button startIcon={<ControlPoint />} onClick={handleAddBid}>
              ADD NEW BID
            </Button>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

function PricingManagementTableRow({
  deal,
  setDeal,
  setSelectedDealID,
  handleDuplicateDeal,
  handleReassignDeal,
  AAAData,
  USTData,
  FHLBData,
  isFinalRow,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { setActiveError } = useContext(ErrorContext);
  const { setActiveTab } = useContext(MainTabContext);
  const { handleOpenDeal } = useContext(DealBuilderContext);
  const [bids, setBids] = useState(getSortedBids(deal.bids || []));
  const [bidsOpen, setBidsOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [advisor, setAdvisor] = useState(deal.advisor);
  const [status, setStatus] = useState(deal.status);
  const [bidDate, setBidDate] = useState(deal.bidDate);
  const [deliveryDate, setDeliveryDate] = useState(deal.deliveryDate);
  const [bidRate, setBidRate] = useState(
    round((deal.bidRate || 400) / 100, 2).toString()
  );
  const [saving, setSaving] = useState(false);
  const displayBids = useMemo(() => {
    return [{ name: "YOUR BID", rate: bidRate, tempId: "YOUR BID" }, ...bids];
  }, [bidRate, bids]);

  const handleSave = async () => {
    if (bidRate <= 0 || bids.some(({ rate }) => Number(rate) <= 0)) {
      setActiveError("Bid rates must be 0.01% or above");
      return;
    }
    try {
      setSaving(true);
      const bidsToSave = bids.map(({ rate, ...bid }) => ({
        ...bid,
        rate: round(Number(rate) || 0, 2) * 100,
      }));
      const bidRateToSave = round(Number(bidRate) || 4, 2) * 100;
      const bidDateToSave = moment(bidDate).unix();
      const deliveryDateToSave = moment(deliveryDate).unix();
      const accessToken = await getAccessTokenSilently();
      const updatedDeal = await updateDeal(
        deal.id,
        {
          advisor,
          bidRate: bidRateToSave,
          bidDate: bidDateToSave,
          deliveryDate: deliveryDateToSave,
          status,
        },
        accessToken
      );
      const returnedBids = await updateBids(deal.id, bidsToSave, accessToken);
      setDeal({
        ...updatedDeal,
        ...getPricingManagementFields({
          ...updatedDeal,
          pricing: deal.pricing,
        }),
        pricing: deal.pricing,
        bids: returnedBids.toSorted(
          ({ rate: rate1 }, { rate: rate2 }) => rate1 - rate2
        ),
      });
      setBidRate(bidRate || 4);
      setBids(getSortedBids(returnedBids));
      setEditing(false);
    } catch (err) {
      console.error(err);
      setActiveError(
        `There was an error communicating with the API: ${err.message ?? err}`
      );
    } finally {
      setSaving(false);
    }
  };

  const handleSaveStatus = async (newStatus) => {
    try {
      setSaving(true);
      const accessToken = await getAccessTokenSilently();
      const updatedDeal = await updateDeal(
        deal.id,
        { status: newStatus },
        accessToken
      );
      setDeal({
        ...updatedDeal,
        ...getPricingManagementFields({
          ...updatedDeal,
          pricing: deal.pricing,
        }),
        pricing: deal.pricing,
        bids: deal.bids,
      });
      setStatus(newStatus);
      setEditing(false);
    } catch (err) {
      console.error(err);
      setActiveError(
        `There was an error communicating with the API: ${err.message ?? err}`
      );
    } finally {
      setSaving(false);
    }
  };

  const handleAddBid = () =>
    setBids([...bids, { tempId: Date.now(), name: "", rate: "0.00" }]);

  const handleBidValueChange = ({ index, key, value }) => {
    if (index) {
      const indexWithoutYourBid = index - 1;
      const newBids = deepCopy(bids);
      newBids[indexWithoutYourBid][key] = value;
      setBids(newBids);
    } else {
      setBidRate(value);
    }
  };

  const handleRemoveBid = ({ index }) => {
    const indexWithoutYourBid = index - 1;
    const newBids = deepCopy(bids);
    setBids(newBids.filter((_, i) => indexWithoutYourBid !== i));
  };

  const handleViewDeal = () => {
    handleOpenDeal(deal.id, deal);
    setActiveTab(TAB_NAMES.DEAL_BUILDER);
  };

  const spreads = useMemo(() => {
    if (deal.tic === 0) {
      return displayBids.map(() => ({
        fiCurve: "N/A",
        AAA: "N/A",
        UST: "N/A",
        FHLB: "N/A",
      }));
    }
    const { matchingAAARate, matchingUSTRate, matchingFHLBRate } =
      getDealSpreadRecords({
        deal,
        bids: displayBids,
        AAAData,
        USTData,
        FHLBData,
      });
    return [
      "Bank Qualified",
      "Non-Bank Qualified",
      "Alternative Minimum Tax",
    ].includes(deal.taxDesignation)
      ? displayBids.map(({ rate }) => ({
          fiCurve: percentFormatter(rate - deal.tic),
          AAA: matchingAAARate
            ? percentFormatter(rate - matchingAAARate)
            : "N/A",
          UST: matchingUSTRate
            ? percentFormatter(rate / 0.79 - matchingUSTRate)
            : "N/A",
          FHLB: matchingFHLBRate
            ? percentFormatter(rate / 0.79 - matchingFHLBRate)
            : "N/A",
        }))
      : displayBids.map(({ rate }) => ({
          fiCurve: "N/A",
          AAA: "N/A",
          UST: matchingUSTRate
            ? percentFormatter(rate - matchingUSTRate)
            : "N/A",
          FHLB: matchingFHLBRate
            ? percentFormatter(rate - matchingFHLBRate)
            : "N/A",
        }));
  }, [deal, displayBids, AAAData, USTData, FHLBData]);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: "#F6F6F6",
          "& > .MuiTableCell-root": { verticalAlign: "top", py: 0.5 },
        }}
      >
        <TableCell>
          {editing ? (
            <DatePicker
              inputFormat="MM/DD/YYYY"
              value={bidDate}
              onChange={(e) => setBidDate(e)}
              disabled={saving}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ backgroundColor: "white", minWidth: 150 }}
                  {...params}
                />
              )}
            />
          ) : (
            <Typography>{moment(bidDate).format("MMMM D, YYYY")}</Typography>
          )}
        </TableCell>
        <TableCell>
          {editing ? (
            <DatePicker
              inputFormat="MM/DD/YYYY"
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e)}
              disabled={saving}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ backgroundColor: "white", minWidth: 150 }}
                  {...params}
                />
              )}
            />
          ) : (
            <Typography>
              {moment(deliveryDate).format("MMMM D, YYYY")}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <TransactionDetails
            borrower={deal.borrower}
            issuanceTitle={deal.issuanceTitle}
            securityType={deal.securityType}
          />
        </TableCell>
        <TableCell>
          <Typography>{deal.stateOfIssuance}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{deal.taxDesignation}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{round(deal.savedDealBuilder.averageLife, 2)}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{dollarFormatter.format(deal.parAmount)}</Typography>
        </TableCell>
        <TableCell>
          <RatingGrade
            saving={saving}
            editing={editing}
            tic={deal.tic}
            ratingAssumption={deal.ratingAssumption}
            marketConditionsDate={deal.marketConditionsDate}
            bidRate={bidRate}
            setBidRate={setBidRate}
          />
        </TableCell>
        <TableCell colSpan={2}>
          <Typography>
            Variance: {dollarFormatter.format(deal.variance)}
          </Typography>
        </TableCell>
        <TableCell colSpan={2}>
          <Actions
            status={status}
            bidsOpen={bidsOpen}
            setBidsOpen={setBidsOpen}
            saving={saving}
            editing={editing}
            setEditing={setEditing}
            handleDeleteDeal={() => setSelectedDealID(deal.id)}
            handleDuplicateDeal={() => handleDuplicateDeal(deal.id)}
            handleReassignDeal={() => handleReassignDeal(deal)}
            handleOpenInDealBuilder={() => handleViewDeal()}
            handleSaveDeal={() => handleSave()}
            handleStatusChange={handleSaveStatus}
          />
        </TableCell>
      </TableRow>
      <BidsTable
        bidsOpen={bidsOpen}
        bids={displayBids}
        advisor={advisor}
        editing={editing}
        saving={saving}
        spreads={spreads}
        handleAddBid={handleAddBid}
        handleRemoveBid={handleRemoveBid}
        handleBidValueChange={handleBidValueChange}
        handleAdvisorChange={setAdvisor}
        isFinalRow={isFinalRow}
      />
    </>
  );
}

export default PricingManagementTableRow;
