import { useCallback, useEffect, useMemo, useState } from "react";
import useMunicipalMarketRawData from "./useMunicipalMarketRawData";
import { PrincipalRate, States } from "../../constants";
import { FiCurveInput } from "../../inputs/inputs";
import moment from "moment";
import { DealBuilderService } from "../../deal-builder/deal-builder-service";

const usedMaturities = [5, 10, 15, 20, 25, 30];
const usedRatingAssumptions = ["AAA", "AA", "A", "BBB"];

function parseDealBuildResponse(data) {
  return Object.keys(data.date).map((indexKey) => {
    return Object.keys(data).reduce((result, key) => {
      result[key] = data[key][indexKey];
      return result;
    }, {});
  });
}

function groupByTaxDesignation(dataRows) {
  return dataRows?.reduce((result, item) => {
    if (!result[item[8]]?.length) {
      result[item[8]] = [item];
    } else {
      result[item[8]].push(item);
    }
    return result;
  }, {});
}

export default function useMunicipalMarketData(taxDesignation) {
  const { rawData, loading } = useMunicipalMarketRawData();
  const dataRows = rawData.slice(1, -1);
  const dataGroupByTaxDesignation = groupByTaxDesignation(dataRows);
  const [data, setData] = useState({});

  const defaultDealInputs = useMemo(
    () => ({
      stateOfIssuance: new FiCurveInput("State of Issuance", [States.NY]),
      securityType: new FiCurveInput("Security Type", [
        "Unlimited Tax General Obligation",
      ]),
      taxDesignation: new FiCurveInput("Tax Designation", [taxDesignation]),
      stateEnhancement: new FiCurveInput("State Enhancement", [false], {}),
      bondInsurance: new FiCurveInput("Bond Insurance", [false], {}),
      marketConditionsDate: new FiCurveInput("Date of Market Conditions", [
        moment(dataRows[2]),
      ]),
      parAmount: new FiCurveInput("Par Amount", [10000000], {}),
      denomination: new FiCurveInput("Minimum Denomination", [5000], {}),
      principalFrequency: new FiCurveInput("Principal Frequency", [
        PrincipalRate.Annual,
      ]),
    }),
    [taxDesignation, dataRows]
  );

  const getData = useCallback(() => {
    if (Object.keys(dataGroupByTaxDesignation).length) {
      return usedMaturities?.reduce((result, maturity, index) => {
        let dealBuild = [];
        usedRatingAssumptions?.forEach((rate) => {
          dealBuild = dataGroupByTaxDesignation[taxDesignation].filter(
            (data) =>
              [5, 10].includes(maturity)
                ? !data[1] && data[4] === rate // no call date
                : data[1] && data[4] === rate // has call date
          )[0][9];
          // set inputs
          dataGroupByTaxDesignation[taxDesignation].forEach((data) => {
            const callDate = new FiCurveInput(
              "Call Date",
              [data[1] ? moment(data[1]) : moment("2028-12-01")],
              {}
            );
            const useCallDate = new FiCurveInput(
              "Use Call Date",
              [data[1] ? true : false],
              {}
            );
            const firstCoupon = new FiCurveInput(
              "First Coupon",
              [moment(data[2]).add(6, "month")],
              {}
            );
            const firstPrincipal = new FiCurveInput(
              "First Principal",
              [moment(data[2]).add(12, "month")],
              {}
            );
            const finalPrincipal = new FiCurveInput(
              "Final Principal",
              [moment(data[2]).add(maturity, "year")],
              {}
            );
            const ratingAssumption = new FiCurveInput(
              "Rating Assumption",
              [rate],
              {}
            );
            const deliveryDate = new FiCurveInput(
              "Delivery Date",
              [moment(data[2])],
              {}
            );

            defaultDealInputs["callDate"] = callDate;
            defaultDealInputs["useCallDate"] = useCallDate;
            defaultDealInputs["firstCoupon"] = firstCoupon;
            defaultDealInputs["firstPrincipal"] = firstPrincipal;
            defaultDealInputs["finalPrincipal"] = finalPrincipal;
            defaultDealInputs["ratingAssumption"] = ratingAssumption;
            defaultDealInputs["deliveryDate"] = deliveryDate;
          });

          // calc TIC
          const dealBuildResult = new DealBuilderService(
            defaultDealInputs,
            undefined,
            parseDealBuildResponse(JSON.parse(dealBuild))
          );

          result[maturity] = {
            ...result?.[maturity],
            TIC: {
              ...result[maturity]?.TIC,
              [rate]: dealBuildResult.TIC,
            },
          };
        });
        return result;
      }, {});
    }
  }, [dataGroupByTaxDesignation, defaultDealInputs, taxDesignation]);

  useEffect(() => {
    setData({
      ...data,
      ...getData(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, taxDesignation]);

  return { data, loading, referenceDate: dataRows?.[0]?.[2] };
}
