import React from "react";
import logo from "../images/main-logo-app.png";
import logoLogin from "../images/main-logo-login.png";
import {Box} from "@mui/material";

function FiCurveLogo(props) {
    const { sx } = props
    const isLogin = props["isLogin"] ?? false;
    return <Box component={"img"}
         src={isLogin ? logoLogin : logo}
         sx={sx}
    />
}

export default FiCurveLogo