import { FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export const getTableComponents = (dataFields) => ({
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  FillerRow: ({ height }) => (
    <TableRow>
      <TableCell sx={{ height }} colSpan={dataFields.length} />
    </TableRow>
  ),
});

export const CellContents = ({
  padding,
  width,
  children,
  align,
  color,
  colSpan,
  border,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderBottomLeftRadius,
}) => (
  <TableCell
    sx={{
      padding,
      maxWidth: width,
      minWidth: width,
      border,
      borderTop,
      borderBottom,
      borderLeft,
      borderRight,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderBottomLeftRadius,
    }}
    colSpan={colSpan || 1}
  >
    <Typography fontSize={10} textAlign={align} color={color}>
      {children}
    </Typography>
  </TableCell>
);

const FilterMenu = ({
  filterType,
  inputProps,
  saveAndClose,
  filterValue,
  setFilterValue,
  secondFilterValue,
  setSecondFilterValue,
}) => {
  switch (filterType) {
    case "number-range":
      return (
        <Stack direction="row" gap={0.5}>
          <TextField
            autoFocus
            label="Minimum"
            type="number"
            value={filterValue}
            onChange={({ target }) => setFilterValue(target.value)}
            onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
            size="small"
            InputProps={inputProps}
            sx={{
              backgroundColor: "white",
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
              },
            }}
          />
          <TextField
            label="Maximum"
            type="number"
            value={secondFilterValue}
            onChange={({ target }) => setSecondFilterValue(target.value)}
            onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
            size="small"
            InputProps={inputProps}
            sx={{
              backgroundColor: "white",
              "& ::-webkit-inner-spin-button, & ::-webkit-outer-spin-button": {
                display: "none",
              },
            }}
          />
        </Stack>
      );

    case "date-range":
      return (
        <Stack direction="row" gap={0.5}>
          <DatePicker
            id="minimum-date-field"
            label="Minimum"
            inputFormat="MM/DD/YYYY"
            value={filterValue}
            onChange={(value) => setFilterValue(value)}
            renderInput={(params) => (
              <TextField
                size="small"
                autoFocus
                sx={{ backgroundColor: "white" }}
                onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
                InputProps={inputProps}
                {...params}
              />
            )}
          />
          <DatePicker
            id="maximum-date-field"
            label="Maximum"
            inputFormat="MM/DD/YYYY"
            value={secondFilterValue}
            onChange={(value) => setSecondFilterValue(value)}
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{ backgroundColor: "white" }}
                onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
                InputProps={inputProps}
                {...params}
              />
            )}
          />
        </Stack>
      );

    case "text-contains":
    case "text-exact":
    default:
      return (
        <TextField
          autoFocus
          label="Search by"
          value={filterValue}
          onChange={({ target }) => setFilterValue(target.value)}
          onKeyDown={({ key }) => key === "Enter" && saveAndClose()}
          InputProps={inputProps}
          size="small"
          sx={{ backgroundColor: "white" }}
        />
      );
  }
};

const getDefaultFilterValue = (filterType) => {
  switch (filterType) {
    case "number-range":
      return 0;

    case "date-range":
      return moment();

    case "text-contains":
    case "text-exact":
    default:
      return "";
  }
};

export const Header = ({
  borderColor,
  dataName,
  tableName,
  width,
  align,
  colSpan,
  filterType,
  filters,
  addOrUpdateFilter,
  removeFilter,
  inputProps,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const [filterValue, setFilterValue] = useState(
    getDefaultFilterValue(filterType)
  );
  const [secondFilterValue, setSecondFilterValue] = useState(
    getDefaultFilterValue(filterType)
  );
  const filter = useMemo(
    () => filters.find((f) => f.column === dataName),
    [filters, dataName]
  );

  useEffect(() => {
    // Reload filter input state for saved filters
    if (filter) {
      setFilterValue(filter.value);
      setSecondFilterValue(filter.secondValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveAndClose = useCallback(() => {
    setAnchorEl();
    if (filterValue !== "") {
      addOrUpdateFilter({
        column: dataName,
        value: filterValue,
        secondValue: secondFilterValue,
        filterType,
      });
    }
  }, [filterValue, secondFilterValue, dataName, filterType, addOrUpdateFilter]);

  const onClickAway = useCallback(() => {
    if (anchorEl) {
      saveAndClose();
    }
  }, [anchorEl, saveAndClose]);

  const toggleAnchor = useCallback(
    ({ currentTarget }) => {
      if (anchorEl) {
        saveAndClose();
      } else {
        setAnchorEl(currentTarget);
      }
    },
    [anchorEl, saveAndClose]
  );

  const filterLabel = useMemo(() => {
    if (!filter) return "";
    switch (filterType) {
      case "number-range":
        return `${filter.value} - ${filter.secondValue}`;

      case "date-range":
        return `${moment(filter.value).format("MM/DD/YYYY")} - ${moment(
          filter.secondValue
        ).format("MM/DD/YYYY")}`;

      case "text-contains":
      case "text-exact":
      default:
        return filter.value;
    }
  }, [filter, filterType]);

  return (
    <TableCell
      sx={{
        maxWidth: !!filter ? width + 80 : width,
        minWidth: !!filter ? width + 80 : width,
        borderColor,
      }}
      colSpan={colSpan || 1}
    >
      <Box flexDirection="row" display="flex" alignItems="center" gap={1}>
        <Typography fontWeight="bold" fontSize={10} textAlign={align} flex={1}>
          {tableName || dataName}
        </Typography>
        {filterType && (
          <ClickAwayListener onClickAway={onClickAway} mouseEvent="onMouseDown">
            <div>
              {/* root div is necessary for the click away listener */}
              <Button
                id="filter-menu"
                variant="text"
                startIcon={<FilterList />}
                aria-expanded={!!anchorEl ? "true" : undefined}
                aria-haspopup="true"
                onClick={toggleAnchor}
                className={anchorEl ? "filter-menu-open" : undefined}
                sx={{
                  "& .MuiButton-startIcon": { mx: 0 },
                  minWidth: 24,
                  py: 0.3,
                  px: 0,
                  backgroundColor: "white",
                  transition: "transform 0.1s linear",
                  transform: "rotate(0deg)",
                  "&.filter-menu-open": {
                    transform: "rotate(-90deg)",
                  },
                }}
              />
              <Popper
                open={!!anchorEl}
                anchorEl={anchorEl}
                sx={{ zIndex: 2 }}
                placement="right"
              >
                <FilterMenu
                  filterType={filterType}
                  inputProps={inputProps}
                  saveAndClose={saveAndClose}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  secondFilterValue={secondFilterValue}
                  setSecondFilterValue={setSecondFilterValue}
                />
              </Popper>
            </div>
          </ClickAwayListener>
        )}
        {!!filter && (
          <Chip
            label={filterLabel}
            onDelete={() => removeFilter(dataName)}
            size="small"
            variant="outlined"
            sx={{ maxWidth: 80 }}
          />
        )}
      </Box>
    </TableCell>
  );
};
