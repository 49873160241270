import { createContext, useContext, useState } from "react";
import { ErrorContext } from "./ErrorContext";

export const TAB_NAMES = {
  MARKET_MONITOR: "MarketMonitor",
  NATIONAL_PRIVATE_PLACEMENTS: "NationalPrivatePlacements",
  DEAL_BUILDER: "DealBuilder",
  DRAFT_TRANSACTIONS: "DraftTransactions",
  PRICING_MANAGEMENT: "PricingManagement",
};

export const MainTabContext = createContext({
  activeTab: TAB_NAMES.MARKET_MONITOR,
  setActiveTab: () => {},
});

export function MainTabProvider({ children }) {
  const { setActiveError } = useContext(ErrorContext);
  const [activeTab, setActiveTab] = useState(TAB_NAMES.MARKET_MONITOR);

  const updateActiveTab = (value) => {
    if (activeTab !== value) {
      setActiveError(null);
    }
    setActiveTab(value);
  };

  return (
    <MainTabContext.Provider
      value={{ activeTab, setActiveTab: updateActiveTab }}
    >
      {children}
    </MainTabContext.Provider>
  );
}
