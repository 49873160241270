import React from "react";
import { Box } from "@mui/material";
import moment from "moment";
import formatter from "../../tools/formatter";

function ReportPageHeader(props) {
  const { dealBuilder: db, title } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="center"
      gap={1}
      mb={3}
    >
      <h3>{title}</h3>
      {!!db.borrower && <Box>{db.borrower}</Box>}
      {!!db.issuanceTitle && <Box>{db.issuanceTitle}</Box>}
      <Box>Dated: {moment(db.deliveryDate).format("MM/DD/YYYY")}</Box>
      {!db.useCustomCouponYield && (
        <Box>
          ({db.ratingAssumption} Rating / {db.taxDesignation})
        </Box>
      )}

      {db.shouldUseCallDate() && (
        <Box>Call Option: {moment(db.callDate).format("MM/DD/YYYY")}</Box>
      )}
      <Box>
        Date of Market Conditions:{" "}
        {moment(db.marketConditionsDate).format("MM/DD/YYYY")}
        {db.usePricingCushion && (
          <strong> ({formatter.percent(db.cushion)} Pricing Cushion)</strong>
        )}
      </Box>
    </Box>
  );
}

export default ReportPageHeader;
