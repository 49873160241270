import { createContext, useRef, useState } from "react";

export const ErrorContext = createContext({
  activeError: null,
  setActiveError: () => {},
});

export function ErrorProvider({ children }) {
  const timeoutRef = useRef();
  const [activeError, setActiveError] = useState(null);

  const updateActiveError = (value, timeout = 5000) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setActiveError(value);

    if (timeout) {
      timeoutRef.current = setTimeout(() => {
        setActiveError(null);
      }, timeout);
    }
  };

  return (
    <ErrorContext.Provider
      value={{ activeError, setActiveError: updateActiveError }}
    >
      {children}
    </ErrorContext.Provider>
  );
}
