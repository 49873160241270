import { useMemo, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Chart,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { FiCurveInput } from "../../inputs/inputs";
import TripleABenchmark from "./triple-a-benchmark";
import USTreasuryYield from "./us-treasury-yield";
import useAAABenchmarkData from "./useAAABenchmarkData";
import useUSTreasuryData from "./useUSTreasuryData";
import useFHLBRawData from "../../pricing-management/useFHLBRawData";

Chart.register(
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const COLORS = {
  AAA: {
    borderColor: "rgb(155, 168, 232)",
    backgroundColor: "rgb(155, 168, 232, 0.5)",
  },
  UST: {
    borderColor: "rgb(0, 68, 104)",
    backgroundColor: "rgb(0, 68, 104, 0.5)",
  },
  FHLB: {
    borderColor: "rgb(64, 138, 127)",
    backgroundColor: "rgb(64, 138, 127, 0.5)",
  },
};

export default function NationalBenchMarks({
  handleInputUpdate,
  savedInputValues,
}) {
  const taxRate = new FiCurveInput(
    "",
    useState(savedInputValues?.taxRate ?? 21),
    {
      validations: [(val) => (isNaN(val) ? "Tax Rate is invalid" : null)],
      setValueCallback: (value) => handleInputUpdate({ key: "taxRate", value }),
    }
  );

  const {
    data: AAAData,
    newestRawData: rawAAAData,
    referenceDate: AAADate,
    loading: AAALoading,
  } = useAAABenchmarkData({
    taxRate: taxRate.getValue(),
  });
  const {
    data: treasuryData,
    newestRawData: rawTreasuryData,
    referenceDate: treasuryDate,
    loading: treasuryLoading,
  } = useUSTreasuryData();
  const { rawData: rawFHLBData, loading: FHLBLoading } = useFHLBRawData();

  const chartData = useMemo(() => {
    // Populate Treasury data
    let treasuryDataInnerIndex = 0;
    const treasuryChartData = rawTreasuryData.length
      ? new Array(30).fill(null).map((_, index) => {
          if ([1, 2, 3, 5, 7, 10, 20, 30].includes(index + 1)) {
            return +rawTreasuryData[++treasuryDataInnerIndex + 4];
          }
          return null;
        })
      : [];

    // Populate AAA data
    const AAAChartData = rawAAAData
      .toSpliced(0, 1)
      .reduce((data, value) => [...data, +value], []);

    // Populate FHLB data
    const newestFHLBData = rawFHLBData?.length
      ? rawFHLBData
          .slice(1)
          .filter((value) => value.length !== 1)
          .reduce((a, b) => {
            return new Date(a[0]) > new Date(b[0]) ? a : b;
          })
      : [];
    const FHLBChartData = newestFHLBData
      .toSpliced(0, 1)
      .reduce((data, value) => [...data, +value], []);

    return {
      labels: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      datasets: [
        {
          label: "AAA",
          data: AAAChartData,
          ...COLORS["AAA"],
        },
        {
          label: "Treasury",
          data: treasuryChartData,
          ...COLORS["UST"],
        },
        {
          label: "FHLB",
          data: FHLBChartData,
          ...COLORS["FHLB"],
        },
      ],
    };
  }, [rawAAAData, rawTreasuryData, rawFHLBData]);

  return (
    <Box>
      <Box mb={1}>
        <Typography textAlign="center" fontWeight="bold">
          National Benchmarks
        </Typography>
      </Box>
      <Divider />
      <Grid container spacing={4} pt={2}>
        <Grid item flexGrow={1}>
          <TripleABenchmark
            taxRate={taxRate}
            data={AAAData}
            loading={AAALoading}
            referenceDate={AAADate}
          />
        </Grid>

        <Grid item flexGrow={1}>
          <USTreasuryYield
            data={treasuryData}
            loading={treasuryLoading}
            referenceDate={treasuryDate}
          />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 350,
            }}
          >
            {treasuryLoading || AAALoading || FHLBLoading ? (
              <CircularProgress />
            ) : (
              <Line
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  spanGaps: true,
                  scales: {
                    y: {
                      title: { text: "Yield", display: true },
                      ticks: {
                        callback: (v) => `${v.toFixed(2)}%`,
                      },
                    },
                    x: { title: { text: "Maturity", display: true } },
                  },
                  plugins: {
                    colors: {
                      enabled: true,
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) =>
                          `${context.dataset.label}: ${context.raw.toFixed(
                            2
                          )}%`,
                        title: ([context]) =>
                          `Maturity: ${context.label} ${
                            context.label === "1" ? "Yr" : "Yrs"
                          }`,
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            * Copyright 2023, S&P Global Market Intelligence. Reproduction of
            any information, data or material, including ratings (“Content”) in
            any form is prohibited except with the prior written permission of
            the relevant party. Such party, its affiliates and suppliers
            (“Content Providers”) do not guarantee the accuracy, adequacy,
            completeness, timeliness or availability of any Content and are not
            responsible for any errors or omissions (negligent or otherwise),
            regardless of the cause, or for the results obtained from the use of
            such Content. In no event shall Content Providers be liable for any
            damages, costs, expenses, legal fees, or losses (including lost
            income or lost profit and opportunity costs) in connection with any
            use of the Content. A reference to a particular investment or
            security, a rating or any observation concerning an investment that
            is part of the Content is not a recommendation to buy, sell or hold
            such investment or security, does not address the suitability of an
            investment or security and should not be relied on as investment
            advice. Credit ratings are statements of opinions and are not
            statements of fact.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
