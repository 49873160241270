import React, { useMemo, useState } from "react";
import moment from "moment";

import { utils, writeFileXLSX } from "xlsx";
import { TableVirtuoso } from "react-virtuoso";
import {
  Box,
  CircularProgress,
  TableRow,
  Typography,
  Button,
  Stack,
  InputAdornment,
} from "@mui/material";

import useNationalPrivatePlacements from "./useNationalPrivatePlacements";
import { dollarFormatter } from "../tools/utils";
import {
  getTableComponents,
  CellContents,
  Header,
} from "../components/TableComponents";

const dataFields = [
  { dataName: "State", width: 30, align: "center", filterType: "text-exact" },
  {
    dataName: "Issuer",
    width: 125,
    align: "left",
    filterType: "text-contains",
  },
  { dataName: "Issue", width: 200, align: "left", filterType: "text-contains" },
  {
    dataName: "Final Maturity (Date)",
    width: 60,
    align: "right",
    filterType: "date-range",
  },
  {
    dataName: "Final Maturity (Years)",
    width: 60,
    align: "right",
    filterType: "number-range",
  },
  {
    dataName: "Par Amount",
    width: 60,
    align: "right",
    filterType: "number-range",
  },
  {
    dataName: "Dated Date",
    width: 60,
    align: "right",
    filterType: "date-range",
  },
  {
    dataName: "Proxy Pricing Date",
    width: 60,
    align: "right",
  },
  {
    dataName: "Tax Designation",
    width: 80,
    align: "center",
    filterType: "text-contains",
  },
  {
    dataName: "Security Code",
    width: 65,
    align: "center",
    filterType: "text-contains",
  },
  {
    dataName: "Coupon",
    width: 40,
    align: "center",
    filterType: "number-range",
    inputProps: {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    },
  },
  {
    dataName: "Purchaser",
    width: 80,
    align: "left",
    filterType: "text-contains",
  },
  {
    dataName: "Advisor",
    width: 80,
    align: "left",
    filterType: "text-contains",
  },
];

const TableComponents = getTableComponents(dataFields);

const getTableRows = (rawData, filters) =>
  rawData
    .filter((row) => {
      let meetsFilterCriteria = true;
      filters.forEach((filter) => {
        // Date range filter
        if (filter.filterType === "date-range") {
          const rowValue = moment(row[filter.column]);
          const isInDateRange = rowValue.isBetween(
            filter.value,
            filter.secondValue,
            undefined,
            "[]"
          );
          if (!isInDateRange) {
            meetsFilterCriteria = false;
          }
        }

        // Number range filter
        if (filter.filterType === "number-range") {
          const minimum = +filter.value;
          const maximum = +filter.secondValue;
          const rowValue = +row[filter.column].replace(/([$,%])/g, "");
          const isInNumberRange =
            row[filter.column] !== "" &&
            rowValue >= minimum &&
            rowValue <= maximum;
          if (!isInNumberRange) {
            meetsFilterCriteria = false;
          }
        }

        // Text filters
        if (
          filter.filterType === "text-contains" ||
          filter.filterType === "text-exact"
        ) {
          const lowerCaseFieldValue = `${row[filter.column]}`.toLowerCase();
          const containsOrMatchesText =
            filter.filterType === "text-contains"
              ? lowerCaseFieldValue.includes(filter.value.toLowerCase())
              : lowerCaseFieldValue === filter.value.toLowerCase();
          if (!containsOrMatchesText) {
            meetsFilterCriteria = false;
          }
        }
      });
      return meetsFilterCriteria;
    })
    .toSorted(
      ({ "Dated Date": date1 }, { "Dated Date": date2 }) => date2 - date1
    )
    .map((row) => {
      const datedDate = moment(row["Dated Date"]).isValid()
        ? moment(row["Dated Date"])
        : "";
      const formattedRow = {
        ...row,
        "Dated Date": datedDate && datedDate.format("M/D/YYYY"),
        "Par Amount":
          row["Par Amount"] &&
          dollarFormatter.format(row["Par Amount"].replaceAll(",", "")),
      };

      return formattedRow;
    });

const getStoredFilters = () => {
  const storedFilters = localStorage.getItem(
    "nationalPrivatePlacementsFilters"
  );
  return storedFilters ? JSON.parse(storedFilters) : [];
};

const getExportRows = (data) =>
  data
    .map((row) => ({
      State: row["State"],
      Issuer: row["Issuer"],
      Issue: row["Issue"],
      "Final Maturity": row["Final Maturity (Date)"],
      "Par Amount": row["Par Amount"],
      "Dated Date": row["Dated Date"],
      "Proxy Pricing Date": row["Proxy Pricing Date"],
      "Tax Designation": row["Tax Designation"],
      "Security Code": row["Security Code"],
      Coupon: row["Coupon"],
      Purchaser: row["Purchaser"],
      Advisor: row["Advisor"],
    }))
    .slice(0, 50);

const addMaturityInYears = (data) =>
  data.map((row) => {
    const datedDate = moment(row["Dated Date"]);
    const finalMaturity = moment(row["Final Maturity"]);
    const finalMaturityInYears =
      finalMaturity.isValid() && datedDate.isValid()
        ? moment
            .duration(finalMaturity.diff(datedDate))
            .asYears()
            .toFixed(2)
            .replace("-0.00", "0.00")
        : "";
    return {
      ...row,
      "Final Maturity (Date)": row["Final Maturity"],
      "Final Maturity (Years)": finalMaturityInYears,
    };
  });

function NationalPrivatePlacements() {
  const { loading, rawData } = useNationalPrivatePlacements({
    header: true,
    skipEmptyLines: true,
  });
  const extendedData = useMemo(() => {
    return addMaturityInYears(rawData);
  }, [rawData]);
  const [filters, setFilters] = useState(getStoredFilters());
  const sortedData = useMemo(
    () => (!loading ? getTableRows(extendedData, filters) : []),
    [loading, extendedData, filters]
  );
  const addOrUpdateFilter = (newFilter) => {
    const newFilters = filters.filter((f) => f.column !== newFilter.column);
    newFilters.push(newFilter);
    localStorage.setItem(
      "nationalPrivatePlacementsFilters",
      JSON.stringify(newFilters)
    );
    setFilters(newFilters);
  };
  const removeFilter = (columnName) => {
    const newFilters = filters.filter((f) => f.column !== columnName);
    localStorage.setItem(
      "nationalPrivatePlacementsFilters",
      JSON.stringify(newFilters)
    );
    setFilters(newFilters);
  };
  const handleExport = () => {
    const ws = utils.json_to_sheet(getExportRows(sortedData));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    writeFileXLSX(wb, "national_private_placements.xlsx");
  };

  return (
    <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flex: 1,
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Stack sx={{ alignItems: "flex-end", my: 1, ml: 1 }} spacing={0.3}>
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.7rem" }}
              onClick={handleExport}
            >
              Output to Excel
            </Button>
            <Typography paragraph sx={{ fontSize: "0.5rem", color: "grey" }}>
              Powered by SheetJS
            </Typography>
          </Stack>
          <TableVirtuoso
            style={{
              height: "100%",
              tableLayout: "fixed",
              userSelect: "none",
            }}
            data={sortedData}
            components={TableComponents}
            fixedHeaderContent={() => (
              <TableRow sx={{ backgroundColor: "white" }}>
                {dataFields.map((dataField) => (
                  <Header
                    {...dataField}
                    key={dataField.dataName}
                    filters={filters}
                    addOrUpdateFilter={addOrUpdateFilter}
                    removeFilter={removeFilter}
                  />
                ))}
              </TableRow>
            )}
            itemContent={(index, row) => (
              <>
                {dataFields.map(({ dataName, width, align }) => (
                  <CellContents
                    key={dataName + index}
                    width={width}
                    align={align}
                  >
                    {row[dataName]}
                  </CellContents>
                ))}
              </>
            )}
          />
        </Box>
      )}
    </Box>
  );
}

export default NationalPrivatePlacements;
