import { useEffect, useState } from "react";
import useUSTreasuryRawData from "./useUSTreasuryRawData";

const usedMaturities = [1, 2, 3, 5, 7, 10, 20, 30];

export default function useUSTreasuryData() {
  const { rawData, loading } = useUSTreasuryRawData();

  const header = rawData[0];

  const allData = rawData.slice(1, -1);

  const newestRawData =
    allData.length === 0
      ? []
      : allData.reduce((a, b) => {
          return new Date(a[0]) > new Date(b[0]) ? a : b;
        });

  const [data, setData] = useState({});

  const getData = () => {
    return header?.reduce((result, item, index) => {
      if (header?.[index].match(/\d+ Yr/g)) {
        const s = header?.[index].replace(/"|\bYr\b/g, "").trim();

        const maturity = Number.parseInt(s);

        if (usedMaturities.includes(maturity)) {
          result[maturity] = {
            ...result[maturity],
            yield: newestRawData[index] / 100,
          };
        }
      }
      return result;
    }, {});
  };

  useEffect(() => {
    setData({
      ...data,
      ...getData(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  return { data, loading, referenceDate: newestRawData?.[0], newestRawData };
}
